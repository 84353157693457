import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Filters } from 'src/app/models/general/filters.model';
import { ParamEntity } from 'src/app/models/general/param.model';
import { TableroCard, TableroDetail } from 'src/app/models/general/tablero-card';
import { Configuracion } from 'src/app/models/rrhh/configuracion.model';

@Injectable({
  providedIn: 'root'
})
export class SenderService {
  $objSource: BehaviorSubject<any>;
  $objSourceTableroDetalle: BehaviorSubject<TableroDetail>;
  $objSourceParams: BehaviorSubject<ParamEntity<any>>;
  $objSourceConfiguracion: BehaviorSubject<Configuracion>;
  private data: any;

  constructor() {
    this.$objSource = new BehaviorSubject<any>(this.data);
    this.$objSourceTableroDetalle = new BehaviorSubject<TableroDetail>(new TableroDetail());
    this.$objSourceParams = new BehaviorSubject<ParamEntity<any>>(new ParamEntity());
    this.$objSourceConfiguracion = new BehaviorSubject<Configuracion>(null);
   }

  enviarObjeto(data: any) {
    this.data = data;
    this.$objSource.next(this.data);
  }

  enviarObjetoTablero(data) {
    this.$objSourceTableroDetalle.next(data);
  }

  enviarObjetoFiltros(data) {
    this.$objSourceParams.next(data);
  }

  enviarObjetoConfiguracion(data) {
    this.$objSourceConfiguracion.next(data);
  }
}
