/**
 *
 * @param data
 * @returns array with keys on position [0]
 */
export function getKeyValue(data: any[], ignoredFields = []) {
  let mappedData = [];
  let columns = [];

  Object.keys(data[0]).forEach(function (key, index) {
    ignoredFields = ignoredFields.map(value => new RegExp(value));
    if (!ignoredFields.length || !ignoredFields.some(x => x.test(key.toUpperCase()))) {
      columns.push(key)
    }
  });
  mappedData.push(columns);
  return mappedData;
}
