export class FileEntity {
  NCategoria: string;
  IdCategoria: number;
  FilesList: FileInfo[];
  Url?: string;
  Filename?: string;
}

export interface FileInfo {
  IdInstructivo: number;
  Descripcion: string;
  Ninstructivo: string;
  Url: string;
  Filename: string;
  Filetype?: FileTypesEnum;
}

export enum FileTypesEnum {
  PDF = 1,
  VIDEO = 2,
  IMAGE = 3
}
