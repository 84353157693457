import { Injectable } from "@angular/core";
import { LoginAutogestionEndpointService } from "./login-autogestion-endpoint";

@Injectable()
export class LoginAutogestionService {
  constructor(private loginAutogestionEndpointService: LoginAutogestionEndpointService) {
  }

  ValidarUsuario(params: any) {
    return this.loginAutogestionEndpointService.ValidarUsuario(params);
  }

  setLogOutService(paramEntity) {
    return this.loginAutogestionEndpointService.setLogOutServiceEndPoint(paramEntity);
  }

}