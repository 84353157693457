import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { DataShareService } from 'src/app/services/data-share.service';

@Component({
  selector: 'app-list-a',
  templateUrl: './list-a.component.html',
  styleUrls: ['./list-a.component.scss']
})
export class ListAComponent implements OnInit {
  control = new UntypedFormControl();
  result: any[] = [
    { id: 1, tipo: 'Fiat' },
    { id: 2, tipo: 'Peugeot' },
    { id: 3, tipo: 'Renault' }
  ];
  @ViewChild('matSelection') matSelection: MatSelectionList;
  elementsSelected: { value; selected }[];
  @Output() elementSelectedEmit = new EventEmitter();
  @Output() editElementEmit = new EventEmitter();

  constructor(
    private dataShareService: DataShareService
  ) { }

  ngOnInit() {
    this.dataShareService.selectedElement$.subscribe((selected: any[]) => {
      if (selected) {
        this.elementsSelected = selected;
      }
    });
  }

  getSelected(e: MatSelectionListChange) {
    this.dataShareService.setProduct(e.options[0]);
    if (e.options[0].selected) {
      const element = e.options[0].value;
      this.elementSelectedEmit.emit(element);
      return;
    }
  }

  compareObjects(optionA, optionB) {
    if (optionA.IdJornada === optionB.IdJornada) {
      return true;
    }
    return false;
  }

  openEdit(e) {
    this.editElementEmit.emit(e);
  }

}
