<h1 mat-dialog-title class="bg-primary titulo-modal">Enviar copia digital del documento respaldatorio a RRHH
  <mat-icon class="btn-close" (click)="cerrarModal(null)">clear</mat-icon>
</h1>
<div mat-dialog-content>
  <div class="container h-100 p-1">
    <div class="row">
      <div class="col-sm-12 col-md-12 mb-2 p-0">
        <p>Presione el botón y seleccione el archivo que desea enviar a RRHH:</p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12 mb-2 p-0">
        <button type="button" mat-stroked-button color="primary" (click)="fileInput.click()" class="btn-attach px-1">Seleccione
          archivo</button>
        <input hidden (change)="inputFileChange($event)" #fileInput type="file">
        <p *ngIf="filename" class="d-inline-flex ml-2">{{ filename }}</p>
      </div>
    </div>

    <div class="row justify-content-end">
      <div class="col-sm-12 col-md-12 mb-2 p-0">
        <button type="button" mat-raised-button color="primary" class="ml-1" (click)="enviarArchivo()" style="float: right;">Enviar</button>
        <button type="button" mat-raised-button color="accent" (click)="cancelarEnvio()" style="float: right;">Cancelar</button>
      </div>
    </div>


  </div>
</div>
