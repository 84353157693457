import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, TimeoutError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TableroService {

  /* Variables para llamar a API */
  private readonly _url: string = '/api/tablero';

  constructor(private http: HttpClient) { }

  protected getRequestHeaders() {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + '',
      'Content-Type': 'application/json',
      'Accept': `application/json, text/plain, */*`,
      'App-Version': '1.0',
      timeout: `${1200000}`
    });

    return headers;
  }

  handleError(error: HttpErrorResponse) {
    if (error instanceof TimeoutError) {
      return throwError('En este momento no podemos realizar la operación. Por favor intente nuevamente más tarde.');
    }
    return throwError(error);
  }

  /*--------------GET------------------*/

  getCardsTableroControlService(filtro) {
    const endpointUrl = this._url + '/getTableroControl';
    const params = new HttpParams()
      .set('filtro', JSON.stringify(filtro));

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders(), params: params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getSectoresService(filtro) {
    const endpointUrl = this._url + '/getSectores';
    const params = new HttpParams()
      .set('filtro', JSON.stringify(filtro));

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders(), params: params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getMindateService(filtro) {
    const endpointUrl = this._url + '/getMindate';
    const params = new HttpParams()
      .set('filtro', JSON.stringify(filtro));

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders(), params: params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getDetalleService(filtro) {
    const endpointUrl = this._url + '/getDetalle';
    const params = new HttpParams()
      .set('filtro', JSON.stringify(filtro));

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders(), params: params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getNovedadDetalleService(filtro) {
    const endpointUrl = this._url + '/getNovedadDetalle';
    const params = new HttpParams()
      .set('filtro', JSON.stringify(filtro));

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders(), params: params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getTableroEvolutivoAnual(filtro) {
    const endpointUrl = this._url + '/getTableroEvolutivoAnual';
    const params = new HttpParams()
      .set('filtro', JSON.stringify(filtro));

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders(), params: params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

}
