import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { ModalAbmComponent } from './modal-abm/modal-abm.component';

@Component({
  selector: 'app-modal-configuracion-usuarios-nomina',
  templateUrl: './modal-configuracion-usuarios-nomina.component.html',
  styleUrls: ['./modal-configuracion-usuarios-nomina.component.scss']
})
export class ModalConfiguracionUsuariosNominaComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalConfiguracionUsuariosNominaComponent>,
    public dialog: MatDialog,
    private _snackBar: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
  }

  ngOnInit() {
  }

  getPlantillaSelected(e) {
    //console.log(e);
  }

  openModal() {
    const dialogRef = this.dialog.open(ModalAbmComponent, {
      width: '350px',
      height: '200px',
      autoFocus: false,
      data: {

      }
    });
    dialogRef.beforeClosed().subscribe((result) => {
      if (result) {
        //console.log(result)
      }
    });

  }

  getOptionSelected(e) {
    const dialogRef = this.dialog.open(ModalAbmComponent, {
      width: '350px',
      height: '200px',
      panelClass: 'modal-marcacion',
      autoFocus: false,
      data: {
        item: e
      }
    });
    dialogRef.beforeClosed().subscribe((result) => {
      if (result) {
        //console.log(result)
      }
    });
  }

}



