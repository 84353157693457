<h1 mat-dialog-title class="bg-primary titulo-modal">
  Titulo
  <mat-icon class="btn-close" (click)="cerrarModal(null)">clear</mat-icon>
</h1>
<div mat-dialog-content>
  <div class="container">
    <div class="row">
      <div class="col">
        <form [formGroup]="form">
          <mat-form-field appearance="outline">
            <mat-label>Descripción</mat-label>
            <input matInput type="text" formControlName="descripcion" placeholder="Descripción">
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button mat-button>Cerrar</button>
  <button mat-button cdkFocusInitial>Guardar</button>
</div>