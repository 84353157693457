import { UserAutogestionService } from './../../../services/utils/user-autogestion.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Usuario } from 'src/app/models/general/usuario.model';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';

@Component({
  selector: 'app-login-ingreso-autogestion',
  templateUrl: './login-ingreso-autogestion.component.html',
  styleUrls: ['./login-ingreso-autogestion.component.scss']
})
export class LoginIngresoAutogestionComponent implements OnInit {
  usuario = new Usuario();
  mostrarProgressBar = false;

  /*--------Styles---------*/
  matCardLogin = 'mat-card-login d-none';

  @Output() setRecuperaCuenta = new EventEmitter();
  @Output() toggleSpinnerEmit = new EventEmitter();
  @Output() volverEmit = new EventEmitter();

  constructor(
    private userAutogestionService: UserAutogestionService,
    private _snackBar: SnackBarService,
    private router: Router
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.matCardLogin = '';
    }, 1200);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.toggleSpinnerEmit.emit(false);
    }, 500);
  }

  loginSubmit() {
    this.mostrarProgressBar = this.validarForm();
    this.userAutogestionService.setLogin(this.usuario.NomUsuario, this.usuario.Pass).then((result) => {
      //console.log(result);
      if (result.Ok) {
        this._snackBar.openSnackBar('snack-success', 'Bienvenido ' + result.NomUsuario + '!', 4000);
      } else {
        this._snackBar.openSnackBar('snack-danger', result.Mensaje, 5000);
      }
      this.mostrarProgressBar = false;
    });
  }

  validarForm() {
    if (!this.usuario.NomUsuario) {
      this._snackBar.openSnackBar('snack-danger', 'Debe ingresar un nombre de usuario', 4000);
      return false;
    }
    if (!this.usuario.Pass) {
      this._snackBar.openSnackBar('snack-danger', 'Debe ingresar una contraseña', 4000);
      return false;
    }
    return true;
  }

  setRecuperarCuenta() {
    this.matCardLogin = 'd-none';
    this.setRecuperaCuenta.emit();
  }

  volver() {
    this.volverEmit.emit();
  }

}
