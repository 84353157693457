import { SourceEmpleados } from './../sources/sourceEmpleados';

export class ConfiguracionNomina {
  SourceEstados: any[];
  SourceIncidencias: any[];
  SourceMetodosAutenticacion: any[];
  SourceNominaTotal: any[];
  SourceShooterEquipos: any[];
  SourceShooterLegajosEquipos: any[];

  constructor(data?) {
    if (data) {
      this.SourceEstados = JSON.parse(data.SourceEstados);
      this.SourceIncidencias = JSON.parse(data.SourceIncidencias);
      this.SourceMetodosAutenticacion = JSON.parse(data.SourceMetodosAutenticacion);
      this.SourceNominaTotal = JSON.parse(data.SourceNominaTotal);
      this.SourceShooterEquipos = JSON.parse(data.SourceShooterEquipos);
      this.SourceShooterLegajosEquipos = JSON.parse(data.SourceShooterLegajosEquipos);
    }
  }
}
