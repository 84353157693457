import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { datePeriodConverter } from 'src/app/functions/date-period';
import { Filters } from 'src/app/models/general/filters.model';
import { ParamEntity } from 'src/app/models/general/param.model';
import { Reporte } from 'src/app/models/rrhh/reporte.model';
import { ReporteService } from 'src/app/services/reporte.service';
import { SenderService } from 'src/app/services/utils/sender.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import moment, { Moment } from 'moment'
moment();
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { UntypedFormControl } from '@angular/forms';
import { ModalNominaComponent } from '../modals/modal-nomina/modal-nomina.component';
import { Nomina } from 'src/app/models/rrhh/nomina.model';
import { DatePipe, formatDate } from '@angular/common';
import { CmbSector } from 'src/app/models/general/cmb-sector.model';
import { TableroService } from 'src/app/services/tablero.service';
import { Console } from 'console';
import { FileService } from 'src/app/services/file.service';
import { CmbEntity } from 'src/app/models/general/cmbEntity.model';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';



// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
// const moment = _rollupMoment || _moment;
@Component({
  selector: 'app-reporting-informes',
  templateUrl: './reporting-informes.component.html',
  styleUrls: ['./reporting-informes.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
  ],
})

export class ReportingInformesComponent implements OnInit, OnDestroy {
  paramEntity = new ParamEntity<any>();
  filters = new Filters();
  // DTP Range
  start = new UntypedFormControl({ value: null, disabled: true })
  end = new UntypedFormControl({ value: '', disabled: true });
  verMas = true;
  seleccionaLegajo = false;
  esNominaActiva: boolean;
  jsonValues: {
    pNReporte, pFechaDesde, pFechaHasta, pIdSeccion, pBusquedaIndividual, pIdLegajo, pSoloNominaActiva
  };
  cmbSectorList: CmbSector[] = [];
  nomina: Nomina;
  dataResult: any;
  desdeFc: UntypedFormControl;
  hastaFc: UntypedFormControl;
  minDate: Date;


  tipoReporte: number;
  reportesList: CmbEntity[];
  selectedReporte: any;

  constructor(
    private userValuesService: UserValuesService,
    private senderService: SenderService,
    private reporteService: ReporteService,
    private _snackBar: SnackBarService,
    private dialog: MatDialog,
    private tableroService: TableroService,
    private datePipe: DatePipe,
    private fileService: FileService,
  ) { }

  ngOnInit() {
    this.getReportesList(3);
    this.setFechasFiltros();
    this.getSectorList();
    this.limpiarDatos();

  }

  ngOnDestroy() {
    this.paramEntity.ClearTable = true;
    this.senderService.enviarObjetoFiltros(this.paramEntity);
  }

  private getReportesList(tipoReporte: number) {
    let paramEntity = new ParamEntity();
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;

    paramEntity.Json = JSON.stringify({
      pTipoReporte: tipoReporte
    });

    this.reporteService.getReportesListService(paramEntity).subscribe((result: any) => {
      if (result) {
        this.reportesList = result;
      }
    });
  }

  private getSectorList() {
    const paramEntity = new ParamEntity<any>();
    paramEntity.Json = JSON.stringify({
      pIdUsuario: this.userValuesService.getUsuarioValues.IdUsuario
    });
    this.tableroService.getSectoresService(paramEntity).subscribe((result: CmbSector[]) => {
      if (result) {
        this.cmbSectorList = result;
      }
    });
  }

  selectPeriodoDesde(x) {
    this.jsonValues.pFechaDesde = formatDate(x.value.toDate(), 'dd/MM/yyyy', 'en');
    this.hastaFc.setValue(x.value);
    this.minDate = new Date(x.value);
  }

  selectPeriodoHasta(x) {
    this.jsonValues.pFechaHasta = formatDate(x.value.toDate(), 'dd/MM/yyyy', 'en');
  }



  setVerMas() {
    this.verMas = !this.verMas;
  }

  setSeleccionaLegajo(e) {
    this.seleccionaLegajo = e.checked;
    this.jsonValues.pBusquedaIndividual = this.seleccionaLegajo ? 1 : 0;
    this.nomina = new Nomina();
    this.jsonValues.pIdLegajo = 0;
  }

  openModalNomina() {
    const dialogRef = this.dialog.open(ModalNominaComponent, {
      width: '1000px',
      height: '550px',
      panelClass: 'modal-marcacion',
      autoFocus: false
    });
    dialogRef.beforeClosed().subscribe((result: Nomina) => {
      if (result) {
        this.jsonValues.pIdLegajo = result.IdLegajo;
        this.nomina = result;
      }
    });
  }

  setNominaActiva() {
    this.jsonValues.pSoloNominaActiva = this.esNominaActiva ? 1 : 0;
  }


  getSeccionSelected(e) {
    this.jsonValues.pIdSeccion = e.value;
  }


  private limpiarDatos() {
    this.minDate = new Date(this.desdeFc.value);
    this.jsonValues = {} as any;
    this.esNominaActiva = true;
    this.seleccionaLegajo = false;
    this.jsonValues.pIdLegajo = 0;
    this.jsonValues.pSoloNominaActiva = 1;
    this.jsonValues.pFechaDesde = this.datePipe.transform(moment().toDate(), 'dd/MM/yyyy');
    this.jsonValues.pFechaHasta = this.datePipe.transform(moment().toDate(), 'dd/MM/yyyy');
    this.dataResult = [];
  }

  getReporteSelected(e) {
    this.setFechasFiltros();
    this.jsonValues.pNReporte = e.value.NReporte;
    this.selectedReporte = e.value;
  }

  private setFechasFiltros() {
    this.desdeFc = new UntypedFormControl(moment());
    this.hastaFc = new UntypedFormControl(moment());
    this.limpiarDatos();
  }


  downloadFile(idDocumento) {
    let request = JSON.stringify({
      pNombreDocumento: 'Vacaciones',
      pIdDocumento: idDocumento,
      NombreArchivo: 'Otorgamiento Vacaciones ' + idDocumento
    });

    this.fileService.getInformePdf(request)
  };


  obtenerInforme($event) {
      this._snackBar.openSnackBar('snack-primary', 'Generando archivo. Por favor espere.', 1000);
      if (this.selectedReporte.NReporte == 'Prueba') {
        let request = JSON.stringify({
          NombreReporte: 'RptTest',
          NombreArchivo: 'Informe de Prueba',
          ReportRenderType: 6, //PDF
          ParametrosJson: JSON.stringify({
                pFechaDesde: this.jsonValues.pFechaDesde,
                pFechaHasta: this.jsonValues.pFechaHasta,
                pIdSeccion: this.jsonValues.pIdSeccion ? this.jsonValues.pIdSeccion : 0,
                pBusquedaIndividual: this.jsonValues.pBusquedaIndividual ? 1 : 0,
                pIdLegajo: this.jsonValues.pIdLegajo ? this.jsonValues.pIdLegajo : 0,
                pSoloNominaActiva: this.jsonValues.pSoloNominaActiva ? 1 : 0,
                pIdUsuario: this.userValuesService.getUsuarioValues.IdUsuario
          })
        });

        this.fileService.getInformePdf(request).subscribe();
      }


      if (this.selectedReporte.Id == 7) {
        let request = JSON.stringify({
          NombreReporte: 'rptAusentismoComercial',
          NombreArchivo: 'Informe de Ausentismo (Comercial)',
          ReportRenderType: 6, //PDF
          ParametrosJson: JSON.stringify({
            pFechaDesde: this.jsonValues.pFechaDesde,
            pFechaHasta: this.jsonValues.pFechaHasta,
            pIdSeccion: this.jsonValues.pIdSeccion ? this.jsonValues.pIdSeccion : 0,
            pBusquedaIndividual: this.jsonValues.pBusquedaIndividual ? 1 : 0,
            pIdLegajo: this.jsonValues.pIdLegajo ? this.jsonValues.pIdLegajo : 0,
            pSoloNominaActiva: this.jsonValues.pSoloNominaActiva ? 1 : 0,
            pIdUsuario: this.userValuesService.getUsuarioValues.IdUsuario
          })
        });
        this.fileService.getInformePdf(request).subscribe();
      }
    }
  }

