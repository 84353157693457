import { SourcePlantillasJornadasXjornada } from './../../../models/sources/sourcePlantillasJornadasXjornada';
import { Component, OnInit, ViewChild, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { SourceJornadas } from 'src/app/models/sources/sourceJornadas';


@Component({
  selector: 'app-config-plantillas-jornadas-list-jornadas',
  templateUrl: './config-plantillas-jornadas-list-jornadas.component.html',
  styleUrls: ['./config-plantillas-jornadas-list-jornadas.component.scss']
})
export class ConfigPlantillasJornadasListJornadasComponent implements OnInit {
  control = new UntypedFormControl();
  listJornadasChecked: number[] = [];
  @Input() dataSourceList: SourceJornadas[];
  @Input() JornadasXplantillaList: SourcePlantillasJornadasXjornada[];

  @Output() optionsDataSelectedEmit = new EventEmitter();

  @ViewChild('matSelection') matSelection: MatSelectionList;

  constructor() { }

  ngOnInit() {
  }

  getSelected(e) {
    if (e.options[0].selected) {
      this.listJornadasChecked.push(e.options[0].value.IdJornada);
    } else {
      const index = this.listJornadasChecked.findIndex(x => x === e.options[0].value.IdJornada);
      this.listJornadasChecked.splice(index, 1);
    }
  }

  setCheckValues(idPlantilla: number, newSource?: SourceJornadas[]) {
    this.listJornadasChecked = [];
    var listJornadasxPlantilla: number[] = this.JornadasXplantillaList.filter(x => x.IdPlantillaJornada === idPlantilla).map(x => x.IdJornada);

    this.matSelection.options.forEach(x => {
      if (listJornadasxPlantilla.indexOf(x.value.IdJornada) > -1) {
        x._setSelected(true);
        this.listJornadasChecked.push(x.value.IdJornada);
      } else {
        x._setSelected(false);
      }
    });
  }

  clearForms() {
    this.matSelection.deselectAll();
    this.listJornadasChecked = [];
    this.optionsDataSelectedEmit.emit([]);
  }

}
