import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UserAutogestionService } from 'src/app/services/utils/user-autogestion.service';

@Component({
  selector: 'app-close-session-button',
  templateUrl: './close-session-button.component.html',
  styleUrls: ['./close-session-button.component.scss']
})
export class CloseSessionButtonComponent implements OnInit {

  constructor(
    private userAutogestionService: UserAutogestionService
  ) { }

  ngOnInit() {
  }

  cerrarSesion() {
    this.userAutogestionService.setLogout();
  }

}
