import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CmbEntity } from 'src/app/models/general/cmbEntity.model';
import { ParamEntity } from 'src/app/models/general/param.model';
import { Usuario } from 'src/app/models/general/usuario.model';
import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { NovedadesService } from 'src/app/services/rrhh/novedades/novedades.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { ModalConfirmacionComponent } from '../modal-confirmacion/modal-confirmacion.component';

@Component({
  selector: 'app-modal-jornada-programada-det',
  templateUrl: './modal-jornada-programada-det.component.html',
  styleUrls: ['./modal-jornada-programada-det.component.scss']
})
export class ModalJornadaProgramadaDetComponent implements OnInit {
  objeto: { titulo, obj };
  resultData;
  lstJornadas: CmbEntity[] = [];
  usuario: Usuario;
  filteredOptions: Observable<any[]>;
  myControl = new UntypedFormControl();
  actualizarTabla = false;

  constructor(
    public dialogRef: MatDialogRef<ModalJornadaProgramadaDetComponent>,
    private userValuesService: UserValuesService,
    private novedadesService: NovedadesService,
    public dialog: MatDialog,
    private _snackBar: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.objeto = data;
    this.usuario = this.userValuesService.getUsuarioValues;
  }

  ngOnInit() {
    this.getJornadaProgramadaDet();
    this.loadJornadasCmb();
  }

  cerrarModal(actualizarTabla = false, objResult = null) {

    const obj = objResult ? objResult : null;
    const result = {
      actualizarTabla,
      obj
    };
    this.dialogRef.close(result);
    this.objeto = null;
  }

  getJornadaProgramadaDet() {
    let params = new ParamEntity();
    params.FechaDate = this.objeto.obj.fecha;
    params.IdLegajo = this.objeto.obj.element.ID_LEGAJO;
    this.novedadesService.getJornadaProgramadaDet(params).subscribe((result: any) => {
      if (result) {
        this.resultData = result[0];
      }
    });
  }

  loadJornadasCmb() {
    let params = [];
    params.push(null);
    params.push(this.usuario.IdEmpresa);
    params.push(this.usuario.IdUsuario);
    this.novedadesService.getListJornadasUser(params).subscribe((result: CmbEntity[]) => {
      this.lstJornadas = result;
      this.subscribeEventChange();
    });

    //Fer 24-07-2021: set default value
    // let jorn = new CmbEntity;
    // jorn.Id = this.objeto.IdJornada;
    // jorn.Detalle = this.objeto.Njornada;
    // this.myControl.setValue(jorn);
  }

  private subscribeEventChange() {
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.Detalle),
        map(value => this._filter(value))
      );
  }

  private _filter(value): any[] {
    const filterValue = value.toLowerCase();
    return this.lstJornadas.filter(option => option.Detalle.toLowerCase().includes(filterValue));
  }

  getSeleccionJornada(e) {
    // this.objeto.IdJornada = e.option.value.Id;
  }

  displayFn(item: CmbEntity): string {
    return item && item.Detalle ? item.Detalle : '';
  }

  guardar() {
    const dialogRef = this.dialog.open(ModalConfirmacionComponent, {
      width: '500px',
      height: '120px',
      autoFocus: false,
      data: {
        titulo: '¿Desea modificar las jornada seleccionada?'
      }
    });
    dialogRef.beforeClosed().subscribe((result) => {
      if (result) {
        this.guardarJornada();
      }
    });
  }

  guardarJornada() {
    let paramEntity = new ParamEntity<object>();
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    paramEntity.IdLegajo = this.objeto.obj.element.ID_LEGAJO;
    paramEntity.FechaDate = this.objeto.obj.fecha;
    paramEntity.IdJornada = this.myControl.value.Id;
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    //console.log(paramEntity)

    this.novedadesService.guardarJornada(paramEntity).subscribe((result: ResponseHelper) => {
      if (result.Ok) {
        this._snackBar.openSnackBar('snack-success', 'Jornada guardada correctamente', 3000);
      } else {
        this._snackBar.openSnackBar('snack-danger', result.Mensaje, 3000);
      }

    }, (error) => { this._snackBar.openSnackBar('snack-danger', error.error, 3000); });
    this.actualizarTabla = true;
    this.cerrarModal(this.actualizarTabla);
  }
}
