import { SourceEmpleados } from './../../../models/sources/sourceEmpleados';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-list-nomina',
  templateUrl: './list-nomina.component.html',
  styleUrls: ['./list-nomina.component.scss']
})
export class ListNominaComponent implements OnInit {
  @Input() lstEmpleados: SourceEmpleados[];
  lstEmpleadosFiltered: SourceEmpleados[];
  @Input() set idLegajosUsuariosNomina(value) {
    if (!value.ids || !value.ids.length) {
      this.lstEmpleadosFiltered = [];
      return;
    }
    this.lstEmpleadosFiltered = value.arr.filter(x => value.ids.includes(x.IdLegajo));
  }

  constructor() { }

  ngOnInit() {
  }

  clearForms() {
    this.lstEmpleadosFiltered = [];
  }

}
