<div *ngIf="resultData && objeto">
  <h1 mat-dialog-title class="bg-primary titulo-modal">{{ objeto.titulo }}
    <mat-icon class="btn-close" (click)="cerrarModal(false, null)">clear</mat-icon>
  </h1>
  <div mat-dialog-content>
    <div class="container h-100">
      <div class="row">
        <div class="col">
          <mat-card class="mb-3">
            <div class="row text-center labels-container">
              <div class="col-3">
                <mat-label>Legajo</mat-label>
                <p>{{ objeto.obj.element.ID_LEGAJO }}</p>
              </div>
              <div class="col-3">
                <mat-label>Nombre</mat-label>
                <p>{{ objeto.obj.element.NOMBRE + ' ' + objeto.obj.element.APELLIDO }}</p>
              </div>
              <div class="col-3">
                <mat-label>Sección</mat-label>
                <p>{{ objeto.obj.element.SECCION }}</p>
              </div>
              <div class="col-3">
                <mat-label>Fecha</mat-label>
                <p>{{ objeto.obj.fecha | date:'dd/MM/yyyy' }}</p>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
      <div class="row pos-relative">
        <div class="col-6">
          <p><strong>Jornada Actual</strong></p>
          <p class="p-nombre w-100">
            <span>Id Jornada: <b>{{ resultData.ID_JORNADA }}</b></span>
          </p>
          <p class="p-nombre w-100">
            <span>Descripción: <b>{{ resultData.N_JORNADA }}</b></span>
          </p>
          <p class="p-nombre w-100">
            <span>Rango Horario Habilitado: <b>{{ resultData.RANGO_HABILITADO }}</b></span>
          </p>
          <p class="p-nombre w-100">
            <span>Horas Teóricas: <b>{{ resultData.H_TEORICAS }}</b></span>
          </p>
        </div>
        <div class="vl"></div>
        <div class="col-6">
          <p><strong>Modificar Jornada</strong></p>
          <mat-form-field class="w-100">
            <mat-label>Seleccione jornada</mat-label>
            <input type="text" placeholder="" aria-label="Seleccione Jornada" matInput [formControl]="myControl"
              [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
              (optionSelected)="getSeleccionJornada($event)" [displayWith]="displayFn">
              <!-- <mat-select [(ngModel)]="objeto.IdJornada" (selectionChange)="getSeleccionJornada($event)"> -->
              <mat-option *ngFor="let item of filteredOptions | async" [matTooltip]="item.Detalle" [value]="item">
                {{ item.Detalle }}
              </mat-option>
              <!-- </mat-select> -->
            </mat-autocomplete>
          </mat-form-field>

          <button mat-raised-button class="btn-gray d-block ml-auto mr-3" (click)="guardar()">Guardar</button>
        </div>
      </div>
    </div>
  </div>
</div>