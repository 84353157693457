import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Plantilla } from 'src/app/models/rrhh/configuracion-jornada';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';


@Component({
  selector: 'app-modal-abm-jornadas-plantillas',
  templateUrl: './modal-abm-jornadas-plantillas.component.html',
  styleUrls: ['./modal-abm-jornadas-plantillas.component.scss']
})
export class ModalAbmJornadasPlantillasComponent implements OnInit {
  plantillasList: Plantilla[];

  constructor(
    public dialogRef: MatDialogRef<ModalAbmJornadasPlantillasComponent>,
    public dialog: MatDialog,
    private _snackBar: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.plantillasList = data.plantillas;
  }

  ngOnInit() {
  }

  getPlantillaSelected(e) {
  }

}
