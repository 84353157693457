import { Injectable } from "@angular/core";
import { AutogestionEndPoint } from "./autogestion-endpoint";

@Injectable()
export class AutogestionService {
  constructor(private autogestionEndPoint: AutogestionEndPoint) {
  }

  getListIncidencias(filtro?) {
    return this.autogestionEndPoint.getListIncidencias(filtro);
  }

  getHistorialNovedades(filtro?) {
    return this.autogestionEndPoint.getHistorialNovedades(filtro);
  }



  guardarAvisoAusencia(filtro?) {
    return this.autogestionEndPoint.guardarAvisoAusenciaEndpoint(filtro);
  }

}
