<h1 mat-dialog-title class="bg-primary titulo-modal">
  {{ title }}
  <mat-icon class="btn-close" (click)="cerrarModal(null)">clear</mat-icon>
</h1>
<div mat-dialog-content>
  <div class="container">
    <div class="row">
      <div class="col">
        <form [formGroup]="form">
          <div class="flex-container-wrapper">
            <div *ngFor="let item of formTemplateArr; let i = index;" class="flex-box-item">
              <div [ngSwitch]="item.Type">
                <div *ngSwitchCase="'textbox'">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ item.Label }}</mat-label>
                    <input matInput [type]="item.InputType" [formControlName]="item.FormcontrolName"
                      placeholder="{{ item.Placeholder }}">
                  </mat-form-field>
                </div>
                <div *ngSwitchCase="'autocomplete'">
                  <mat-form-field class="w-100">
                    <mat-label>{{ item.Label }}</mat-label>
                    <input type="text" placeholder="" matInput [formControlName]="item.FormcontrolName"
                      [matAutocomplete]="auto" (input)="getFilteredList($event, item, i)">
                    <mat-autocomplete class="autocomplete" autoActiveFirstOption #auto="matAutocomplete"
                      [displayWith]="displayFn.bind(this, i)">
                      <mat-option *ngFor="let itemOption of getAutocompleteArray(i) | async"
                        [matTooltip]="itemOption.Key" [value]="itemOption">
                        {{ itemOption.Key }}
                      </mat-option>
                    </mat-autocomplete>
                    <!-- <mat-hint *ngIf="item.error" class="error">Debe seleccionar un elemento de la lista</mat-hint> -->
                  </mat-form-field>
                </div>
                <div *ngSwitchCase="'checkbox'">
                  <mat-checkbox [formControlName]="item.FormcontrolName">{{ item.Label }}</mat-checkbox>
                </div>
                <div *ngSwitchCase="'select'">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ item.Label }}</mat-label>
                    <mat-select [formControlName]="item.FormcontrolName">
                      <mat-option *ngFor="let optionItem of item.Options" [value]="optionItem.Value">
                        {{optionItem.Key}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row" *ngIf="configuracion.Tipo == tipoConfiguracionEnum.NUEVO">
      <div class="col">
        <mat-card>
          <mat-card-header>
            <mat-card-subtitle>Historial</mat-card-subtitle>
          </mat-card-header>
          <mat-list role="list">
            <mat-list-item role="listitem" *ngFor="let itemHistorial of historialFilteredList">
              {{ itemHistorial.Key }}
            </mat-list-item>
          </mat-list>
        </mat-card>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button mat-button (click)="cerrarModal(null)">Cerrar</button>
  <button mat-button cdkFocusInitial (click)="submitForm()">Guardar</button>
</div>
