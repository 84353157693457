import { UserAutogestionService } from 'src/app/services/utils/user-autogestion.service';
import { Injectable } from "@angular/core"
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from "@angular/router"
import { UserValuesService } from "./user-values.service"
import { SenderService } from "./sender.service"
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private userValuesService: UserValuesService,
    private userAutogestionService: UserAutogestionService,
    private router: Router,
    private senderService: SenderService,
    private cookieService: CookieService) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    let setMostrar;
    switch (this.cookieService.get('tipoLogin')) {
      case 'admin':
        return this.setAdministracionAuthGuard(next, state);
        break;
      case 'autogestion':
        return this.setAutogestionAuthGuard(next, state);
        break;

      default:
        setMostrar = false;
        this.senderService.enviarObjeto(setMostrar);
        if (state.url === '/login') {
          return true;
        }
        this.router.navigate(['login']);
        return false;
        break;
    };
  }

  private setAdministracionAuthGuard(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    let setMostrar;
    if (!this.userValuesService.isLogueado) {
      setMostrar = false;
      this.senderService.enviarObjeto(setMostrar);
      if (state.url === '/login') {
        return true;
      }
      this.router.navigate(['login']);
      return false;
    } else if (state.url === '/login') {
      this.router.navigate(['home']);
      return true;
    }
    setMostrar = true;
    this.senderService.enviarObjeto(setMostrar);
    return true;
  }

  private setAutogestionAuthGuard(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    let setMostrar;
    if (!this.userAutogestionService.isLogueado) {
      setMostrar = false;
      this.senderService.enviarObjeto(setMostrar);
      if (state.url === '/login') {
        return true;
      }
      this.router.navigate(['login']);
      return false;
    } else if (state.url === '/login') {
      this.router.navigate(['home-autogestion']);
      return true;
    }
    // setMostrar = true;
    // this.senderService.enviarObjeto(setMostrar);
    return true;
  }
}
