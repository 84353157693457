import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TimeoutError, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AutogestionEndPoint {

  /* Variables para llamar a API */
  private readonly _urlApi: string = '/api/autogestnovedades';

  constructor(private http: HttpClient) { }

  protected getRequestHeaders() {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + '',
      'Content-Type': 'application/json',
      'Accept': `application/json, text/plain, */*`,
      'App-Version': '1.0',
      timeout: `${100000}`
    });

    return headers;
  }

  handleError(error: HttpErrorResponse) {
    if (error instanceof TimeoutError) {
      return throwError('En este momento no podemos realizar la operación. Por favor intente nuevamente más tarde.');
    }
    return throwError(error);
  }

  getListIncidencias(filtro) {
    const endpointUrl = this._urlApi + '/getListIncidencias';
    const params = new HttpParams()
      .set('filtro', JSON.stringify(filtro));

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders(), params: params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getHistorialNovedades(filtro) {
    const endpointUrl = this._urlApi + '/getHistorialNovedades';
    const params = new HttpParams()
      .set('filtro', JSON.stringify(filtro));

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders(), params: params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }





  /* POST */


  guardarAvisoAusenciaEndpoint(param) {
    const endPointUrl = this._urlApi + '/guardarAvisoAusencia';

    return this.http.post(endPointUrl, JSON.stringify(param), { headers: this.getRequestHeaders() }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

}
