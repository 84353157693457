import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, Observable, of } from "rxjs";
import { ParamEntity } from "src/app/models/general/param.model";
import { Usuario } from "src/app/models/general/usuario.model";
import { LoginAutogestionService } from "../login/login-autogestion.service";
import { SnackBarService } from "./snackBar.service";
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class UserAutogestionService {
  private loggedInAutogestion = new BehaviorSubject<boolean>(false);
  usuarioLogueado: Usuario;
  private loggedInStatus = false;

  constructor(
    private loginService: LoginAutogestionService,
    // private storage: StorageMap,
    private cookieService: CookieService,
    private route: Router,
    private _snackBar: SnackBarService,
    private dialogRef: MatDialog) {
    this.usuarioLogueado = new Usuario();
    this.usuarioLogueado.IdEmpresa = 1; // BORRAR UNA VEZ QUE ESTE LISTO EL MULTIEMPRESA
    this.setLoggedIn(this.cookieService.check('logueado-autogestion'));
    if (this.isLogueado) {
      this.getUsuarioSuccess(false);
    }
  }

  /* Devolvemos una promesa para asi hacer logica en
    el componente.
  */
  setLogin(userName: string, pass: string): Promise<any> {
    return this.loadLoginUser(userName, pass).then(() => this.usuarioLogueado);
  }

  /* Valida contra la BD con usr y pass. Si obtenemos un TRUE como respuesta
    guardamos todos los valores necesarios del usuario en las cookies del browser
  */
  loadLoginUser(userName: string, pass: string) {
    const params = new ParamEntity();
    params.Json = JSON.stringify(
      {
        'pUsername': userName,
        'pPassword': pass
      }
    );
    return this.loginService.ValidarUsuario(params).toPromise().then((result: any) => {
      this.usuarioLogueado = result;
      this.usuarioLogueado.IdEmpresa = 1; // BORRAR UNA VEZ QUE ESTE LISTO EL MULTIEMPRESA
      if (this.usuarioLogueado.Ok) {
        this.cookieService.set('tipoLogin', 'autogestion');
        // this.paramLogoutDb = new ParamEntity<any>();
        // this.paramLogoutDb.GenericData = result.IdUserLog;
        this.setLoggedInAutogestionBehavior(true); // Seteo la variable en TRUE para el navbar (logueado)
        this.cookieService.set('logueado-autogestion', JSON.stringify(true));
        localStorage.setItem('user', JSON.stringify(this.getUsuarioSuccess(true)));
        this.setLoggedIn(true);
        // this.checkLocalStorageLogout();
        // this.setIntervalSaveLocalStorageData();
        this.route.navigate(['home-autogestion']);
      }
    }).catch((error) => {
      // this.getUsuarioValues.Mensaje = error.error; this.getUsuarioValues.Ok = false;
    });
  }

  /* AUTOGESTION */

  get isLoggedInAutogestionBehavior() {
    return this.loggedInAutogestion.asObservable();
  }

  setLoggedInAutogestionBehavior(value) {
    this.loggedInAutogestion.next(value);
  }

  // Setea el login en TRUE cuando inicia sesion
  setLoggedIn(value: boolean) {
    this.loggedInStatus = value;
  }

  // Nos devuelve el estado del logueo (si esta logueado o no)
  get isLogueado() {
    return this.loggedInStatus;
  }

  get getUsuarioValues() {
    return this.usuarioLogueado;
  }

  /* Si estamos en la pantalla de LOGIN, envia un TRUE para
   crear un nuevo usuario logueado.
   Si se refresca la pagina, busca los datos que esten
   en las cookies y los obtenemos. */
  getUsuarioSuccess(nuevoLogin) {
    let usuarioCookie = new Usuario();

    if (nuevoLogin) {
      usuarioCookie.IdUserLog = this.usuarioLogueado.IdUserLog;
      usuarioCookie.IdEmpresa = this.usuarioLogueado.IdEmpresa;
      usuarioCookie.IdRol = this.usuarioLogueado.IdRol;
      usuarioCookie.IdUsuario = this.usuarioLogueado.IdUsuario;
      usuarioCookie.Ok = this.usuarioLogueado.Ok;
      usuarioCookie.NomUsuario = this.usuarioLogueado.NomUsuario;
      usuarioCookie.SistMenu = this.usuarioLogueado.SistMenu;
    } else {
      usuarioCookie = JSON.parse(localStorage.getItem('user'));
      this.loggedInAutogestion.next(true); // Seteo la variable en TRUE para el navbar (logueado)
      Object.assign(this.usuarioLogueado, usuarioCookie);
    }
    return usuarioCookie;
  }

  // Para desloguearnos. Cierra todos los modals que esten
  // abiertos en la app. Luego llama a funcion para
  // que esten almacenados en la web
  setLogout(inactividad = false) {
    this.dialogRef.closeAll();
    this._snackBar.openSnackBar('snack-warning', 'Redirigiendo al login', 3000);
    this.removeLogoutData().subscribe((result) => {
      if (result) {
        this.cookieService.delete('tipoLogin');
        this.logoutDatabase(inactividad);
      } else {
        this._snackBar.openSnackBar('snack-danger', 'Hubo un problema al cerrar la sesión', 3000);
      }
    });
  }

  // Primero verifica que haya una cookie
  // si existe, elimina todo dato almacenado en el navegador
  // y devuelve un TRUE
  removeLogoutData(): Observable<boolean> {
    if (!this.checkCookieExists) {
      return of(false);
    }
    this.loggedInAutogestion.next(false); // Seteo la variable en FALSE para el navbar (no logueado)
    this.cookieService.delete('logueado-autogestion');
    this.setLoggedIn(false);
    localStorage.removeItem('user');
    return of(true);
  }

  // Nos devuelve el estado de la cookie
  // si existe o no
  get checkCookieExists() {
    return this.cookieService.check('logueado-autogestion');
  }

  private logoutDatabase(inactividad) {
    const paramEntity = new ParamEntity();
    paramEntity.GenericData = this.getUsuarioValues.IdUserLog;
    paramEntity.Inactividad = inactividad;
    // paramEntity.TiempoInactividad = this.tiempoInactividad;
    this.route.navigate(['login']);

    // this.loginService.setLogOutService(paramEntity).subscribe((result: ResponseHelper) => {
    //   if (result.Ok) {
    //     this.route.navigate(['login']);
    //   } else {
    //     this._snackBar.openSnackBar('snack-danger', 'Hubo un problema al cerrar la sesión', 3000);
    //   }
    // }, (error) => { this._snackBar.openSnackBar('snack-danger', 'Hubo un problema al cerrar la sesión', 3000); })
  }
}
