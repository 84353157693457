<div class="row">
  <div class="col-6 mx-auto text-center text-white">
    <app-alert [titulo]="'Monitor de usuarios conectados'"></app-alert>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-12 mx-auto text-center">
      <div class="connection-table-container mt-2">
        <table class="connection-table">
          <thead class="table-header-bg">
            <tr>
              <th>Fecha y Hora de Conexión</th>
              <th>Nombre de Usuario</th>
              <th>ID de Conexión</th>
              <th class="text-center">Estado</th> <!-- Agregar la clase text-center aquí -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let connection of connections">
              <td>
                {{ connection.connectedAt | date : "dd/MM/yyyy HH:mm:ss" }}
              </td>
              <td>{{ connection.username }}</td>
              <td>{{ connection.connectionId }}</td>
              <td class="text-center"> <!-- Agregar la clase text-center aquí -->
                <div
                  class="status-circle"
                  [ngClass]="{
                    connected: connection.status === 'connected',
                    disconnected: connection.status !== 'connected'
                  }"
                ></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
