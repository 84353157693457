
<div class="row">
  <div class="col-3">
    <div class="row">
      <div class="col-12 style-tilte-list">
        Plantillas
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-selection-list #matSelection role="list" class="list-style" (selectionChange)="getPlantillaSelected($event)">
          <mat-list-option *ngFor="let plantillaItem of plantillasList" [value]="plantillaItem">
            {{ plantillaItem.IdPlantilla + ' - ' + plantillaItem.Nplantilla }}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>


  </div>

  <div class="col-3">
    <div class="row">
      <div class="col-12 style-tilte-list">
        Usuarios
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-selection-list #matSelection role="list" class="list-style" (selectionChange)="getPlantillaSelected($event)">
          <mat-list-option *ngFor="let plantillaItem of plantillasList" [value]="plantillaItem">
            {{ plantillaItem.IdPlantilla + ' - ' + plantillaItem.Nplantilla }}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
  </div>

  <div class="col-6">
    <div class="row">
      <div class="col-12 style-tilte-list">
        Datos de Plantilla
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p>Aquí van los componentes</p>
      </div>
    </div>
  </div>

</div>

