<div class="mb-2 d-flex align-items-end flex-column">
  <button
    type="button"
    class="btn btn-secondary"
    (click)="print()"
    [disabled]="printButtonDisabled"
  >
    <mat-icon>print</mat-icon>
  </button>
</div>
<mat-progress-bar
  class="mb-2"
  mode="indeterminate"
  *ngIf="loading"
></mat-progress-bar>

<div class="row">
  <div class="col-12">
    <div id="form_Vacaciones">
      <div [ngStyle]="container">
        <!--Logo-->
        <div [ngStyle]="row">
          <div [ngStyle]="col12">
            <img
              alt=""
              src="assets/images/pdf/cofarsur-logo.png"
              style="width: 264px; height: 66px"
              (load)="disableButton()"
            />
          </div>
        </div>

        <div [ngStyle]="row">
          <div [ngStyle]="col12">
            <div [ngStyle]="title">
              <p [ngStyle]="pTitle">Notificación de Vacaciones</p>
            </div>
          </div>
        </div>

        <div [ngStyle]="row">
          <div [ngStyle]="col12">
            <div [ngStyle]="infoTitle">
              <p>{{ docVacacionesList.fechaVacaciones }}</p>
              <p>{{ docVacacionesList.nombre }}</p>
              <p>{{ docVacacionesList.legajo }}</p>
              <p>{{ docVacacionesList.seccion }}</p>
            </div>
          </div>
        </div>

        <div [ngStyle]="row">
          <div [ngStyle]="col12">
            <div [ngStyle]="contentText">
              <p [ngStyle]="pContent">
                {{ docVacacionesList.textoA }}
              </p>
            </div>
          </div>
        </div>

        <br />
        <br />
        <br />


        <div [ngStyle]="row">
          <div [ngStyle]="col6">
            <div [ngStyle]="firmasContainer">
              <div class="info-firma">
                <div [ngStyle]="firmaContainer">
                  <img
                    [ngStyle]="firmaEmpresa"
                    alt=""
                    src="assets/images/pdf/firma_empresa.jpeg"
                  /><br />
                </div>

                <hr class="dotted" />
                <span class="firma-span">Firma y aclaración</span><br />
                <span class="firma-span">(Empresa)</span>
              </div>
            </div>
          </div>
          <div [ngStyle]="col6">
            <div [ngStyle]="firmasContainer">
              <!-- <div [ngStyle]="firmaEmpresa">
          <br />
        </div> -->
              <div class="info-firma">
                <div [ngStyle]="firmaContainer">
                  <img [ngStyle]="firmaEmpresa" alt="" src="" /><br />
                </div>
                <hr class="dotted" />
                <span class="firma-span">Firma y aclaración</span><br />
                <span class="firma-span">(Colaborador)</span>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />


        <div [ngStyle]="row">
          <div [ngStyle]="col12">
            <div [ngStyle]="contentText">
              <p [ngStyle]="pContent">
                {{ docVacacionesList.textoB }}
              </p>
            </div>
          </div>
        </div>
        <div [ngStyle]="rowCentered">
          <qrcode
            id="qr"
            [qrdata]="url"
            [width]="150"
            [errorCorrectionLevel]="'M'"
            [margin]="2"
          ></qrcode>
        </div>
        <br />

        <div class="footer">
          <div [ngStyle]="imgfootercontainer">
            <!-- <img class="img-footer" src="logo_footer.jpeg" alt=""> -->
            <span [ngStyle]="textSniper">SniperBot ®</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
