import { Router, ActivatedRoute, NavigationStart, NavigationEnd, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { Component, OnInit, Output, EventEmitter, OnDestroy, ElementRef, ViewChild, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Input } from '@angular/core';

import { NominaService } from 'src/app/services/rrhh/nomina/nomina.service';
import { Nomina } from 'src/app/models/rrhh/nomina.model';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { SenderService } from 'src/app/services/utils/sender.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { UntypedFormControl } from '@angular/forms';

import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { TableroCard, TableroDetail } from 'src/app/models/general/tablero-card';
import { TableroService } from 'src/app/services/tablero.service';
import { ParamEntity } from 'src/app/models/general/param.model';
import { formatDate } from '@angular/common';
import { Observable, of } from 'rxjs';
import { CmbSector } from 'src/app/models/general/cmb-sector.model';
import { datePeriodConverter } from 'src/app/functions/date-period';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileService } from 'src/app/services/file.service';
import { FileEntity } from 'src/app/models/general/file.entity';
import { Filters } from 'src/app/models/general/filters.model';
import { ReporteService } from 'src/app/services/reporte.service';
import { ModalTableroControlComponent } from '../modals/modal-tablero-control/modal-tablero-control.component';
import { NovedadesEndPoint } from 'src/app/services/rrhh/novedades/novedades-endpoint';


const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
  ],
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  idRol: number;
  idComponente: number;
  txtParam: any;
  Home_idLoginAccion_Prm: number;
  Home_IdRol_Prm: number;
  Home_ComponentShow_prm: string;
  Home_Wait_prm: number;
  autenticado: boolean;
  date = new UntypedFormControl(moment());
  tableroCards: Array<TableroCard> = [];
  idSeccion = 0;
  periodo: any;
  dataSended$ = new Observable<TableroDetail>();
  cmbSectorList: CmbSector[] = [];
  periodoConverted: string;
  maxDate = new Date();
  minDate: Date;
  @ViewChildren('cardsContainer', { read: ElementRef }) cardsContainer: QueryList<ElementRef>;
  contentLoaded = false;
  loadingFile = false;
  pdfReq = true;
  dotacionObj: { bajas, dFinal, dInicial, ingresos };
  mensajeObj: { ActivarAlerta: boolean, MensajeAlerta: string } = { ActivarAlerta: false, MensajeAlerta: '' };

  constructor(
    private tableroService: TableroService,
    private novedadesService: NovedadesEndPoint,
    private userValuesService: UserValuesService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private senderService: SenderService,
    private _snackBar: SnackBarService,
    private fileService: FileService,
    private spinnerNgx: NgxSpinnerService,
    private reporteService: ReporteService,
    private dialog: MatDialog) {
    this.senderService.$objSource.subscribe(data => this.autenticado = data);
    this.Home_IdRol_Prm = this.userValuesService.getUsuarioValues.IdRol;
    this.senderService.$objSourceTableroDetalle.subscribe((res: TableroDetail) => {
      if (res && res.TableroFilters) {
        this.periodo = res.TableroFilters.Periodo;
        this.date.setValue(this.periodo);
        this.idSeccion = res.TableroFilters.IdSeccion;
      }
    });

    route.events.subscribe(
      (event) => {
        if (event instanceof NavigationEnd) {
          const urlArr = ['/', '/detalle-tablero', '/home'];
          if (!urlArr.includes(event.url)) {
            this.setSenderObject(null);
          }
          return true;
        }
        return true;
      });
  }

  ngOnInit() {
    this.Home_ComponentShow_prm = 'Home';

  }

  ngOnDestroy() { }

  ngAfterViewInit() {

  }

  Home_Get_LoginAccion_Void(idAccion: number) {
    if (idAccion == 1) {
      document.getElementById("contenedor").style.opacity = "0.5";
      document.getElementById("contenedor").style.backgroundColor = "black";
      document.getElementById("contenedor").style.pointerEvents = "none";
      document.getElementById("MenuHeader").style.opacity = "0.5";
      document.getElementById("MenuHeader").style.backgroundColor = "black";
      document.getElementById("MenuHeader").style.pointerEvents = "none";
    }
    else {
      document.getElementById("contenedor").style.opacity = "1";
      document.getElementById("contenedor").style.backgroundColor = "";
      document.getElementById("contenedor").style.pointerEvents = "";
      document.getElementById("MenuHeader").style.opacity = "1";
      document.getElementById("MenuHeader").style.backgroundColor = "";
      document.getElementById("MenuHeader").style.pointerEvents = "";
    }
    this.Home_idLoginAccion_Prm = idAccion;
  }

  Home_Get_IdRol_Void(idRol: number) {
    this.Home_IdRol_Prm = idRol;
  }

  Home_ComponentShow_void(componente: string) {
    this.Home_ComponentShow_prm = componente;
  }

  Home_Wait_void(wait: number) {
    this.Home_Wait_prm = wait;
  }

  getAutenticadoEmit(event) {
    this.autenticado = event;
  }

  filter(event: Filters) {
    this.idSeccion = event.idSector;
    this.date.setValue(moment(event.periodo + "01", "YYYYMMDD").toDate());
    this.contentLoaded = false;
    this.setCardsItems(event.periodo, event.idSector);
    this.getCardInfo(event.periodo, event.idSector);
  }

  private setCardsItems(periodo = '202103', idSector = 0) {
    this.tableroCards = [];
    this.periodo = this.date.value;
    this.idSeccion = idSector;
    let paramEntity = new ParamEntity<any>();
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    paramEntity.Periodo = periodo;
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    paramEntity.IdSeccion = this.idSeccion;
    this.tableroService.getCardsTableroControlService(paramEntity).subscribe((result: TableroCard[]) => {
      this.mensajeObj = {
        ActivarAlerta: result[0][0].ActivarAlerta,
        MensajeAlerta: result[0][0].MensajeAlerta
      }
      this.tableroCards = result;
      this.hideSpinner();
    });
  }

  hideSpinner() {
    setTimeout(() => {
      this.contentLoaded = true;
    }, 3000);
  }

  redirectDotacion(idControl, nControl) {
    const item = new TableroCard();
    item.IdControl = idControl;
    item.Ncontrol = nControl;
    this.redirect(item);
  }

  redirect(item: TableroCard) {
    const detail: TableroDetail = {
      TableroFilters: {
        IdSeccion: this.idSeccion,
        Seccion: this.cmbSectorList.find(x => x.IdSector === this.idSeccion).Nsector,
        Periodo: this.periodo
      },
      TableroItem: item
    }
    //console.log(detail)
    this.setSenderObject(detail);
    this.route.navigate(['detalle-tablero']);
  }

  private setSenderObject(value) {
    this.senderService.enviarObjetoTablero(value);
  }

  setSectorList(e) {
    this.cmbSectorList = e;
  }

  print($event) {
    this.reporteService.getReporte();
  }

  openModalChart(item: TableroCard, showPercentage) {
    const detail: TableroDetail = {
      TableroFilters: {
        IdSeccion: this.idSeccion,
        Seccion: this.cmbSectorList.find(x => x.IdSector === this.idSeccion).Nsector,
        Periodo: this.periodo
      },
      TableroItem: item
    }
    const dialogRef = this.dialog.open(ModalTableroControlComponent, {
      width: '1100px',
      height: '600px',
      panelClass: 'modal-marcacion',
      autoFocus: false,
      data: {
        titulo: 'Evolutivo Anual',
        tableroItem: detail,
        showPercentage
      }
    });
    dialogRef.beforeClosed().subscribe((result) => {

    });
  }

  setBg(estado: number) {
    switch (estado) {
      case 0:
        return 'primary';
        break;
      case 1:
        return 'secondary';
        break;
      case 2:
        return 'tertiary';
        break;
      default:
        break;
    }
  }

  private getCardInfo(periodo, seccion) {
    let paramEntity = new ParamEntity<any>();
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    paramEntity.Periodo = periodo;
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    paramEntity.IdSeccion = seccion;

    this.novedadesService.getDotacionInfoService(paramEntity).subscribe((result) => {
      if (result[0]) {
        this.dotacionObj = {
          bajas: result[0].BAJAS,
          dFinal: result[0].DOTACION_FINAL,
          dInicial: result[0].DOTACION_INICIAL,
          ingresos: result[0].INGRESOS
        };
      }
    });
  }

}
