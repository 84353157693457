import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { getKeyValue } from 'src/app/functions/key-value';
import { Filters } from 'src/app/models/general/filters.model';
import { ParamEntity } from 'src/app/models/general/param.model';
import { ReporteService } from 'src/app/services/reporte.service';
import { SenderService } from 'src/app/services/utils/sender.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';

@Component({
  selector: 'app-table-report',
  templateUrl: './table-report.component.html',
  styleUrls: ['./table-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableReportComponent implements OnInit, AfterViewInit, OnDestroy {
  dataSource = new MatTableDataSource<any>([]);
  columns: string[];
  paramEntity = new ParamEntity();
  dataSended$ = new Observable<ParamEntity<any>>();
  @ViewChild('detallePaginator', { static: true }) paginator: MatPaginator;
  @Input() set setTableParams(values) {
    this.paramEntity = values;
    if (!this.paramEntity.ClearTable) {
      this.ngAfterViewInit();
    } else {
      this.clearValues();
    }

  }

  constructor(
    private userValuesService: UserValuesService,
    private reporteService: ReporteService,
    private senderService: SenderService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    if (this.paramEntity.SpName && this.paramEntity.IdEmpresa) {
      setTimeout(() => {
        this.getTablaReporte();
      });
    }

  }

  ngOnDestroy() {
    this.clearValues();
    this.paramEntity = new ParamEntity();
  }

  applyFilter(event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openModalData(row) {

  }

  private getTablaReporte() {
    this.reporteService.getTablaReporteService(this.paramEntity).subscribe((result: any[]) => {
      if (result && result.length) {
        this.columns = getKeyValue(result)[0];
        this.dataSource.data = result;
        this.changeDetectorRef.detectChanges();
      } else {
        this.clearValues();
      }
    })
  }

  private clearValues() {
    this.dataSource = new MatTableDataSource<any>([]);
    this.columns = [];
  }

}
