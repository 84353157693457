<div class="container">
  <div class="row">
    <div class="col-6 list-tollbar style-list">
      <mat-icon [matTooltip]="'Nuevo'" (click)="openModal()">add</mat-icon>
      <app-list-a (editElementEmit)="getOptionSelected($event)"></app-list-a>
    </div>

    <div class="col-6 list-tollbar style-list">
      <app-list-b></app-list-b>
    </div>
  </div>
</div>