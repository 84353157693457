<div class="row mt-5">
  <div class="col-sm-12 col-md-6 mx-auto">
    <form>
      <mat-card>
        <mat-card-subtitle>Autenticación</mat-card-subtitle>
        <mat-card-content>
          <p>Ingrese el código de seguridad para poder gestionar el documento Sniper escaneado</p>

          <mat-form-field appearance="outline" class="code-mat-form-field">
            <mat-label>Código</mat-label>
            <input matInput type="password" placeholder="Ingrese el código" [(ngModel)]="code" name="code"
              sniperAutofocus>
          </mat-form-field>
        </mat-card-content>
        <mat-divider inset></mat-divider>
        <mat-card-actions align="end" class="p-2">
          <button mat-raised-button class="mt-1" (click)="continue()" color="primary"
            [disabled]="!code">Ingresar</button>
        </mat-card-actions>
        <mat-card-footer>
          <mat-progress-bar mode="indeterminate" *ngIf="showLoader"></mat-progress-bar>
        </mat-card-footer>
      </mat-card>
    </form>
  </div>
</div>