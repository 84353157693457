<div class="container-fluid mt-3">
  <div class="spinner-container" *ngIf="!contentLoaded">
    <mat-spinner></mat-spinner>
  </div>

  <div class="row">
    <div class="col-6 mx-auto text-center text-white">
      <app-alert [titulo]="'Detalle'"></app-alert>
    </div>
  </div>
  <div class="row">
    <div class="col-5 d-flex align-items-center pl-5">
      <mat-label>{{ infoText }}</mat-label>
    </div>
    <div class="col-4 text-center ml-auto">
      <button mat-icon-button class="icon-action-print" matTooltip="Exportar a excel" aria-label="Exportar a excel" [disabled]="loadingFile"
              (click)="printFile(true)">
        <i class="fas fa-file-excel"></i>
      </button>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <mat-form-field class="filter-input" >
        <mat-label>Filtrar</mat-label>
        <input matInput (keyup)="filtrarGrilla($event)" sniperAutofocus>
      </mat-form-field>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-parte-diario">
        <ng-container *ngFor="let col of columns" [matColumnDef]="col">
          <th class="border bg-secondary  text-white" mat-header-cell *matHeaderCellDef> {{ col }} </th>
          <td  class="border" mat-cell *matCellDef="let element"  matTooltip="{{ element[col] }}"> {{ element[col] }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;" (click)="openModalData(row)"></tr>
      </table>

      <mat-paginator #detallePaginator [pageSizeOptions]="[1000, 500]"></mat-paginator>
    </div>
  </div>
</div>
