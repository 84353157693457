import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { datePeriodConverter } from 'src/app/functions/date-period';
import { Filters } from 'src/app/models/general/filters.model';
import { ParamEntity } from 'src/app/models/general/param.model';
import { Reporte } from 'src/app/models/rrhh/reporte.model';
import { ReporteService } from 'src/app/services/reporte.service';
import { SenderService } from 'src/app/services/utils/sender.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-table-report-individual',
  templateUrl: './table-report-individual.component.html',
  styleUrls: ['./table-report-individual.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    // { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
  ]
})
export class TableReportIndividualComponent implements OnInit, OnDestroy {
  paramEntity = new ParamEntity<any>();
  filters = new Filters();
  @Input() dataSource = new MatTableDataSource<any>([]);
  @Input() columns: string[];

  constructor(
    private userValuesService: UserValuesService,
    private senderService: SenderService,
    private reporteService: ReporteService,
    private _snackBar: SnackBarService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.paramEntity.ClearTable = true;
    this.senderService.enviarObjetoFiltros(this.paramEntity);
  }

  filter(event?: Filters, fromMultipleFilters = true, clearTable = false) {
    this.paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    this.paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    if (fromMultipleFilters) {
      this.paramEntity.Periodo = '';
      this.paramEntity.PeriodoDesde = event.periodo.desde;
      this.paramEntity.PeriodoHasta = event.periodo.hasta;
      this.paramEntity.IdSeccion = event.idSector;
      this.paramEntity.IdLegajo = event.legajo;
    } else {
      this.paramEntity.SpName = event.reporte.SpName;
    }

    // ver cómo armar y mandar el json


    this.paramEntity.ClearTable = clearTable;

    this.paramEntity = { ...this.paramEntity };
    this.senderService.enviarObjetoFiltros(this.paramEntity);
  }

  printExcel($event) {
    setTimeout(() => {
      this._snackBar.openSnackBar('snack-warning', 'Generando archivo. Por favor espere.');
    }, 1000);
    this.reporteService.getExcelFileService(this.paramEntity).subscribe((res) => {
      if (res) {
        setTimeout(() => {
          this._snackBar.dismissSnackbar();
        }, 3000);
      }
    });
  }

  applyFilter(event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
