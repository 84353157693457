import { Component, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ParamEntity } from 'src/app/models/general/param.model';
import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { AutogestionService } from 'src/app/services/rrhh/autogestion/autogestion.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { UserAutogestionService } from 'src/app/services/utils/user-autogestion.service';
import { DatePipe } from '@angular/common';
import { CmbEntity } from 'src/app/models/general/cmbEntity.model';
import { FileService } from 'src/app/services/file.service';
import { ModalUploadComponent } from '../modals/modal-upload/modal-upload.component';

import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';



@Component({
  selector: 'app-avisos-ausencias',
  templateUrl: './avisos-ausencias.component.html',
  styleUrls: ['./avisos-ausencias.component.scss']
})


export class AvisosAusenciasComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumns: string[] = ['ID_AVISO', "INCIDENCIA", 'FECHA_DESDE', 'FECHA_HASTA', 'DIAS', 'FECHA_REGISTRO'];
  dataSource = new MatTableDataSource<any>([]);


  listIncidenciasCmb: CmbEntity[] = [];
  incidenciaSelected: any;
  fechaPicker: Date;
  dateInput: any = '';
  fechaCtrl: UntypedFormControl;
  cantidadDias: any;
  observaciones: string;

  novedades: any[];
  urlImagen = '';
  paramEntity: ParamEntity<any>;
  filename = '';
  fileBase64: any;
  fileExtension = 'jpg';

  showDropdown = true;

  constructor(
    private userAutogestionService: UserAutogestionService,
    private autogestionService: AutogestionService,
    private _snackBar: SnackBarService,
    private datePipe: DatePipe,
    private fileService: FileService,
    private dialog: MatDialog,
    private renderer: Renderer2, private el: ElementRef
  ) { }




  ngOnInit() {
    this.getNewDate();
    this.getIncidencias();
    this.getHistorialNovedades();

    this.renderer.listen(this.el.nativeElement, 'show.bs.dropdown', (event) => {
      this.showDropdown = true;
    });
  }

  getIncidenciaSelected(item: any): void {
    this.incidenciaSelected = item.value;
    this.showDropdown = false;
  }

  mostrarList(): void {
    this.showDropdown = true;
  }


  getStartDatePickerEmit(value?) {
    this.fechaPicker = value;
    // this.calculaFecha(this.fechaPicker);
    const fechaPicker = (!value) ? this.fechaPicker : value;
  }

  calculaFecha(fechaPicker?) {
    fechaPicker = (!fechaPicker) ? this.fechaPicker : fechaPicker;

  }

  getNewDate() {
    this.fechaCtrl = (this.dateInput) ?
      new UntypedFormControl((new Date(this.dateInput)).toISOString()) :
      new UntypedFormControl(new Date().toISOString());
  }

  soloNumeros(e) {
    if (!(e.keyCode === 8
      || e.keyCode === 46
      || (e.keyCode >= 35 && e.keyCode <= 40)
      || (e.keyCode >= 48 && e.keyCode <= 57)
      || (e.keyCode >= 96 && e.keyCode <= 105)
      || (e.keyCode === 86 && e.ctrlKey === true)
      || e.keyCode === 111)) {
      e.preventDefault();
    }
  }

  private getIncidencias() {
    const paramEntity = new ParamEntity();
    paramEntity.Json = JSON.stringify({
      pIdLegajo: this.userAutogestionService.getUsuarioValues.IdUsuario,
    });

    this.autogestionService.getListIncidencias(paramEntity).subscribe((result: CmbEntity[]) => {
      this.listIncidenciasCmb = result;

    },
      (error) => { this._snackBar.openSnackBar('snack-danger', 'Hubo un error', 3000); });
  }

  private getHistorialNovedades() {
    const paramEntity = new ParamEntity();
    paramEntity.Json = JSON.stringify({
      pIdLegajo: this.userAutogestionService.getUsuarioValues.IdUsuario,
    });

    this.autogestionService.getHistorialNovedades(paramEntity).subscribe((result: ResponseHelper) => {
      this.novedades = result.DataResponse;
      this.dataSource.data = this.novedades;
      this.dataSource.paginator = this.paginator;
    },
      (error) => { this._snackBar.openSnackBar('snack-danger', 'Hubo un error', 3000); });
  }

  guardar() {
    const paramEntity = new ParamEntity();
    paramEntity.Json = JSON.stringify({
      pIdAviso: 0,
      pIdLegajo: this.userAutogestionService.getUsuarioValues.IdUsuario,
      pIdIncidencia: this.incidenciaSelected.Id,
      pIdPatologia: 0,
      pIdEstado: 1,
      pFechaDesde: this.datePipe.transform(this.fechaCtrl.value, 'dd/MM/yyyy'),
      pDias: this.cantidadDias,
      pObservaciones: this.observaciones,
      FileModel: {
        fileName: this.filename,
        fileExtension: this.fileExtension,
        fileBase64: this.fileBase64
      }
    });
    this.autogestionService.guardarAvisoAusencia(paramEntity).subscribe((result: ResponseHelper) => {
      this.getHistorialNovedades();
      let mensaje: string = null;
      if (result.Mensaje == null) {
        if (result.Ok) {
          mensaje = 'Aviso de ausencia informado con éxito.';
        } else {
          mensaje = 'Aviso de ausencia no informado.';
        }
      } else {
        mensaje = result.Mensaje;
      }
      const type = result.Ok ? 'snack-success' : 'snack-danger';
      this._snackBar.openSnackBar(type, mensaje, 3000);
      this.fechaCtrl.reset();
      this.incidenciaSelected = null;
      this.cantidadDias = null;
      this.observaciones = null;
      this.filename = null;
      this.fileBase64 = null;
    },
      (error) => { this._snackBar.openSnackBar('snack-danger', 'Hubo un error', 3000); });
  }

  inputFileChange(fileInputEvent: any) {
    const file = fileInputEvent.target.files[0];
    this.filename = file.name;
    this.urlImagen = file;
    const reader = new FileReader();
    let byteArray;

    const _this = this;

    reader.onload = (e: any) => {
      _this.urlImagen = e.target.result;
    };

    reader.addEventListener("loadend", function () {
      byteArray = _this.convertDataURIToBinary(reader.result);
      fileInputEvent.target.value = null;
      _this.fileBase64 = reader.result;
    }, false);

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  convertDataURIToBinary(dataURI) {
    let base64Index = dataURI.indexOf(';base64,') + ';base64,'.length;
    let base64 = dataURI.substring(base64Index);
    let raw = window.atob(base64);
    let rawLength = raw.length;
    let array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }



  openModalUpload(idAviso) {
    const dialogRef = this.dialog.open(ModalUploadComponent, {
      width: '80%', // Utiliza un porcentaje para el ancho del modal
      maxWidth: '400px', // Establece un ancho máximo para evitar que el modal sea demasiado grande en dispositivos pequeños
      height: 'auto', // Deja que la altura se ajuste automáticamente al contenido
      panelClass: 'modal-marcacion',
      autoFocus: false,
      data: idAviso
    });
    dialogRef.beforeClosed().subscribe((result: any) => {
      if (result) {
      }
    });
  }

}
