<div class="container mt-3">
  <div class="row">
    <div class="col-4 mx-auto text-center text-white">
      <app-alert [titulo]="'Conceptos Especiales'"></app-alert>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-2 align-self-center">
      <mat-label class="label-legajo shadow-sm">
        {{ (sectionObj.IdLegajo) ? 'Leg. ' + sectionObj.IdLegajo : 'Legajo' }}</mat-label>
    </div>
    <div class="col-1 align-self-center">
      <button mat-mini-fab class="btn-open-nomina" color="primary" matTooltip="Buscar" (click)="openModalNomina()">
        <mat-icon>search</mat-icon>
      </button>
    </div>
    <div class="col-6 align-self-center" *ngIf="sectionObj.IdLegajo">
      <mat-chip-list>
        <mat-chip class="mx-auto position-relative">
          <span>{{sectionObj.Nombre + ' ' + sectionObj.Apellido + ' (' + sectionObj.Seccion + ')' }}</span>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-4">
      <mat-form-field class="select-form-field">
        <mat-label>Concepto</mat-label>
        <mat-select [(ngModel)]="conceptoSelected" (selectionChange)="getSeleccionConcepto($event)">
          <mat-option *ngFor="let item of conceptosEspecialesCmb" [matTooltip]="item.Detalle" [value]="item.Id">
            {{ item.Detalle }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-4">
      <mat-form-field class="mat-form-field-date">
        <mat-label>Período</mat-label>
        <input matInput [matDatepicker]="dp" [formControl]="datePicker">
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp startView="multi-year" (monthSelected)="setMonthAndYear($event, dp)">
        </mat-datepicker>
      </mat-form-field>
    </div>

    <div class="col-4">
      <mat-form-field class="flex-item">
        <mat-label>Importe</mat-label>
        <input class="input-text" matInput type="text" placeholder="Importe" [(ngModel)]="importe" inputWithComma
          (keydown)="soloNumeros($event, true)"
          >
      </mat-form-field>
    </div>
  </div>

  <div class="row my-3">
    <div class="col-4">
      <button mat-raised-button  color="primary" (click)="openModalConfirmacion()"
        [disabled]="!conceptoSelected">Guardar</button>
    </div>
  </div>

  <hr>


  <div class="row">
    <div class="col">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-parte-diario">
        <ng-container *ngFor="let col of columns" [matColumnDef]="col">
          <div *ngIf="col !== 'accion';else btnAction">
            <th class="border bg-secondary text-white" mat-header-cell *matHeaderCellDef> {{ col }} </th>
            <td class="border" mat-cell *matCellDef="let element" matTooltip="{{ element[col] }}">
              {{ element[col] }}
            </td>
          </div>

          <ng-template #btnAction>
            <th mat-header-cell *matHeaderCellDef class="border bg-secondary text-white"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="text-center">
              <mat-icon matTooltip="Anular" class="icon-action-anular mr-1" [matMenuTriggerFor]="menu"
                (menuOpened)="toggleMenuOpened()" (menuClosed)="toggleMenuOpened()">
                close
              </mat-icon>
              <mat-menu #menu="matMenu" class="p-3 text-center menu-marcacion">
                <p>¿Anular registro?</p>
                <button cdkFocusInitial mat-stroked-button color="primary" class="m-2"
                  (click)="actualizarConcepto(false, element)">
                  Si
                </button>
                <button mat-stroked-button class="m-2">No</button>
              </mat-menu>
            </td>
          </ng-template>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[20, 10]"></mat-paginator>
    </div>
  </div>

</div>
