import { SourceUsuariosXplantillaJornada } from './../../../models/sources/sourceUsuariosXplantillaJornada';
import { SourcePlantillasJornadas } from './../../../models/sources/sourcePlantillasJornadas';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';

@Component({
  selector: 'app-list-plantillas-jornadas',
  templateUrl: './list-plantillas-jornadas.component.html',
  styleUrls: ['./list-plantillas-jornadas.component.scss']
})
export class ListPlantillasJornadasComponent implements OnInit {
  @Input() listPlantillasJornadas: SourcePlantillasJornadas[];
  @ViewChild('matSelection') matSelection: MatSelectionList;
  @Output() optionsPlantillaJornadaSelectedEmit = new EventEmitter();
  listPlantillaChecked: number[] = [];
  @Input() plantillaxUsuarioList: SourceUsuariosXplantillaJornada[];


  constructor() { }

  ngOnInit() {
  }

  getSelected(e) {
    if (e.options[0]?.selected) {
      this.listPlantillaChecked.push(e.options[0].value.IdPlantillaJornada);
      this.optionsPlantillaJornadaSelectedEmit.emit(this.listPlantillaChecked);
    } else {
      const index = this.listPlantillaChecked.findIndex(x => x === e.options[0].value.IdPlantillaJornada);
      this.listPlantillaChecked.splice(index, 1);
      this.optionsPlantillaJornadaSelectedEmit.emit(this.listPlantillaChecked);
    }

  }

  setCheckValues(idUsuario: number) {
    this.listPlantillaChecked = [];
    var listPlantillaXusuario: number[] = this.plantillaxUsuarioList.filter(x => x.IdUsuario === idUsuario).map(x => x.IdPlantillaJornada);

    this.matSelection.options.forEach(x => {
      if (listPlantillaXusuario.indexOf(x.value.IdPlantillaJornada) > -1) {
        x._setSelected(true);
        this.listPlantillaChecked.push(x.value.IdPlantillaJornada);
      } else {
        x._setSelected(false);
      }
    });
  }


  clearForms() {
    this.matSelection.deselectAll();
    this.listPlantillaChecked = [];
    this.optionsPlantillaJornadaSelectedEmit.emit(this.listPlantillaChecked);
  }



}
