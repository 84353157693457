import { Component } from '@angular/core';
import { ModalFormVacacionesComponent } from '../../../components/modals/modal-form-vacaciones/modal-form-vacaciones.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'pageTestComponent',
  templateUrl: './page-test.component.html',
  styleUrls: ['./page-test.component.css']
})

export class pageTestComponent {

  constructor(
    private dialog: MatDialog
  ) { }




  openModal() {
    const dialogRef = this.dialog.open(ModalFormVacacionesComponent, {
      width: '2480px',
      height: '500px',
      autoFocus: false,
      data: {
        titulo: '¿Desea guardar el aviso?'
      }
    });
    dialogRef.beforeClosed().subscribe((result) => {
      if (result) {
        //console.log("TEst");
      }
    });
  }

}
