import { Component, Inject, OnInit } from '@angular/core';
import { datePeriodConverter } from 'src/app/functions/date-period';
import { getKeyValue } from 'src/app/functions/key-value';
import { ParamEntity } from 'src/app/models/general/param.model';
import { TableroCard, TableroDetail } from 'src/app/models/general/tablero-card';
import { TableroService } from 'src/app/services/tablero.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Chart, ChartData, ChartOptions, ChartType } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export interface PeriodicElement {
  name: string;
  position: number;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Hydrogen' },
  { position: 1, name: 'Hydrogen' },
  { position: 1, name: 'Hydrogen' },
  { position: 1, name: 'Hydrogen' }
];

Chart.register(ChartDataLabels);

@Component({
  selector: 'app-modal-tablero-control',
  templateUrl: './modal-tablero-control.component.html',
  styleUrls: ['./modal-tablero-control.component.scss']
})
export class ModalTableroControlComponent implements OnInit {
  displayedColumns: string[] = ['position', 'name'];
  title = 'bar-chart';
  titulo = 'Datos';
  tableroValues = new TableroDetail();
  dataSource = new MatTableDataSource<any>([]);
  columns: string[];
  public showPercentage = false;

  // // CHART

  public chart: Chart;
  chartData = {
    labels: [],
    datasets: [{
      label: '',
      data: [],
      backgroundColor: [
        'rgba(225,10,24,0.2)'
      ],
      borderColor: [
        'rgba(225,10,24,0.2)'
      ],
      pointBackgroundColor: [
        'rgba(225, 10, 24, 0.2)'
      ],
      pointBorderColor: ['#fff'],
      pointHoverBackgroundColor: ['#fff'],
      pointHoverBorderColor: ['rgba(225,10,24,0.2)'],
      borderWidth: 1
    }],
    plugin: [ChartDataLabels]
  };


  constructor(
    public dialogRef: MatDialogRef<ModalTableroControlComponent>,
    private userValuesService: UserValuesService,
    @Inject(MAT_DIALOG_DATA) public data,
    private tableroService: TableroService
  ) {
    this.titulo = data.titulo;
    this.tableroValues = data.tableroItem;
    this.showPercentage = data.showPercentage;
  }

  ngOnInit() {
    const showPercentage = this.showPercentage;
    this.chart = new Chart("chart", {
      type: 'bar' as ChartType, // tipo de la gráfica
      data: this.chartData, // datos,
      options: {
        responsive: true,
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
            display: true,
            formatter: function (value, context) {
              return showPercentage ? value + '%' : value;
            }
          }
        }
      }
    })
    this.getData();
  }

  private getData() {
    const paramEntity = new ParamEntity();
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    paramEntity.IdTcontrol = this.tableroValues.TableroItem.IdControl;
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    paramEntity.Periodo = datePeriodConverter(this.tableroValues.TableroFilters.Periodo, true);
    paramEntity.IdSeccion = this.tableroValues.TableroFilters.IdSeccion;

    this.tableroService.getTableroEvolutivoAnual(paramEntity).subscribe((result: any[]) => {
      if (result) {
        const ignoredFields = [
          'O_ORDEN'
        ];
        this.columns = getKeyValue(result, ignoredFields)[0];
        this.dataSource.data = result.map((value) => {
          return {
            O_ORDEN: value.O_ORDEN,
            PERIODO: value.PERIODO,
            VALOR: this.showPercentage ? value.VALOR + '%' : value.VALOR
          }
        });
        this.setBarChartData(result);
      }
    })
  }

  private setBarChartData(result: any[]) {
    const reversedArr = [...result.slice().reverse()];
    const data = reversedArr.map(item => item.VALOR);
    this.chartData.labels = reversedArr.map(item => item.PERIODO);;
    this.chartData.datasets[0].data = data;
    this.chartData.datasets[0].label = this.tableroValues.TableroItem.Ncontrol;
  }

  cerrarModal() {
    this.dialogRef.close();
  }

  openModalData() {

  }

}
