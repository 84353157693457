import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelectionList, MatListOption } from '@angular/material/list';
// import { MatLegacySelectionList as MatSelectionList, MatLegacyListOption as MatListOption } from '@angular/material/legacy-list';
import { SourceUsuarios } from 'src/app/models/sources/sourceUsuarios';

@Component({
  selector: 'app-list-usuarios',
  templateUrl: './list-usuarios.component.html',
  styleUrls: ['./list-usuarios.component.scss']
})
export class ListUsuariosComponent implements OnInit {
  @Input() usuariosList: SourceUsuarios[] = [];
  @ViewChild('matSelection', { static: true }) matSelection: MatSelectionList;
  @Output() usuarioSelectedEmit = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.matSelection.selectedOptions = new SelectionModel<MatListOption>(false);
  }

  getSelected(e) {
    const element = e.options[0].selected ? e.options[0].value : null;
    this.usuarioSelectedEmit.emit(element);
  }

  clearForms() {
    this.usuarioSelectedEmit.emit([]);
    this.matSelection.deselectAll();
  }

}
