import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyListOption as MatListOption, MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { Filters } from 'src/app/models/general/filters.model';
import { ParamEntity } from 'src/app/models/general/param.model';
import { Reporte } from 'src/app/models/rrhh/reporte.model';
import { ReporteService } from 'src/app/services/reporte.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';

@Component({
  selector: 'app-selection-report-individual-list',
  templateUrl: './selection-report-individual-list.component.html',
  styleUrls: ['./selection-report-individual-list.component.scss']
})
export class SelectionReportIndividualListComponent implements OnInit {
  // @ViewChild(MatSelectionList, { static: true })
  // private selectionList: MatSelectionList;
  @Output() selectedOptionEmit = new EventEmitter<Filters>();
  reportesList: Reporte[];
  selectedReporte: Reporte;

  constructor(
    private reporteService: ReporteService,
    private userValuesService: UserValuesService
  ) { }

  ngOnInit() {
    this.getReportesList();
    this.selectedReporte = new Reporte();
  }

  getSelectedOption(event) {
    this.selectedReporte = event.value;
    const reporte: Filters = {
      reporte: this.selectedReporte
    };
    this.selectedOptionEmit.emit(reporte);
  }

  private getReportesList() {
    const paramEntity = new ParamEntity();
    paramEntity.Json = JSON.stringify({
      pIdUsuario: this.userValuesService.getUsuarioValues.IdUsuario
    });

    this.reporteService.getReportesIndividualesListService(paramEntity).subscribe((result: Reporte[]) => {
      if (result) {
        this.reportesList = result;
      }
    });
  }

}
