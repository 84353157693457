<div class="container-fluid mt-3">
  <div class="spinner-container" *ngIf="!contentLoaded">
    <mat-spinner></mat-spinner>
  </div>
  <div class="row">
    <div class="col-6 mx-auto text-center text-white">
      <app-alert [titulo]="'Liquidaciones'"></app-alert>
    </div>
  </div>
  <div class="row">
    <div class="col-6 ml-auto">
      <app-filter-card (filterEmit)="filter($event)" [hasLegajoInput]="false" [hasExportExcelButton]="true"
        (printExcelEmit)="printFile(true)" [exportExcelButtonDisabled]="false"
        [allowOneMoreDay]="true"></app-filter-card>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-2">
      <mat-form-field class="w-90">
        <mat-label>Filtrar</mat-label>
        <input matInput (keyup)="applyFilter($event)">
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="table-container">
        <table #matTable mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <!-- Agrupadores -->
          <ng-container *ngFor="let groupCol of groupColDef" [matColumnDef]="groupCol.colDef">
            <th mat-header-cell *matHeaderCellDef [style.text-align]="'center'" [attr.colspan]="groupCol.colSpan"
              class="bg-agrupador text-white">
              {{ groupCol.colDef }}
            </th>
          </ng-container>

          <!-- Filas subcolumnas -->
          <ng-container *ngFor="let col of columnsDef, let i = index" [matColumnDef]="col"
            [sticky]="isSticky(col, null)">
            <div *ngIf="col !== 'accion';else btnAction">
              <th class="border bg-title" mat-header-cell *matHeaderCellDef> {{ col }} </th>
              <td class="border" mat-cell *matCellDef="let element" matTooltip="{{ element[col] }}"
                [ngClass]="isSticky(col, element)">
                {{ getCellData(element[col], col) }}
              </td>
            </div>

            <ng-template #btnAction>
              <th mat-header-cell *matHeaderCellDef class="border text-white"> </th>
              <td mat-cell *matCellDef="let element; let i = index" class="text-center">
                <button mat-mini-fab class="btn-open-detalle mx-3" color="primary" matTooltip="Ver detalle"
                  (click)="openModal(element)">
                  <mat-icon>description</mat-icon>
                </button>
              </td>
            </ng-template>

          </ng-container>

          <!-- Nombres de agrupadores -->
          <tr mat-header-row *matHeaderRowDef="groupColHeaders; sticky: true"></tr>

          <!-- Nombres de columnas -->
          <tr mat-header-row *matHeaderRowDef="columnsDef; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsDef;" [ngClass]="getRowClassCondition(row)"></tr>
        </table>
      </div>


      <mat-paginator #detallePaginator [pageSizeOptions]="[1000, 500]"></mat-paginator>
    </div>
  </div>
</div>