<h1 mat-dialog-title class="bg-primary titulo-modal">Detalle
  <mat-icon class="btn-close" (click)="cerrarModal()">clear</mat-icon>
</h1>
<div mat-dialog-content>
  <div class="container-fluid h-100">
    <div class="row">
      <div class="col-3">
        <p>Periodo: {{ dataObj.Periodo }}</p>
        <p>Legajo: {{ dataObj.GenericEntity.ID_LEGAJO }}</p>
        <p>Legajo de liquidación: {{ dataObj.GenericEntity.ID_LEGAJO_LIQUIDACION }}</p>
        <p>Fecha Baja: {{ dataObj.GenericEntity.FECHA_BAJA }}</p>
      </div>
      <div class="col-3">
        <p>Colaborador: {{ getColaborador() | titlecase }}</p>
        <p>Sección: {{ dataObj.GenericEntity.SECCION | titlecase }}</p>
        <p>Fecha Ingreso: {{ dataObj.GenericEntity.FECHA_INGRESO_CALC }}</p>
        <p>Antigüedad: {{ dataObj.GenericEntity.ANTIGUEDAD }}</p>
      </div>
      <div class="col d-flex justify-content-end align-items-end mb-3">
        <div class="text-center mx-2">
          <button mat-icon-button class="icon-action-print" matTooltip="Exportar a pdf" aria-label="Exportar a pdf"
             (click)="printFile(true)">
            <i class="far fa-file-pdf"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-2">
        <mat-form-field class="w-90">
          <mat-label>Filtrar</mat-label>
          <input matInput (keyup)="applyFilter($event)">
        </mat-form-field>
      </div>
    </div>

    <div class="table-container">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-parte-diario">
        <ng-container *ngFor="let col of columns" [matColumnDef]="col" [sticky]="isSticky(col)">
          <div *ngIf="col != undefined && col === 'accion'">
            <th class="border bg-secondary  text-white" mat-header-cell *matHeaderCellDef> </th>
            <td class="border" mat-cell *matCellDef="let element">
              <button mat-mini-fab class="btn-open-detalle mx-3" color="primary" matTooltip="Modificar Novedades" (click)="openModalData(element)">
                <mat-icon>event</mat-icon>
              </button>
            </td>
          </div>
          <div *ngIf="col != undefined && col !== 'accion'">
            <th class="border bg-secondary  text-white" mat-header-cell *matHeaderCellDef> {{ col }} </th>
            <td class="border" mat-cell *matCellDef="let element" matTooltip="{{ element[col] }}"
            [ngClass]="isSticky(col, element)">
            {{ element[col] }}
            </td>
          </div>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
      </table>

      <!-- <mat-paginator #detallePaginator [pageSizeOptions]="[1000, 500]"></mat-paginator> -->
    </div>
  </div>
</div>
