export class MonitorProcesos {
  vEstadoReprocesos: number;
  vUltimoReproceso: Date;
  vCantidadReprocesosPendientes: number;

  vEstadoProcAvisos: number;
  vUltimoProcAvisos: Date;
  vCantidadProcAvisosPend: number;


  vEstadoProcJustificaciones: number;
  vUltimoProcJustificaciones: Date;
  vCantidadProcJustificacionesPend: number;
}
