<h1 mat-dialog-title class="bg-primary titulo-modal">Jornadas Habituales
  <mat-icon class="btn-close" (click)="cerrarModal()">clear</mat-icon>
</h1>
<div mat-dialog-content>
  <p class="p-nombre w-100">
    <span>Leg. <b>{{ ' ' + objeto.IdLegajo }}</b> {{' - ' + objeto.Nombre + ' ' + objeto.Apellido }}</span>
  </p>
  <p class="p-nombre w-100 mb-1">
    <span>Sección {{ ' ' + objeto.Seccion }}</span>
  </p>
  <div class="container h-100 modal-jornadas-habituales">
    <div class="row" *ngFor="let item of diasList">
      <div class="col mx-auto">
        <div class="input-container">
          <!-- <mat-form-field class="w-100 select-form-field">
            <mat-label>{{ item.Header }}</mat-label>
            <mat-select [(ngModel)]="item.id">
              <mat-option *ngFor="let item of lstJornadas" [matTooltip]="item.Detalle" [value]="item.Id">
                {{ item.Detalle }}
              </mat-option>
            </mat-select>
          </mat-form-field> -->

          <mat-form-field class="w-100">
            <mat-label>{{ item.Header }}</mat-label>
            <input type="text" placeholder="" matInput [(ngModel)]="item.value"
              (ngModelChange)="getFilteredList($event, item); item.id = $event.Id" [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
              (optionSelected)="getFilteredList('')">
              <mat-option *ngFor="let itemOption of filteredOptions | async" [matTooltip]="itemOption.Detalle"
                [value]="itemOption">
                {{ itemOption.Detalle }}
              </mat-option>
            </mat-autocomplete>
            <mat-hint *ngIf="item.error" class="error">Debe seleccionar un elemento de la lista</mat-hint>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="p-2 text-right">
      <button mat-raised-button (click)="openModalData()" [disabled]="validateList()">Guardar</button>
    </div>
  </div>
</div>