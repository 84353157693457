import { Component, Inject, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { dateStrSplitter } from 'src/app/functions/date-period';
import { getKeyValue } from 'src/app/functions/key-value';
import { ParamEntity } from 'src/app/models/general/param.model';
import { Novedades } from 'src/app/models/rrhh/novedades/novedades.model';
import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { NovedadesService } from 'src/app/services/rrhh/novedades/novedades.service';
import { TableroService } from 'src/app/services/tablero.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { ModalMarcacionComponent } from '../modal-marcacion/modal-marcacion.component';
import { excelLiquidacionesRequest } from 'src/app/models/general/excelLiquidacionesRequest';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-modal-detalle-liquidacion',
  templateUrl: './modal-detalle-liquidacion.component.html',
  styleUrls: ['./modal-detalle-liquidacion.component.scss']
})
export class ModalDetalleLiquidacionComponent implements OnInit {
  dataObj: ParamEntity<any>;
  columns: string[] = [];
  dataSource = new MatTableDataSource<any>([]);
  @ViewChild('detallePaginator', { static: true }) paginator: MatPaginator;
  stickyColumns = [
    'accion',
    'FECHA',
    'DIA',
    'N_JORNADA'
  ];
  actualizaTabla = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ParamEntity<any>,
    private novedadesService: NovedadesService,
    public dialogRef: MatDialogRef<ModalDetalleLiquidacionComponent>,
    public dialog: MatDialog,
    private tableroService: TableroService,
    private userValuesService: UserValuesService,
    private _snackBar: SnackBarService,
    private changeDetectorRefs: ChangeDetectorRef,
    private fileService: FileService
  ) {
    this.dataObj = data;
  }

  ngOnInit() {
    // this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit() {
    this.getDetalleLiquidacion();

  }

  cerrarModal() {
    this.dialogRef.close(this.actualizaTabla);
  }

  private getDetalleLiquidacion() {
    const params = new ParamEntity<any>();
    params.IdUsuario = this.dataObj.IdUsuario;
    params.IdEmpresa = this.dataObj.IdEmpresa;
    params.IdLegajo = this.dataObj.GenericEntity.ID_LEGAJO;
    params.Periodo = this.dataObj.Periodo;
    params.IdSeccion = this.dataObj.IdSeccion;

    this.novedadesService.getDetalleLiquidacion(params).subscribe((result: any) => {
      if (result) {
        if (result && result.length) {
          const ignoredFields = [
            '^O_',
            '^S_'
          ];
          this.columns = getKeyValue(result, ignoredFields)[0];
          this.columns.unshift('accion');
          this.changeDetectorRefs.detectChanges();
          this.dataSource.data = result;
        } else {
          this.columns = [];
          this.dataSource.data = [];
        }
      }
    });

  }

  getColaborador() {
    return this.dataObj.GenericEntity.APELLIDO + ' ' + this.dataObj.GenericEntity.NOMBRE;
  }

  openModalData(row) {
    //DESCOMENTAR CUANDO HAYA QUE IMPLEMENTAR
    if (row && row.FECHA) { // SE TOMAN LOS CAMPOS LEGAJO Y FECHA TAL CUAL LLEGAN DEL SP
      const paramEntity = new ParamEntity();
      paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
      paramEntity.IdLegajo = this.dataObj.GenericEntity.ID_LEGAJO;
      paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
      paramEntity.FechaDate = dateStrSplitter(row.FECHA, true) as Date;

      this.tableroService.getNovedadDetalleService(paramEntity).subscribe((result: Novedades) => {
        if (result) {
          result.FechaDate = paramEntity.FechaDate; // adjunto la fecha tomada de la row
          this.showModal(result);
        } else {
          this._snackBar.openSnackBar('snack-danger', 'Intente nuevamente', 3000);
        }
      }, (error) => {
        this._snackBar.openSnackBar('snack-danger', 'Intente nuevamente', 3000);
      })

    }

  }

  private showModal(row) {
    const dialogRef = this.dialog.open(ModalMarcacionComponent, {
      width: '1000px',
      height: '500px',
      panelClass: 'modal-marcacion',
      autoFocus: false,
      data: {
        titulo: 'Novedades',
        obj: row,
      }
    });
    dialogRef.beforeClosed().subscribe((result) => {
      if (result.actualiza) {
        this.actualizaTabla = true;
        this.getDetalleLiquidacion();
      }
    });
  }

  printFile(soloDescarga: boolean, url?, fileName?) {
    // DESCOMENTAR CUANDO HAYA QUE IMPLEMENTAR
    let params = new excelLiquidacionesRequest();
    params.idEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    params.idUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    params.idLegajo = parseInt(this.dataObj.GenericEntity.ID_LEGAJO);
    params.periodo = this.dataObj.Periodo;  //datePeriodConverter(this.tableroValues.TableroFilters.Periodo, true);
    params.idSeccion = this.dataObj.IdSeccion;
    params.fileName = "Liquidaciones_Detalle";

    //this.loadingFile = true; // inhabilito todos los controles del html
    // SI ES TRUE, USO TIMEOUT PARA QUE NO SE PISE CON EL MENSAJE ANTERIOR
    setTimeout(() => {
      this._snackBar.openSnackBar('snack-warning', 'Generando archivo. Por favor espere.');
    }, 1000);

    this.fileService.downloadXlsxLiquidacionesDetalle(params).subscribe((resultDownload) => {
      if (resultDownload) {
        if (!soloDescarga) {
          // SI SE DESCARGA CORRECTAMENTE LIMPIO TODO Y ACTUALIZO LOS DATOS
          //console.log("Descarga Ok");
        }
        // USO EL TIMEOUT DE NUEVO PARA ELIMINAR EL MENSAJE ANTERIOR
        setTimeout(() => {
          this._snackBar.dismissSnackbar();
        }, 3000);

      } else {
        this._snackBar.openSnackBar('snack-danger', 'Hubo un error al intentar descargar el archivo', 3000);
      }
      //this.loadingFile = false; // VUELVO A HABILITAR LOS CONTROLER
    }, (errorDescarga) => {
      this._snackBar.openSnackBar('snack-danger', errorDescarga, 5000);
      //this.loadingFile = false; // VUELVO A HABILITAR LOS CONTROLER
    });



  }

  isSticky(col, element) {
    if (element) {
      if (col === 'I_SIN_INCIDENCIAS' && Number(element.I_SIN_INCIDENCIAS) > 0) { // ESTILO PARA LA COL IMPARES CON CONDICION
        return 'bg-celda';
      }
      if (col === 'I_IMPARES' && Number(element.I_IMPARES) > 0) { // ESTILO PARA LA COL SIN_INCIDENCIA CON CONDICION
        return 'bg-celda';
      }
      if (col === 'JORNADA_SUPERPUESTA' && Number(element.JORNADA_SUPERPUESTA) > 0) { // ESTILO PARA LA COL
        return 'bg-celda';
      }
      if (col === 'MARCACIONES_FUERA_JORNADA' && Number(element.MARCACIONES_FUERA_JORNADA) > 0) { // ESTILO PARA LA COL
        return 'bg-celda';
      }
      if (col === 'DESCANSO_INCUMPLIDO' && Number(element.DESCANSO_INCUMPLIDO) > 0) { // ESTILO PARA LA COL
        return 'bg-celda';
      }
      if (col === 'FERIADO_TRABAJADO' && Number(element.FERIADO_TRABAJADO) > 0) { // ESTILO PARA LA COL
        return 'bg-celda';
      }
      if ((col === 'FECHA' || col === 'DIA' || col === 'N_JORNADA') && Number(element.O_ES_FRANCO) > 0) { // ESTILO PARA LAS COLS FECHA/DIA/N_JORNADA CON CONDICION SI ES FRANCO
        return 'bg-celda-franco';
      }
      if ((col === 'FECHA' || col === 'DIA' || col === 'N_JORNADA') && Number(element.O_ES_FERIADO) > 0) { // ESTILO PARA LA COL FECHA/DIA/N_JORNADA CON CONDICION SI ES FERIADO
        return 'bg-celda-feriado';
      }
      if ((col === 'FECHA' || col === 'DIA' || col === 'N_JORNADA') && Number(element.O_ES_FERIADO) == 0 && Number(element.O_ES_FRANCO) == 0) { // ESTILO PARA LA COL FECHA/DIA/N_JORNADA CON CONDICION SI NO ES NI FRANCO
        return 'bg-celda';
      }

      return '';
    }

    return this.stickyColumns.includes(col);
  }

  applyFilter(event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
