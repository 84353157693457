<div class="container-fluid p-0 contenedor-bordes">
  <p class="p-title">Turno 2 </p>
  <span class="badge rounded-pill bg-danger fw-normal turnoSuperpuesto bounce" *ngIf="turnosSuperpuestos">Turno superpuesto</span>
  <form [formGroup]="form">
    <table>
      <tr>
        <th>
          <p class="table-title">INGRESO</p>
        </th>
        <th>
          <p class="table-title">EGRESO</p>
        </th>
      </tr>
      <tr>
        <td>
          <mat-list role="list">
            <mat-list-item role="listitem">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Día</mat-label>
                <mat-select formControlName="diaIngreso" (selectionChange)="getSeleccionDiaIngreso($event)">
                  <mat-option *ngFor="let item of diasOptions" [value]="item.Id">
                    {{ item.Detalle }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item role="listitem">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Hora</mat-label>
                <input matInput type="text" formControlName="horaIngreso" placeholder="HH:MM" maxlength="5"
                       (keydown)="soloNumeros($event, true)" (input)="convert($event)"
                       [ngClass]="{'turnoSuperpuestoInput': turnosSuperpuestos, 'mismosHorariosInput': controlMismosHorarios}">
              </mat-form-field>
            </mat-list-item>
            <mat-list-item role="listitem">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Margen</mat-label>
                <input matInput type="text" formControlName="margenIngreso" placeholder="HH:MM" maxlength="5"
                       (keydown)="soloNumeros($event, true)" (input)="convert($event)"
                       [ngClass]="{'turnoSuperpuestoInput': turnosSuperpuestos}">
              </mat-form-field>
            </mat-list-item>
            <mat-list-item role="listitem">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Tolerancia</mat-label>
                <input matInput type="text" formControlName="toleranciaIngreso" placeholder="HH:MM" maxlength="5"
                       (keydown)="soloNumeros($event, true)" (input)="convert($event)">
              </mat-form-field>
            </mat-list-item>
          </mat-list>
        </td>
        <td>
          <mat-list role="list">
            <mat-list-item role="listitem">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Día</mat-label>
                <mat-select formControlName="diaEgreso">
                  <mat-option *ngFor="let item of diasOptions" [value]="item.Id">
                    {{ item.Detalle }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item role="listitem">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Hora</mat-label>
                <input matInput type="text" formControlName="horaEgreso" placeholder="HH:MM" maxlength="5"
                       (keydown)="soloNumeros($event, true)" (input)="convert($event)"
                       [ngClass]="{'mismosHorariosInput': controlMismosHorarios}"
                       >
              </mat-form-field>
            </mat-list-item>
            <mat-list-item role="listitem">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Margen</mat-label>
                <input matInput type="text" formControlName="margenEgreso" placeholder="HH:MM" maxlength="5"
                       (keydown)="soloNumeros($event, true)" (input)="convert($event)">
              </mat-form-field>
            </mat-list-item>
            <mat-list-item role="listitem">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Tolerancia</mat-label>
                <input matInput type="text" formControlName="toleranciaEgreso" placeholder="HH:MM" maxlength="5"
                       (keydown)="soloNumeros($event, true)" (input)="convert($event)">
              </mat-form-field>
            </mat-list-item>
          </mat-list>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <p class="table-title"><strong>HORAS TEORICAS: {{ this.HTeoricas ? this.HTeoricas : '' }}</strong></p>
        </td>
      </tr>
    </table>
  </form>
</div>
