import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataShareService {
  private element$ = new BehaviorSubject<any>(null);
  selectedElement$ = this.element$.asObservable();

  constructor() { }

  setProduct(item: any) {
    this.element$.next(item);
  }
}
