import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CmbEntity } from 'src/app/models/general/cmbEntity.model';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { map, startWith } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { NovedadesService } from 'src/app/services/rrhh/novedades/novedades.service';
import { ParamEntity } from 'src/app/models/general/param.model';

@Component({
  selector: 'app-modal-gestion-incidencia',
  templateUrl: './modal-gestion-incidencia.component.html',
  styleUrls: ['./modal-gestion-incidencia.component.scss']
})
export class ModalGestionIncidenciaComponent implements OnInit {
  objeto: any;
  myControl = new UntypedFormControl();
  filteredOptions: Observable<any[]>;
  lstIncidencias: any;
  observaciones: string;
  observacionesOrig: string;
  cmbChanged = false;

  constructor(
    public dialogRef: MatDialogRef<ModalGestionIncidenciaComponent>,
    public dialog: MatDialog,
    private _snackBar: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private novedadesService: NovedadesService,
    private userValuesService: UserValuesService
  ) {
    this.objeto = this.data;
    this.lstIncidencias = this.objeto.modalData.filter(x => x.esEntrada === this.objeto.elementSelected.EsEntrada).map((x) => ({ Id: x.Id, Detalle: x.Detalle }));
  }

  ngOnInit() {
    this.observaciones = this.objeto.elementSelected.Observaciones;
    this.subscribeEventChange();
    let jorn = new CmbEntity;
    jorn.Id = this.objeto.elementSelected.IdIncidencia;
    jorn.Detalle = this.objeto.elementSelected.Nincidencia;
    this.myControl.setValue(jorn);
  }

  private subscribeEventChange() {
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.Detalle),
        map(value => this._filter(value))
      );
  }

  private _filter(value): any[] {
    const filterValue = value.toLowerCase();
    return this.lstIncidencias.filter(option => option.Detalle.toLowerCase().includes(filterValue));
  }

  cerrarModal(estado = false) {
    this.dialogRef.close(estado);
  }

  displayFn(item: CmbEntity): string {
    return item && item.Detalle ? item.Detalle : '';
  }

  getSeleccionIncidencia(incidencia: any) {
    this.cmbChanged = (incidencia !== this.myControl.value) ? true : false;
  }

  guardar() {
    if (!this.myControl.value.Id) {
      this._snackBar.openSnackBar('snack-danger', 'Seleccione una incidencia', 3000);
      return;
    }
    const paramEntity = new ParamEntity();
    paramEntity.Json = JSON.stringify({
      pIdMarcacion: this.objeto.elementSelected.IdMarcacion,
      pIdIncidencia: this.myControl.value.Id,
      pIdUsuario: this.userValuesService.getUsuarioValues.IdUsuario,
      pIdEstado: 1,
      pObservaciones: this.observaciones
    });
    this.novedadesService.guardarAsignacionIncidencia(paramEntity).subscribe((x: ResponseHelper) => {
      if (x.Ok) {
        this._snackBar.openSnackBar('snack-success', 'Asignación guardada con éxito', 3000);
        this.observaciones = '';
        this.cerrarModal(true);
      }
    })
  }
}
