import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, throwError, TimeoutError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ParamEntity } from '../models/general/param.model';
import { tcontroldetParams } from '../models/general/tcontrolDet.entity';

@Injectable({
  providedIn: 'root'
})
export class ReporteService {

  /* Variables para llamar a API */
  private readonly _url: string = '/api/reporte';

  constructor(private http: HttpClient) { }

  protected getRequestHeaders() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': `application/json, text/plain, */*`,
      timeout: `${500000}`
    });

    return headers;
  }

  private handleError(error: HttpErrorResponse) {
    if (error instanceof TimeoutError) {
      return throwError('En este momento no podemos realizar la operación. Por favor intente nuevamente más tarde.');
    }
    return throwError(error);
  }

  /*--------------GET------------------*/

  getReportesListService(filtro) {
    const endpointUrl = this._url + '/getReportesList';
    const params = new HttpParams()
      .set('filtro', JSON.stringify(filtro));

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders(), params: params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getReportesIndividualesListService(filtro) {
    const endpointUrl = this._url + '/getReportesIndividualesList';
    const params = new HttpParams()
      .set('filtro', JSON.stringify(filtro));

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders(), params: params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getTablaReporteService(filtro) {
    const endpointUrl = this._url + '/getTablaReporte';
    const params = new HttpParams()
      .set('filtro', JSON.stringify(filtro));

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders(), params: params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getReporte(download?) {
    const sub = new Subject<boolean>();

    const url = `${this._url}/getReport`;

    this.http.get(url, { headers: this.getRequestHeaders(), responseType: 'arraybuffer' })
      .pipe(
        catchError(error => {
          return this.handleError(error);
        })).subscribe((fileResponse) => {
          if (fileResponse) {
            this.manageFile(fileResponse, download);
            sub.next(true);
          } else {
            sub.error(false);
          }
        });

    return sub;
  }


  getReportingIndividualEndpoint(filtro) {
    const endpointUrl = this._url + '/getReportingIndividual';
    const params = new HttpParams()
      .set('filtro', JSON.stringify(filtro));

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders(), params: params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getPeriodosAnualesEndpoint(filtro = '') {
    const endpointUrl = this._url + '/getPeriodosAnuales';
    const params = new HttpParams()
      .set('filtro', JSON.stringify(filtro));

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders() }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }


  private manageFile(fileResponse, download: boolean, filename = 'default') {
    var blob = new Blob([fileResponse], { type: 'application/pdf' })
    const objectUrl: string = URL.createObjectURL(blob);
    if (download) {
      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
      a.href = objectUrl;
      a.download = `${filename}.pdf`;
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(objectUrl);
    } else {
      window.open(objectUrl, '_blank');
    }

  }

  getExcelFileService(params?: ParamEntity<any>) {
    const sub = new Subject<boolean>();

    const url = `${this._url}/getExcelFile`;

    let filters = new HttpParams()
      .set('filters', JSON.stringify(params));

    this.http.get(url, { headers: this.getRequestHeaders(), params: filters, responseType: 'arraybuffer' })
      .pipe(
        catchError(error => {
          return this.handleError(error);
        })).subscribe((fileResponse) => {
          if (fileResponse) {
            const fileName = 'reporte';
            this.print_Excel_TControlDet(fileResponse, fileName);
            sub.next(true);
          } else {
            sub.error(false);
          }
        });

    return sub;
  }

  getExcelFileByJsonService(params?: ParamEntity<any>) {
    const sub = new Subject<boolean>();

    const url = `${this._url}/getExcelFileByJson`;

    let filters = new HttpParams()
      .set('filters', JSON.stringify(params));

    this.http.get(url, { headers: this.getRequestHeaders(), params: filters, responseType: 'arraybuffer' })
      .pipe(
        catchError(error => {
          return this.handleError(error);
        })).subscribe((fileResponse) => {
          if (fileResponse) {
            const fileName = 'reporte';
            this.print_Excel_TControlDet(fileResponse, fileName);
            sub.next(true);
          } else {
            sub.error(false);
          }
        });

    return sub;
  }

  private print_Excel_TControlDet(fileResponse, fileName: any) {
    var blob = new Blob([fileResponse], { type: 'application/vnd.ms-excel' })
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = fileName + ".xlsx";
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

  /*
  private print_Excel_LiquidacionesConsolidadas(fileResponse, fileName: any) {
    var blob = new Blob([fileResponse], { type: 'application/vnd.ms-excel' })
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.download = fileName + ".xlsx";
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }*/


}
