<h1 mat-dialog-title class="bg-primary">Video Instructivo
  <mat-icon class="btn-close" (click)="cerrarModal(null)">clear</mat-icon>
</h1>

<div mat-dialog-content>
  <div class="row">
    <div class="col-12">
      <div class="bg-video-wrap">
        <video autoplay controls>
          <source src="../../../assets/instructivos/Vacaciones.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  </div>
</div>