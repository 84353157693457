import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableroCard } from 'src/app/models/general/tablero-card';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-swiper-cards',
  templateUrl: './swiper-cards.component.html',
  styleUrls: ['./swiper-cards.component.scss']
})
export class SwiperCardsComponent implements OnInit {
  @Input() tableroCards: Array<TableroCard> = [];
  @Output() redirectEmit = new EventEmitter();
  @Output() openModalEmit = new EventEmitter();
  config: SwiperOptions = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    slidesPerView: 4,
    spaceBetween: 20,
    fadeEffect: {
      crossFade: true
    },
    grabCursor: true
  };

  constructor() { }

  ngOnInit() {
  }

  redirect(item) {
    this.redirectEmit.emit(item);
  }

  openModalChart(item) {
    this.openModalEmit.emit(item);
  }

  setBg(estado: number) {
    switch (estado) {
      case 0:
        return 'primary';
        break;
      case 1:
        return 'secondary';
        break;
      case 2:
        return 'tertiary';
        break;
      default:
        break;
    }
  }

}
