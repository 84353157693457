import { Justificacion } from 'src/app/models/rrhh/justificacion.model';
import { ParamEntity } from 'src/app/models/general/param.model';
import { Marcacion } from './../../../models/rrhh/marcacion.model';
import { CmbEntity } from './../../../models/general/cmbEntity.model';
import { Observable } from 'rxjs';
import { Injectable, OnInit, Output, EventEmitter } from '@angular/core';
import { NovedadesEndPoint } from 'src/app/services/rrhh/novedades/novedades-endpoint';
import { DateTimeEntity } from 'src/app/models/sistema/dateTimeEntity';
import { Novedades } from 'src/app/models/rrhh/novedades/novedades.model';
import { map } from 'rxjs/operators';
import { JornadaHabitual } from 'src/app/models/rrhh/novedades/jornada-habitual.model';

@Injectable()
export class NovedadesService {
  constructor(private novedadesEndPoint: NovedadesEndPoint) {
  }

  getNovedades(filtro?) {
    return this.novedadesEndPoint.getNovedades(filtro);
  }

  getListJornadasUser(filtro?) {
    return this.novedadesEndPoint.getListJornadasUserEndPoint(filtro);
  }

  getListJornadas(filtro?) {
    return this.novedadesEndPoint.getListJornadasEndPoint(filtro);
  }

  getListIncidencias(filtro?) {
    return this.novedadesEndPoint.getListIncidenciasEndPoint(filtro);
  }

  getListPatologias(params?: ParamEntity<any>) {
    return this.novedadesEndPoint.getListPatologiasEndPoint(params);
  }

  getListMarcaciones(params?: ParamEntity<any>): Observable<Marcacion[]> {
    return this.novedadesEndPoint.getListMarcacionesEndPoint(params);
  }

  getListJornadasHabituales(params?: ParamEntity<any>) {
    return this.novedadesEndPoint.getListJornadasHabitualesEndPoint(params);
  }

  getIncidenciasJustificaciones(params?: ParamEntity<any>) {
    return this.novedadesEndPoint.getIncidenciasJustificacionesEndPoint(params);
  }

  getNominaGrilla(params?: ParamEntity<any>) {
    return this.novedadesEndPoint.getNominaGrillaEndPoint(params);
  }

  getNominaCofarsur(params?: ParamEntity<any>) {
    return this.novedadesEndPoint.getNominaCofarsurEndPoint(params);
  }

  getJustificacionGrilla(params?: ParamEntity<any>) {
    return this.novedadesEndPoint.getJustificacionGrillaEndPoint(params);
  }

  getIncidenciasGrilla(params?: ParamEntity<any>) {
    return this.novedadesEndPoint.getIncidenciasGrillaEndPoint(params);
  }

  getNovedadesAvisosGrilla(params?: ParamEntity<any>) {
    return this.novedadesEndPoint.getNovedadesAvisosGrillaEndPoint(params);
  }

  getVacacionesList(params?: ParamEntity<any>) {
    return this.novedadesEndPoint.getVacacionesListEndPoint(params);
  }

  getDocVacacionesList(params?: ParamEntity<any>) {
    return this.novedadesEndPoint.getDocVacacionesListEndPoint(params);
  }


  getSaldosVacacionesList(params?: ParamEntity<any>) {
    return this.novedadesEndPoint.getSaldosVacacionesListEndPoint(params);
  }

  getLiquidaciones(params) {
    return this.novedadesEndPoint.getLiquidacionesEndPoint(params);
  }

  getDetalleLiquidacion(params) {
    return this.novedadesEndPoint.getDetalleLiquidacionEndPoint(params);
  }

  getLiqHsExtras(params) {
    return this.novedadesEndPoint.getLiqHsExtrasEndPoint(params);
  }

  getDetalleLiqHsExtras(params) {
    return this.novedadesEndPoint.getDetalleLiqHsExtrasEndPoint(params);
  }

  getJornadasProgramadas(params) {
    return this.novedadesEndPoint.getJornadasProgramadasEndPoint(params);
  }

  getJornadaProgramadaDet(params) {
    return this.novedadesEndPoint.getJornadaProgramadaDetEndPoint(params);
  }

  getJornadasProgramadasUpdate(params) {
    return this.novedadesEndPoint.getJornadasProgramadasUpdateEndPoint(params);
  }

  getNovedadesTimeline(params) {
    return this.novedadesEndPoint.getNovedadesTimelineEndpoint(params);
  }

  getIncidenciasAgrupadores(params) {
    return this.novedadesEndPoint.getIncidenciasAgrupadoresEndpoint(params);
  }

  getGestionDoc(params) {
    return this.novedadesEndPoint.getGestionDocEndpoint(params);
  }

  getInstructivos(params) {
    return this.novedadesEndPoint.getInstructivosEndpoint(params);
  }

  getModalMarcacionesData(params) {
    return this.novedadesEndPoint.getModalMarcacionesDataEndPoint(params);
  }

  getMotivosCmb(params) {
    return this.novedadesEndPoint.getMotivosCmbEndPoint(params);
  }

  getConceptosEspecialesTable(params) {
    return this.novedadesEndPoint.getConceptosEspecialesTableEndPoint(params);
  }

  getConceptosEspecialesCmb(params) {
    return this.novedadesEndPoint.getConceptosEspecialesCmbEndPoint(params);
  }

  /*--------POST-----------*/

  guardarJornada(params: ParamEntity<any>) {
    return this.novedadesEndPoint.guardarJornada(params);
  }

  guardarIncidencia(params: ParamEntity<any>) {
    return this.novedadesEndPoint.guardarIncidencia(params);
  }

  anularMarcacion(params: ParamEntity<any>) {
    return this.novedadesEndPoint.anularMarcacionEndPoint(params);
  }

  guardarMarcacion(params: ParamEntity<any>) {
    return this.novedadesEndPoint.guardarMarcacion(params);
  }

  guardarJornadaHabitual(params: ParamEntity<any>) {
    return this.novedadesEndPoint.guardarJornadaHabitualEndPoint(params);
  }

  updJustificacion(params: ParamEntity<Justificacion>) {
    return this.novedadesEndPoint.updJustificacionEndPoint(params);
  }

  guardarAviso(params: ParamEntity<any>) {
    return this.novedadesEndPoint.guardarAvisoEndPoint(params);
  }

  anularAviso(params: ParamEntity<any>) {
    return this.novedadesEndPoint.anularAvisoEndPoint(params);
  }

  updVacacion(params: ParamEntity<any>) {
    return this.novedadesEndPoint.updVacacionEndPoint(params);
  }

  guardarRegistrosHsExtras(params) {
    return this.novedadesEndPoint.guardarRegistrosHsExtrasEndPoint(params);
  }

  postJornadasProgramadasUpdate(params) {
    return this.novedadesEndPoint.postJornadasProgramadasUpdateEndPoint(params);
  }

  guardarConfiguracionJornadaUpd(params) {
    return this.novedadesEndPoint.guardarConfiguracionJornadaUpdEndPoint(params);
  }

  guardarGestionDocumental(params) {
    return this.novedadesEndPoint.guardarGestionDocumentalEndpoint(params);
  }

  guardarAsignacionIncidencia(params) {
    return this.novedadesEndPoint.guardarAsignacionIncidenciaEndpoint(params);
  }

  anularIncidencia(params) {
    return this.novedadesEndPoint.anularIncidenciaEndpoint(params);
  }

  actualizarConcepto(params) {
    return this.novedadesEndPoint.actualizarConceptoEndPoint(params);
  }

}
