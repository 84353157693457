import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvisosAusenciasComponent } from './avisos-ausencias/avisos-ausencias.component';
import { AutogestionRoutingModule } from './autogestion-routing.module';
import { HomeAutogestionComponent } from '../home-autogestion/home-autogestion.component';
import { MenuHomeAutogestionComponent } from './menu-home-autogestion/menu-home-autogestion.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { Angular2UsefulSwiperModule } from 'angular2-useful-swiper';
import { QRCodeModule } from 'angularx-qrcode';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ViewTitleComponent } from '../autogestion/Utils/view-title/view-title.component';
import { ModalUploadComponent } from './modals/modal-upload/modal-upload.component';
import { CloseSessionButtonComponent } from './menu-home-autogestion/close-session-button/close-session-button.component';
import { MaterialModule } from 'src/app/material.module';
import { NgChartsModule } from 'ng2-charts';



@NgModule({
  declarations: [
    HomeAutogestionComponent,
    AvisosAusenciasComponent,
    MenuHomeAutogestionComponent,
    ViewTitleComponent,
    ModalUploadComponent,
    CloseSessionButtonComponent
  ],
  exports: [
    MenuHomeAutogestionComponent
  ],
  imports: [
    CommonModule,
    AutogestionRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatMomentDateModule,
    NgxMaterialTimepickerModule.setOpts('es-AR'),
    NgxSpinnerModule,
    Angular2UsefulSwiperModule,
    QRCodeModule,
    PdfViewerModule,
    NgChartsModule
  ],
  providers: [
  ]
})
export class AutogestionModule { }
