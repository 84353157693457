<div class="contenedor">
  <div class="container-fluid my-3 pb-2">
    <div class="row">
      <div class="col-6 mx-auto text-center text-white">
        <app-alert [titulo]="'Configuracion de Usuarios'"></app-alert>
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        <div class="row">
          <div class="col-12  style-tilte-list">
            Usuarios
          </div>
        </div>
        <div class="row">
          <div class="col-12 list-tollbar">
            <div class="row justify-content-end mr-1">
              <div class="col-10">
                <input type="text" [formControl]="inputFiltroUsuario" placeholder="Ingrese valor a buscar">
              </div>
              <div class="col-2">
                <mat-icon [matTooltip]="'Nuevo Usuario'" (click)="nuevoUsuario()">add</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col style-list">
            <app-list-usuarios [usuariosList]="sourceUsuariosFiltered"
              (usuarioSelectedEmit)="getUsuarioSelected($event)"></app-list-usuarios>
          </div>
        </div>
      </div>


      <div class="col-2">

        <div class="row">
          <div class="col-12 style-tilte-list">
            <p>Datos de usuario</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12 list-tollbar">

          </div>
        </div>

        <div class="row">
          <div class="col style-list">
            <form [formGroup]="formUsuarios" (ngSubmit)="submitForm($event)">
              <mat-form-field appearance="outline">
                <mat-label>Nombre de usuario</mat-label>
                <input matInput type="text" formControlName="nombreUsuario" placeholder="Nombre de usuario">
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Clave de seguridad</mat-label>
                <input matInput type="text" formControlName="claveSeguridad" placeholder="Clave de seguridad">
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput type="text" formControlName="email" placeholder="Email">
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Rol</mat-label>
                <mat-select [compareWith]="compareObjectsRol" formControlName="rol">
                  <mat-option *ngFor="let optionItem of rolOptions" [value]="optionItem.IdRol">
                    {{ optionItem.Nrol }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Estado</mat-label>
                <mat-select [compareWith]="compareObjectsEstado" formControlName="estado">
                  <mat-option *ngFor="let optionItem of estadoOptions" [value]="optionItem.IdEstado">
                    {{ optionItem.Nestado }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <button mat-raised-button type="submit" color="primary" class="btn-guardar">GUARDAR</button>
            </form>

          </div>
        </div>

      </div>



      <div class="col-2">
        <div class="row">
          <div class="col-12 style-tilte-list">
            <p>Plantillas Nomina</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12 list-tollbar">
            <!-- <input type="text" placeholder="Ingrese valor a buscar" [formControl]="inputFiltroPlantillasNomina"> -->
          </div>
        </div>

        <div class="row">
          <div class="col style-list">
            <app-list-plantillas-nomina [plantillasNominaList]="sourcePlantillasNominaFiltered"
              [setPlantillaNomina]="idPlantillaNomina"
              (optionsPlantillaNominaSelectedEmit)="getIdPlantillaNominaSelected($event)">
            </app-list-plantillas-nomina>
          </div>
        </div>

      </div>

      <div class="col-2">
        <div class="row">
          <div class="col-12 style-tilte-list">
            <p>Nómina</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12 list-tollbar">
            <input type="text" placeholder="Ingrese valor a buscar" [formControl]="inputFiltroEmpleados">
          </div>
        </div>

        <div class="row">
          <div class="col style-list-informativo">
            <app-list-nomina [idLegajosUsuariosNomina]="{ids: idLegajosUsuariosNomina, arr: sourceEmpleadosFiltered }">
            </app-list-nomina>
          </div>
        </div>

      </div>

      <div class="col-2">
        <div class="row">
          <div class="col-12 style-tilte-list">
            <p>Plantillas Jornadas</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12 list-tollbar">
            <!-- <input type="text" placeholder="Ingrese valor a buscar" [formControl]="inputFilterPlantillaJornadas"> -->
          </div>
        </div>

        <div class="row">
          <div class="col style-list">
            <app-list-plantillas-jornadas [listPlantillasJornadas]="sourcePlantillasJornadasFiltered"
              [setPlantillaJornada]="idPlantillaJornada"
              (optionsPlantillaJornadaSelectedEmit)="getIdPlantillaJornadaSelected($event)">
            </app-list-plantillas-jornadas>
          </div>
        </div>

      </div>


      <div class="col-2">

        <div class="row">
          <div class="col-12 style-tilte-list">
            <p>Jornadas</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12 list-tollbar">
            <input type="text" placeholder="Ingrese valor a buscar" [formControl]="inputFiltroJornadas">
          </div>
        </div>

        <div class="row">
          <div class="col style-list-informativo">
            <app-list-usr-jornadas [idUsuarioJornadas]="{ids: idUsuarioJornadas, arr: sourceJornadasFiltered }">
            </app-list-usr-jornadas>
          </div>
        </div>

      </div>

    </div>

  </div>

</div>
