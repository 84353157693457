<mat-form-field class="w-100 select-form-field">
  <mat-label>Reportes</mat-label>
  <mat-select [(ngModel)]="selectedReporte"
    (selectionChange)="getSelectedOption($event)">
    <mat-option *ngFor="let reporte of reportesList" [matTooltip]="reporte.NReporte" [value]="reporte">
      {{ reporte.NReporte }}
    </mat-option>
  </mat-select>
</mat-form-field>

