import { DatePipe, formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, BehaviorSubject } from 'rxjs';
import { TipoConfiguracionEnum } from 'src/app/enums/configuracion.enum';
import { HtmlComponent } from 'src/app/models/general/html-component.interface';
import { ParamEntity } from 'src/app/models/general/param.model';
import { Configuracion } from 'src/app/models/rrhh/configuracion.model';
import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { ConfiguracionService } from 'src/app/services/configuracion.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { ModalNominaSectoresComponent } from '../modal-nomina-sectores/modal-nomina-sectores.component';

@Component({
  selector: 'app-modal-equipos',
  templateUrl: './modal-equipos.component.html',
  styleUrls: ['./modal-equipos.component.scss']
})
export class ModalEquiposComponent implements OnInit {
  configuracion: Configuracion;
  form: UntypedFormGroup;
  formTemplateArr: HtmlComponent[];
  title: string;
  filteredOptions: Observable<any>[] = [];
  autocompleteArr: any[] = [];
  tipoConfiguracionEnum = TipoConfiguracionEnum;

  constructor(
    public dialogRef: MatDialogRef<ModalNominaSectoresComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private configuracionService: ConfiguracionService,
    private userStorageService: UserValuesService,
    private _snackBar: SnackBarService,
    public datepipe: DatePipe
  ) {
    this.configuracion = data.configuracion;
    const type = this.configuracion.Tipo === this.tipoConfiguracionEnum.MODIFICAR ? 'Editar' : 'Alta';
    this.title = `${type} - ${this.configuracion.AliasTabla}`;
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({});
    this.createForm(this.configuracion.Tipo == TipoConfiguracionEnum.MODIFICAR);
    this.detectFormControlChanges();
  }

  ngOnDestroy() {
    this.form = new UntypedFormGroup({});
  }

  cerrarModal(result: { status, mensaje }) {
    this.dialogRef.close(result);
  }

  /*----------FORMULARIO------------*/

  private createForm(update: boolean) {
    this.formTemplateArr = this.configuracion.Elements;
    let group = {};
    this.formTemplateArr.forEach(itemTemplate => {
      let value = itemTemplate.SetDefault ? itemTemplate.SetDefault : '';

      if (update) {
        value = this.configuracion.Row[itemTemplate.FormcontrolName];
        if (itemTemplate.Type === 'select') {
          value = value.toString();
        }

        if (itemTemplate.Hidden) {
          const relation = this.formTemplateArr.find(x => x.FormcontrolNameRelation === itemTemplate.FormcontrolName);
          if (relation) {
            this.formTemplateArr.find(x => x.FormcontrolName === relation.FormcontrolNameRelation).Hidden = !this.configuracion.Row[relation.FormcontrolName];
          }
        }
      }
      if (itemTemplate.Type === 'autocomplete') {
        const value = new BehaviorSubject<any>(itemTemplate.Options).asObservable();
        this.filteredOptions.push(value);
        this.autocompleteArr.push(itemTemplate.Options);
      }
      if (itemTemplate.InputType === 'date' && value) {
        const dateArr = value.split('/');
        value = dateArr[2] + '/' + dateArr[1] + '/' + dateArr[0];
        value = formatDate(value, 'yyyy-MM-dd', 'en');
      }
      group[itemTemplate.FormcontrolName] = new UntypedFormControl({ value, disabled: itemTemplate.Disabled });
    })
    this.form = new UntypedFormGroup(group);
  }

  private detectFormControlChanges() {
    // this.form.controls['ID_LEGAJO'].valueChanges.subscribe(x => {
    //   if (x && x.Key) {
    //     x = x.Value;
    //     this.historialFilteredList = this.historialList.filter(value => value.Value.includes(x));
    //   } else {
    //     this.historialFilteredList = [];
    //   }
    // })
  }

  submitForm() {
    const paramEntity = new ParamEntity();
    paramEntity.Json = JSON.stringify(this.formatObjectKeys(this.form.getRawValue(), /^O_/i, this.configuracion.Tipo == TipoConfiguracionEnum.MODIFICAR));
    paramEntity.IdEmpresa = this.userStorageService.getUsuarioValues.IdEmpresa;
    paramEntity.IdUsuario = this.userStorageService.getUsuarioValues.IdUsuario;
    paramEntity.NombreTabla = this.configuracion.NombreTabla;
    paramEntity.Accion = this.configuracion.Tipo;

    this.configuracionService.guardarNuevaConfiguracionService(paramEntity).subscribe((result: ResponseHelper) => {
      let resultModal;
      if (result.Result === '1') {
        resultModal = {
          mensaje: result.Mensaje,
          status: true
        };
        this.cerrarModal(resultModal);
      } else {
        this.showToast(result.Mensaje, 'danger');
      }
    },
      (error) => {
        this.showToast('Hubo un error. Intente nuevamente', 'danger');
      });
  }

  private formatObjectKeys(object, regexp, isUpdate) {
    let group = {};

    const objectKeys = Object.keys(object)
    const objectValues: any[] = Object.values(object);
    objectKeys.forEach((key, index) => {
      key = key.replace(regexp, '');
      let value = (objectValues[index] && objectValues[index].Key) ? objectValues[index].Value : objectValues[index];

      if ((/(\/)|(-)/).test(value)) {
        try {
          value = this.datepipe.transform(value, 'dd-MM-yyyy');
        } catch (error) { }
      }
      group[key] = value ? value.toString() : '';
    });
    if (!isUpdate) {
      group['ID_ESTADO'] = '1';
    }
    return group;
  }

  private showToast(mensaje, tipo = 'success') {
    this._snackBar.openSnackBar(`snack-${tipo}`, mensaje, 3000);
  }

  soloNumeros(e, soloNumero: boolean) {
    if (soloNumero) {
      if (!(e.keyCode === 8
        || e.keyCode === 46
        || (e.keyCode >= 35 && e.keyCode <= 40)
        || (e.keyCode >= 48 && e.keyCode <= 57)
        || (e.keyCode >= 96 && e.keyCode <= 105)
        || (e.keyCode === 86 && e.ctrlKey === true)
      )) {
        e.preventDefault();
        return false
      }
    }
  }

}
