import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ConfiguracionJornada, Plantilla, UsuariosPlantilla } from 'src/app/models/rrhh/configuracion-jornada';

@Component({
  selector: 'app-list-usuarios-plantilla',
  templateUrl: './list-usuarios-plantilla.component.html',
  styleUrls: ['./list-usuarios-plantilla.component.scss']
})
export class ListUsuariosPlantillaComponent implements OnChanges, OnChanges {
  @Input() dataUsuariosPlantilla: UsuariosPlantilla[];
  @Input() plantillaSelected: Plantilla;


  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.dataUsuariosPlantilla.currentValue) {
      this.dataUsuariosPlantilla = [];
    }
  }

  getUsuarioPlantillaSelected(e) {
    this.plantillaSelected = e;
  }

  limpiarList() {
    this.dataUsuariosPlantilla = [];
  }





}
