import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-gestion-codigo',
  templateUrl: './gestion-codigo.component.html',
  styleUrls: ['./gestion-codigo.component.scss']
})
export class GestionCodigoComponent implements OnInit {
  @Input() showLoader = false;
  code: any;
  @Output() codeEmitter = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  continue() {
    this.showLoader = true;
    this.codeEmitter.emit(this.code);
    setTimeout(() => {
      this.showLoader = false;
    }, 2500);
  }

}
