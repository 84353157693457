import { ConceptosEspecialesPagadosComponent } from './components/conceptos-especiales-pagados/conceptos-especiales-pagados.component';
import { InstructivosComponent } from './components/instructivos/instructivos.component';
import { MonitorProcesosComponent } from './components/sistema/monitor-procesos/monitor-procesos.component';
import { NovedadesTimelineComponent } from './components/novedades-timeline/novedades-timeline.component';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AuthGuard } from './services/utils/auth.guard';
import { LoginComponent } from './components/login/login.component';
import { ParteDiarioComponent } from './components/novedades/parte-diario/parte-diario.component';
import { JornadasHabitualesComponent } from './components/novedades/jornadas-habituales/jornadas-habituales.component';
import { JustificacionesComponent } from './components/novedades/justificaciones/justificaciones.component';
import { AvisosComponent } from './components/novedades/avisos/avisos.component';
import { VacacionesComponent } from './components/novedades/vacaciones/vacaciones.component';
import { DetalleTableroComponent } from './components/detalle-tablero/detalle-tablero.component';
import { ReportingGeneralComponent } from './components/reporting-general/reporting-general.component';
import { pageTestComponent } from './components/utils/page-test/page-test.component';
import { ConfiguracionesComponent } from './components/configuraciones/configuraciones.component';
import { DetalleConfiguracionComponent } from './components/detalle-configuracion/detalle-configuracion.component';
import { NovedadesLiquidacionComponent } from './components/liquidaciones/novedades-liquidacion/novedades-liquidacion.component';
import { HsExtrasComponent } from './components/hs-extras/hs-extras.component';
import { JornadasProgramadasComponent } from './components/jornadas-programadas/jornadas-programadas.component';
import { CalendarizadosComponent } from './components/calendarizados/calendarizados.component';
import { ConfiguracionJornadasComponent } from './components/configuracion-jornadas/configuracion-jornadas.component';
import { ConfiguracionUsuariosComponent } from './components/configuracion-usuarios/configuracion-usuarios.component';
import { ConfiguracionPlantillasNominaComponent } from './components/configuracion-plantillas-nomina/configuracion-plantillas-nomina.component';
import { ConfiguracionPlantillasJornadasComponent } from './components/configuracion-plantillas-jornadas/configuracion-plantillas-jornadas.component';
import { ConfiguracionNominaComponent } from './components/configuracion-nomina/configuracion-nomina.component';
import { GestionDocumentalComponent } from './components/gestion-documental/gestion-documental.component';
import { ReportingIndividualComponent } from './components/reporting-individual/reporting-individual.component';
import { HomeAutogestionComponent } from './components/home-autogestion/home-autogestion.component';
import { AvisosAusenciasComponent } from './components/autogestion/avisos-ausencias/avisos-ausencias.component';
import { UserHubMonitorComponent } from './components/user-hub-monitor/user-hub-monitor.component';
import { ReportingInformesComponent } from './components/reporting-informes/reporting-informes.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard], pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard], data: { title: 'Login' } },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard], data: { title: 'Home' } },
  { path: 'parte-diario', component: ParteDiarioComponent, canActivate: [AuthGuard], data: { title: 'Parte Diario' } },
  { path: 'jornadas-habituales', component: JornadasHabitualesComponent, canActivate: [AuthGuard], data: { title: 'Jornadas Habituales' } },
  { path: 'justificaciones', component: JustificacionesComponent, canActivate: [AuthGuard], data: { title: 'Justificaciones' } },
  { path: 'avisos', component: AvisosComponent, canActivate: [AuthGuard], data: { title: 'Avisos' } },
  { path: 'vacaciones', component: VacacionesComponent, canActivate: [AuthGuard], data: { title: 'Vacaciones' } },
  { path: 'detalle-tablero', component: DetalleTableroComponent, canActivate: [AuthGuard], data: { title: 'Detalle tablero' } },
  { path: 'reporting-general', component: ReportingGeneralComponent, canActivate: [AuthGuard], data: { title: 'Reporting generales' } },
  { path: 'configuraciones', component: ConfiguracionesComponent, canActivate: [AuthGuard], data: { title: 'Configuraciones' } },
  { path: 'detalle-configuracion', component: DetalleConfiguracionComponent, canActivate: [AuthGuard], data: { title: 'Detalle Configuración' } },
  { path: 'novedades-liquidacion', component: NovedadesLiquidacionComponent, canActivate: [AuthGuard], data: { title: 'Novedades de liquidación' } },
  { path: 'hs-extras', component: HsExtrasComponent, canActivate: [AuthGuard], data: { title: 'Hs Extras (Autorizaciones)' } },
  { path: 'jornadas-programadas', component: JornadasProgramadasComponent, canActivate: [AuthGuard], data: { title: 'Jornadas Programadas' } },
  { path: 'calendarizados', component: CalendarizadosComponent, canActivate: [AuthGuard], data: { title: 'Calendarizados' } },
  { path: 'configuracion-jornadas', component: ConfiguracionJornadasComponent, canActivate: [AuthGuard], data: { title: 'Configuracion Jornadas' } },
  { path: 'configuracion-usuarios', component: ConfiguracionUsuariosComponent, canActivate: [AuthGuard], data: { title: 'Configuracion Usuarios' } },
  { path: 'configuracion-plantillas-nomina', component: ConfiguracionPlantillasNominaComponent, canActivate: [AuthGuard], data: { title: 'Configuracion Plantillas Nómina' } },
  { path: 'configuracion-plantillas-jornadas', component: ConfiguracionPlantillasJornadasComponent, canActivate: [AuthGuard], data: { title: 'Configuracion Plantillas Jornadas' } },
  { path: 'visor-novedades', component: NovedadesTimelineComponent, canActivate: [AuthGuard], data: { title: 'Visor de novedades (Timeline)' } },
  { path: 'sistema-monitor-procesos', component: MonitorProcesosComponent, canActivate: [AuthGuard], data: { title: 'Monitor de procesos' } },
  { path: 'configuracion-nomina', component: ConfiguracionNominaComponent, canActivate: [AuthGuard], data: { title: 'Administración de Nómina' } },
  { path: 'gestion-documental/:id', component: GestionDocumentalComponent, canActivate: [AuthGuard], data: { title: 'Autenticador' } },
  { path: 'instructivos', component: InstructivosComponent, canActivate: [AuthGuard], data: { title: 'Instructivos' } },
  { path: 'reporting-individual', component: ReportingIndividualComponent, canActivate: [AuthGuard], data: { title: 'Administración de Nómina' } },
  { path: 'conceptos-especiales', component: ConceptosEspecialesPagadosComponent, canActivate: [AuthGuard], data: { title: 'Conceptos Especiales Pagados' } },
  { path: 'monitor-usuarios', component: UserHubMonitorComponent, canActivate: [AuthGuard], data: { title: 'Monitor de usuarios' } },
  { path: 'reporting-informes', component: ReportingInformesComponent, canActivate: [AuthGuard], data: { title: 'Informes' } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
