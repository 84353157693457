<h1 mat-dialog-title class="bg-primary titulo-modal">{{ titulo }}
  <mat-icon class="btn-close" (click)="cerrarModal()">clear</mat-icon>
</h1>
<div mat-dialog-content>
  <div class="container-fluid">
    <div class="row">
      <div class="col-3">
        <div class="table-container">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-parte-diario">
            <ng-container *ngFor="let col of columns" [matColumnDef]="col">
              <th class="border bg-secondary  text-white" mat-header-cell *matHeaderCellDef> {{ col }} </th>
              <td class="border" mat-cell *matCellDef="let element" matTooltip="{{ element[col] }}"> {{ element[col] }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns;" (click)="openModalData(row)"></tr>
          </table>
        </div>

      </div>

      <div class="col-9">
        <canvas id="chart">{{ chart }}</canvas>
      </div>
    </div>
  </div>

</div>
