<div class="row mt-3">
  <div class="col-12">
    <mat-card class="gestion-card">
      <div class="container p-0">
        <mat-toolbar class="toolbar justify-content-center">
          <span>Documento</span>
        </mat-toolbar>
        <div class="row padding-section">
          <div class="col">
            <p><strong>Id Documento:</strong> {{ gestionDocumental.IdDocumento }}</p>
            <p><strong>Tipo Documento:</strong> {{ gestionDocumental.TipoDocumento }}</p>
            <p><strong>Estado Documento: </strong><span [ngClass]="{'estadoGenerado': gestionDocumental.EstadoDocumento === 'Generado', 'estadoRecibido': gestionDocumental.EstadoDocumento === 'Recibido', 'estadoDevuelto': gestionDocumental.EstadoDocumento === 'Devuelto' }">&nbsp;{{gestionDocumental.EstadoDocumento}}&nbsp;</span></p>
            <p><strong>Fecha Registro:</strong> {{ gestionDocumental.FechaRegistroDoc }}</p>
            <p><strong>Fecha Modificación:</strong> {{ gestionDocumental.FechaModificacionDoc }}</p>
            <p><strong>Usuario:</strong> {{ gestionDocumental.UsuarioGestor }}</p>
          </div>
        </div>
        <mat-divider></mat-divider>
        <mat-toolbar class="toolbar justify-content-center">
          <span>Detalle</span>
        </mat-toolbar>
        <div class="row padding-section">
          <div class="col">
            <div [innerHTML]="gestionDocumental.Detalle"></div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <mat-toolbar class="toolbar justify-content-center">
          <span>Gestión</span>
        </mat-toolbar>
        <div class="row padding-section mt-2">
          <div class="col-sm-12 col-md-3 mx-auto text-center">
            <mat-form-field appearance="outline" class="observacion-mat-form-field">
              <mat-label>Observaciones</mat-label>
              <input matInput type="text" placeholder="Observaciones" [formControl]="observacionsInput" [max]="200">
            </mat-form-field>
          </div>
          <div class="col-sm-12 col-md-3 mx-auto text-center">
            <mat-form-field appearance="outline" class="select-accion">
              <mat-label>Acción</mat-label>
              <mat-select [(value)]="accionSelected" (selectionChange)="getAccionSelected($event)">
                <mat-option *ngFor="let item of gestionDocumental.ListAcciones" [matTooltip]="item.nAccion"
                  [value]="item.idAccion">
                  {{ item.nAccion }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <mat-card-actions align="end" class="p-4">
        <button mat-stroked-button class="mt-1" color="primary" (click)="guardar()">Guardar</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
