import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'groupByField' })
export class GroupByPipe implements PipeTransform {
    transform(collection: Array<any>, property: string) {
      if (collection && collection.length) {
        const groupedObj: [] = collection.reduce((prev, cur) => {
          if (!prev[cur[property]]) {
            prev[cur[property]] = [cur];
          } else {
            prev[cur[property]].push(cur);
          }
          return prev;
        }, {});

        const newGroupList = Object.keys(groupedObj).map(key => ({ key, value: groupedObj[key] }));
        return newGroupList;
      } else {
        return [];
      }
    }
}
