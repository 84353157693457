<div [ngClass]="{'bg-opacity-modal': menuMarcacionOpened}"></div>
<h1 mat-dialog-title class="bg-primary titulo-modal">{{ titulo }}
  <mat-icon class="btn-close" (click)="cerrarModal()">clear</mat-icon>
</h1>
<div mat-dialog-content>

  <p class="p-nombre">
    <span>Leg. <b>{{ objeto.IdLegajo }}</b> {{' - ' + objeto.Nombre + ' ' + objeto.Apellido }}</span>
  </p>

  <mat-card class="mb-3">
    <div class="row text-center labels-container">
      <div class="col-3">
        <mat-label>Sección</mat-label>
        <p>{{ objeto.Seccion }}</p>
      </div>
      <div class="col-3">
        <mat-label>Fecha</mat-label>
        <p>{{ objeto.FechaDate | date:'dd/MM/yyyy' }}</p>
      </div>
      <div class="col-3">
        <mat-label>Jornada</mat-label>
        <p>{{ objeto.Njornada }}</p>
      </div>
      <div class="col-3">
        <mat-label>Movimientos</mat-label>
        <p>{{ calcularMovimientos() }}</p>
      </div>
    </div>
  </mat-card>

  <mat-tab-group mat-stretch-tabs class="mb-2 mat-elevation-z4">
    <mat-tab label="Jornadas">
      <div class="container">
        <div class="row">
          <div class="col-6 p-3 mx-auto text-center">
            <mat-form-field class="w-100">
              <mat-label>Seleccione jornada</mat-label>
              <input type="text" placeholder="" aria-label="Seleccione Jornada" matInput [formControl]="myControl"
                [matAutocomplete]="auto">
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                (optionSelected)="getSeleccionJornada($event)" [displayWith]="displayFn">
                <!-- <mat-select [(ngModel)]="objeto.IdJornada" (selectionChange)="getSeleccionJornada($event)"> -->
                <mat-option *ngFor="let item of filteredOptions | async" [matTooltip]="item.Detalle" [value]="item">
                  {{ item.Detalle }}
                </mat-option>
                <!-- </mat-select> -->
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-12 p-1 text-right">
            <button mat-stroked-button [disabled]="isBtnGuardarJornada" (click)="openModalData('jornada')"
              color="primary">Guardar</button>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Marcaciones">
      <div class="container p-4 h-100">
        <div class="row mb-4 justify-content-center">
          <div class="col-6 d-flex justify-content-center align-items-center">
            <div class="mr-5">
              <button [disabled]="!setHorarioHabilitado" (click)="toggleHorarioHabilitado()" mat-mini-fab color="accent"
                aria-label="Add" class="mr-3">
                <mat-icon>add</mat-icon>
              </button>
              <button [disabled]="isBtnAnularMarcacion" [matMenuTriggerFor]="menu"
                (menuOpened)="toggleMarcacionMenuOpened()" (menuClosed)="toggleMarcacionMenuOpened()" mat-mini-fab
                color="primary" aria-label="Remove">
                <mat-icon>remove</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="p-3 text-center menu-marcacion">
                <p>¿Anular marcación?</p>
                <button cdkFocusInitial mat-stroked-button color="primary" class="m-2"
                  (click)="anularMarcacion()">Si</button>
                <button mat-stroked-button class="m-2">No</button>
              </mat-menu>
            </div>
            <app-timepicker [isDisabled]="setHorarioHabilitado" [setDarkMode]="true"
              (timePickerEmit)="getTimePickerValue($event)" [tiempoActual]="tiempoActualReset"></app-timepicker>
          </div>
          <div class="col-2 align-items-center d-flex">
            <button mat-stroked-button color="primary" (click)="openModalData('marcacion')"
              [disabled]="isBtnGuardarMarcacion">Guardar</button>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <table mat-table [dataSource]="dataSourceMarcacion" class="mat-elevation-z8 table-parte-diario">
              <ng-container matColumnDef="Hora">
                <th mat-header-cell *matHeaderCellDef> Hora </th>
                <td mat-cell *matCellDef="let element"> {{element.Hora}} </td>
              </ng-container>

              <ng-container matColumnDef="Tipo Marcación">
                <th mat-header-cell *matHeaderCellDef> Tipo Marcación </th>
                <td mat-cell *matCellDef="let element"> {{element.NmarcacionTipo}} </td>
              </ng-container>

              <ng-container matColumnDef="Fuente Marcación">
                <th mat-header-cell *matHeaderCellDef> Fuente Marcación </th>
                <td mat-cell *matCellDef="let element"> {{element.NmarcacionFuente}} </td>
              </ng-container>

              <ng-container matColumnDef="Equipo">
                <th mat-header-cell *matHeaderCellDef> Equipo </th>
                <td mat-cell *matCellDef="let element"> {{element.Nequipo}} </td>
              </ng-container>

              <ng-container matColumnDef="Estado">
                <th mat-header-cell *matHeaderCellDef> Estado </th>
                <td mat-cell *matCellDef="let element"> {{element.Nestado}} </td>
              </ng-container>

              <ng-container matColumnDef="Incidencia">
                <th mat-header-cell *matHeaderCellDef> Incidencia </th>
                <td mat-cell *matCellDef="let element"> {{element.Nincidencia}} </td>
              </ng-container>

              <ng-container matColumnDef="Foto">
                <th mat-header-cell *matHeaderCellDef> Foto </th>
                <td mat-cell *matCellDef="let element"> {{element.Foto}} </td>
              </ng-container>

              <ng-container matColumnDef="Observaciones">
                <th mat-header-cell *matHeaderCellDef> Observaciones </th>
                <td mat-cell *matCellDef="let element"> {{element.Observaciones}} </td>
              </ng-container>

              <ng-container matColumnDef="Accion">
                <th mat-header-cell *matHeaderCellDef> Acción </th>
                <td mat-cell *matCellDef="let element">
                  <mat-icon [matTooltip]="'Asignar incidencia'" (click)="openModalIncidencias(element)"
                    class="ico-asignar-incidencia">assignment_add</mat-icon>
                  <mat-icon matTooltip="Anular" class="icon-action-anular ml-2" *ngIf="element.IdIncidencia"
                    (click)="openModalAnularIncidencia(element)">close
                  </mat-icon>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="marcacionColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: marcacionColumns;"
                [ngClass]="{'css_anulado': row.IdEstado === 0, hovered: row.hovered, sombreado: row.Seleccionado}"
                (click)="getRowData(row)" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
            </table>

            <mat-paginator #marcacionPaginator [pageSizeOptions]="[5, 10]"></mat-paginator>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Incidencias">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-6 p-3 mr-auto text-center">
            <mat-form-field class="w-100">
              <mat-label>Seleccione incidencia</mat-label>
              <mat-select [(ngModel)]="objeto.IdIncidencia" (selectionChange)="getSeleccionIncidencia($event)">
                <mat-option *ngFor="let item of lstIncidencias" [matTooltip]="item.Detalle" [value]="item.Id">
                  {{ item.Detalle }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col p-3 mx-auto text-center" *ngIf="cmbPatologiaHabilitado">
            <mat-form-field class="w-100">
              <mat-label>Seleccione patología</mat-label>
              <mat-select [(ngModel)]="objeto.IdPatologia" (selectionChange)="getSeleccionPatologia($event)">
                <mat-option *ngFor="let item of lstPatologias" [matTooltip]="item.Detalle" [value]="item.Id">
                  {{ item.Detalle }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 px-3 mx-auto text-center">
            <mat-form-field class="w-100">
              <mat-label>Observaciones</mat-label>
              <textarea matInput [(ngModel)]="objeto.Observaciones"></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <table mat-table [dataSource]="dataSourceIncidencia" class="mat-elevation-z8 table-parte-diario">
              <ng-container matColumnDef="Incidencia">
                <th mat-header-cell *matHeaderCellDef> Incidencia </th>
                <td mat-cell *matCellDef="let element"> {{element.Nincidencia}} </td>
              </ng-container>

              <ng-container matColumnDef="Estado">
                <th mat-header-cell *matHeaderCellDef> Estado </th>
                <td mat-cell *matCellDef="let element"> {{element.Nestado}} </td>
              </ng-container>

              <ng-container matColumnDef="Observaciones">
                <th mat-header-cell *matHeaderCellDef> Observaciones </th>
                <td mat-cell *matCellDef="let element"> {{element.Observaciones}} </td>
              </ng-container>

              <ng-container matColumnDef="Patologia">
                <th mat-header-cell *matHeaderCellDef> Patologia </th>
                <td mat-cell *matCellDef="let element"> {{element.Npatologia}} </td>
              </ng-container>

              <ng-container matColumnDef="Fecha Modificacion">
                <th mat-header-cell *matHeaderCellDef> Fecha Modificacion </th>
                <td mat-cell *matCellDef="let element"> {{element.FechaModificacion | date: 'dd/MM/yyyy HH:mm:ss'}}
                </td>
              </ng-container>

              <ng-container matColumnDef="nUsuario">
                <th mat-header-cell *matHeaderCellDef> Usuario </th>
                <td mat-cell *matCellDef="let element"> {{element.Nusuario}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="incidenciaColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: incidenciaColumns;"
                [ngClass]="{'css_anulado': row.IdEstado === 0, hovered: row.hovered, sombreado: row.Seleccionado}"
                (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
            </table>

            <mat-paginator #incidenciasPaginator [pageSizeOptions]="[5, 10]"></mat-paginator>
          </div>
        </div>
        <div class="row">
          <div class="col-12 p-1 text-right">
            <button mat-stroked-button color="primary" (click)="openModalData('incidencia')"
              [disabled]="isBtnGuardarIncidencia">Guardar</button>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

</div>