<div class="container-fluid mt-3">
  <div class="row">
    <div class="col-6 mr-0 pr-0">
      <mat-card class="card-list">
        <mat-card-title>
          Instructivos
        </mat-card-title>

        <mat-card-content class="p-2">
          <mat-accordion class="instructivo-accordion example-headers-align">
            <mat-expansion-panel *ngFor="let fileGroup of files" class="my-2">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ fileGroup.NCategoria }}
                </mat-panel-title>
                <mat-panel-description>
                  <p class="p-panel-description">{{ fileGroup.FilesList.length }} documento(s)</p>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-list role="list" class="list-instructivo">
                <mat-list-item *ngFor="let file of fileGroup.FilesList; let i = index"
                  [ngClass]="{'activo': file === fileSelected}">
                  <mat-icon mat-list-icon>note</mat-icon>
                  <mat-radio-button [value]="file" [checked]="file === fileSelected" (change)="displayFile($event)">
                    {{file.Ninstructivo}}

                  </mat-radio-button>
                </mat-list-item>
              </mat-list>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-card-content>


      </mat-card>
    </div>
    <div class="col-6 ml-0 pl-0">
      <mat-card class="card-multimedia">
        <mat-card-title>
          {{ fileSelected.Ninstructivo ? fileSelected.Ninstructivo : 'Seleccione un instructivo' }}
        </mat-card-title>
        <mat-card-subtitle>
          <mat-icon mat-list-icon>info</mat-icon>
          {{ fileSelected.Descripcion }}
        </mat-card-subtitle>
        <mat-divider></mat-divider>
        <div [ngSwitch]="fileSelected.Filetype">
          <div *ngSwitchCase="fileTypesEnum.VIDEO">
            <div class="video-wrapper">
              <video id="video" autoplay controls>
                <source [src]="fileSelected.Url" type="video/mp4" />
              </video>
            </div>
          </div>
          <div *ngSwitchCase="fileTypesEnum.PDF">
            <div class="pdf-wrapper">
              <pdf-viewer [src]="fileSelected.Url" [render-text]="true" [original-size]="false">
              </pdf-viewer>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>