import { AfterContentInit, ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { datePeriodConverter } from 'src/app/functions/date-period';
import { Filters } from 'src/app/models/general/filters.model';
import { ParamEntity } from 'src/app/models/general/param.model';
import { DynamicTable, EstructureTable } from 'src/app/models/rrhh/dynamic-table.model';
import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { ReporteService } from 'src/app/services/reporte.service';
import { NovedadesService } from 'src/app/services/rrhh/novedades/novedades.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { default as _rollupMoment, Moment } from 'moment';
import * as _moment from 'moment';
import { SenderService } from 'src/app/services/utils/sender.service';
import { DatePipe, formatDate } from '@angular/common';
import { ModalDetalleLiquidacionComponent } from '../../modals/modal-detalle-liquidacion/modal-detalle-liquidacion.component';
import { startWith, take } from 'rxjs/operators';
import { excelLiquidacionesRequest } from 'src/app/models/general/excelLiquidacionesRequest';
import { FileService } from 'src/app/services/file.service';
import { MatTableDataSource, MatRow, MatTable } from '@angular/material/table';
import { MatCheckbox } from '@angular/material/checkbox';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-novedades-liquidacion',
  templateUrl: './novedades-liquidacion.component.html',
  styleUrls: ['./novedades-liquidacion.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
  ],
})

export class NovedadesLiquidacionComponent implements OnInit, AfterContentInit {
  dataSource = new MatTableDataSource<any>([]);
  columnsDef: string[];
  groupColDef: any;
  groupColHeaders: string[];
  dynamicData: DynamicTable;
  selected = -1;
  row: any;
  paramEntity = new ParamEntity<any>();
  @ViewChild('detallePaginator', { static: true }) paginator: MatPaginator;
  stickyColumns = [
    'SECCION',
    'ID_LEGAJO',
    'ID_LEGAJO_LIQUIDACION',
    'APELLIDO',
    'NOMBRE',
    'accion'
  ];
  stickyAgrupadoresHeaders = [
    'DATOS-PERSONALES'
  ];
  contentLoaded = false;
  @ViewChildren(MatRow) rows: QueryList<any>;

  @ViewChild('matTable') set table(matTable: MatTable<any>) {
    if (matTable) {
      this.ngZone.onMicrotaskEmpty
        .pipe(take(3))
        .subscribe(() => matTable.updateStickyColumnStyles())
    }
  }

  constructor(
    private novedadesService: NovedadesService,
    private userValuesService: UserValuesService,
    private _snackBar: SnackBarService,
    private reporteService: ReporteService,
    private senderService: SenderService,
    private elementRef: ElementRef,
    private dialog: MatDialog,
    private changeDetectorRefs: ChangeDetectorRef,
    private ngZone: NgZone,
    private datePipe: DatePipe,
    private fileService: FileService,
  ) {
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngAfterContentInit() {

  }

  private getTableData() {
    const paramEntity = new ParamEntity();
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    paramEntity.IdSeccion = this.paramEntity.IdSeccion;
    paramEntity.Periodo = this.paramEntity.Periodo;

    this.novedadesService.getLiquidaciones(paramEntity).subscribe((result: ResponseHelper) => {
      if (result.Ok) {
        if (result.Json) {
          this.clearData();
          this.dynamicData = JSON.parse(result.Json);
          this.dataSource.data = this.dynamicData.data;
          this.createTableEstructure();
          // this.setCssProperties();
          // this.hideSpinner();
          this.changeDetectorRefs.detectChanges();
        }
      }
    },
      (error) => { this._snackBar.openSnackBar('snack-danger', 'Hubo un error', 3000); },
      () => {
        this.hideSpinner();
      });

  }

  private clearData() {
    this.columnsDef = [];
    this.groupColHeaders = [];
    this.dynamicData = null as DynamicTable;
    this.dataSource = new MatTableDataSource<any>();
    this.groupColDef = null;
  }

  private createTableEstructure() {
    this.columnsDef = this.dynamicData.estructure.reduce((a, b) => a.concat(b.columns), []);
    this.columnsDef.unshift('accion');
    this.groupColDef = this.dynamicData.estructure.reduce((a, b) => a.concat(b), []);
    this.groupColHeaders = this.dynamicData.estructure.reduce<string[]>((a, b) => a.concat(b.colDef), []);
    this.groupColHeaders.unshift('accion');
  }

  checkboxSelect(e: MatCheckbox, element) {
    this.row = (e.checked) ? element : null;
  }

  filter(event: Filters, fromMultipleFilters = true) {
    this.paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    this.paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    if (fromMultipleFilters) {
      this.paramEntity.Periodo = event.periodo;
      this.paramEntity.IdSeccion = event.idSector;
      this.paramEntity.IdLegajo = event.legajo;
    } else {
      this.paramEntity.SpName = event.reporte.SpName;
    }
    this.getTableData();
  }

  printFile(soloDescarga: boolean, url?, fileName?) {
    // DESCOMENTAR CUANDO HAYA QUE IMPLEMENTAR
    let params = new excelLiquidacionesRequest();
    params.idEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    params.idUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    params.periodo = this.paramEntity.Periodo;  //datePeriodConverter(this.tableroValues.TableroFilters.Periodo, true);
    params.idSeccion = this.paramEntity.IdSeccion;
    params.fileName = "Liquidaciones_Consolidadas";

    //this.loadingFile = true; // inhabilito todos los controles del html
    // SI ES TRUE, USO TIMEOUT PARA QUE NO SE PISE CON EL MENSAJE ANTERIOR
    setTimeout(() => {
      this._snackBar.openSnackBar('snack-warning', 'Generando archivo. Por favor espere.');
    }, 1000);

    this.fileService.downloadXlsxLiquidacionesConsolidadas(params).subscribe((resultDownload) => {
      if (resultDownload) {
        if (!soloDescarga) {
          // SI SE DESCARGA CORRECTAMENTE LIMPIO TODO Y ACTUALIZO LOS DATOS
          //console.log("Descarga Ok");
        }
        // USO EL TIMEOUT DE NUEVO PARA ELIMINAR EL MENSAJE ANTERIOR
        setTimeout(() => {
          this._snackBar.dismissSnackbar();
        }, 3000);

      } else {
        this._snackBar.openSnackBar('snack-danger', 'Hubo un error al intentar descargar el archivo', 3000);
      }
      //this.loadingFile = false; // VUELVO A HABILITAR LOS CONTROLER
    }, (errorDescarga) => {
      this._snackBar.openSnackBar('snack-danger', errorDescarga, 5000);
      //this.loadingFile = false; // VUELVO A HABILITAR LOS CONTROLER
    });



  }

  applyFilter(event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  isSticky(col, element) {
    if (element) {
      // APLICO ESTILO PARA COL FECHA_INGRESO_CALC
      if (col === 'FECHA_INGRESO_CALC' && element.FECHA_INGRESO_CALC) {
        try {
          const splitDate = element.FECHA_INGRESO_CALC.split('/');
          const newDate = new Date(splitDate[2], splitDate[1] - 1, splitDate[0]);
          const periodDate = datePeriodConverter(newDate, true);
          if (periodDate === this.paramEntity.Periodo) {
            return 'bg-celda';
          }
        } catch (error) { }
      }
      // NO APLICA ESTILO PARA CELDA FECHA_BAJA
      if (element.FECHA_BAJA) {
        return false;
      }
      // SE APLICA CLASE A LAS COLUMNAS FIJAS
      if (this.stickyColumns.includes(col)) {
        return 'bg-fixed-col';
      } else {
        if (col === 'IMPARES' && Number(element.IMPARES) > 0) { // ESTILO PARA LA COL IMPARES CON CONDICION
          return 'bg-celda';
        }
        if (col === 'SIN_INCIDENCIA' && Number(element.SIN_INCIDENCIA) > 0) { // ESTILO PARA LA COL SIN_INCIDENCIA CON CONDICION
          return 'bg-celda';
        }
        if (col === 'JORNADA_SUPERPUESTA' && Number(element.JORNADA_SUPERPUESTA) > 0) { // ESTILO PARA LA COL IMPARES CON CONDICION
          return 'bg-celda';
        }
        if (col === 'MARCACIONES_FUERA_JORNADA' && Number(element.MARCACIONES_FUERA_JORNADA) > 0) { // ESTILO PARA LA COL IMPARES CON CONDICION
          return 'bg-celda';
        }

        if (col === 'DESCANSO_INCUMPLIDO' && Number(element.DESCANSO_INCUMPLIDO) > 0) { // ESTILO PARA LA COL
          return 'bg-celda';
        }

        if (col === 'FERIADOS_TRABAJADOS' && Number(element.FERIADOS_TRABAJADOS) > 0) { // ESTILO PARA LA COL
          return 'bg-celda';
        }
        return '';
      }
    }
    return this.stickyColumns.includes(col);
  }

  private setCssProperties() {
    // const arr = this.stickyAgrupadoresHeaders.concat(this.stickyColumns)
    setTimeout(() => {
      let arr = [];
      arr = this.stickyAgrupadoresHeaders.map(x => { return { css: '', el: x } });
      arr = arr.concat(this.stickyColumns.map(x => { return { css: 'subcolumnSticky', el: x } }))

      for (let index = 0; index < arr.length; index++) {
        const arrElement = arr[index];
        const elementName = `mat-column-${arrElement.el}`;
        const el = document.getElementsByClassName(elementName)[0];
        if (el) {
          el.className += ` stickyColumnHeader ${arrElement.css}`;
        }
      }

    }, 500);

  }

  private addClass(elementName) {
    const el = document.getElementsByClassName(elementName)[0];
    if (el) {
      el.className += ' stickyColumnHeader';
    }
  }

  getRowClassCondition(row) {
    if (row.FECHA_BAJA) {
      return 'bg-row-date';
    }
  }

  openModal(element) {
    this.row = element;
    this.paramEntity.GenericEntity = this.row;
    const matConfig: MatDialogConfig = {
      width: '100%',
      maxWidth: '90vw',
      minHeight: '90vh',
      height: 'auto',
      panelClass: ['modal-marcacion', 'modal-insert-config'],
      autoFocus: false,
      data: this.paramEntity
    };
    const dialogRef = this.dialog.open(ModalDetalleLiquidacionComponent, matConfig);
    dialogRef.beforeClosed().subscribe((result) => {
      if (result) {
        this.getTableData();
      }
    });
  }

  hideSpinner() {
    this.rows.changes.pipe(startWith([undefined])).subscribe(() => {
      if (this.allRowsRendered()) {
        setTimeout(() => {
          this.contentLoaded = true;
        }, 2000);
      }
    });
  }

  allRowsRendered(): boolean {
    return this.rows && this.rows.length > 0;
  }

  getCellData(value, col) {
    const dateArrValues = [
      'FECHA_INGRESO_CALC',
      'FECHA_BAJA'
    ]
    if (dateArrValues.includes(col)) {
      return this.datePipe.transform(value, 'dd/MM/yyyy');
    }
    return value;
  }
}
