<div class="container-fluid mt-3">
  <div class="spinner-container" *ngIf="!contentLoaded">
    <mat-spinner></mat-spinner>
  </div>
  <div class="row">
    <div class="col-6 mx-auto text-center text-white">
      <app-alert [titulo]="'Hs Extras (Autorizaciones)'"></app-alert>
    </div>
  </div>
  <div class="row">
    <div class="col-6 ml-auto">
      <app-filter-card (filterEmit)="filter($event)" [hasLegajoInput]="false" [hasExportExcelButton]="true"
        (printExcelEmit)="printFile(true)" [exportExcelButtonDisabled]="false"
        [allowOneMoreDay]="true"></app-filter-card>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-2">
      <mat-form-field class="w-90">
        <mat-label>Filtrar</mat-label>
        <input matInput (keyup)="applyFilter($event)">
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="table-container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-parte-diario">
          <ng-container *ngFor="let col of displayedColumns" [matColumnDef]="col" [sticky]="isSticky(col, null)">

            <div *ngIf="col !== 'accion';else btnAction">
              <th class="border bg-secondary  text-white" mat-header-cell *matHeaderCellDef> {{ col }} </th>
              <td class="border" mat-cell *matCellDef="let element" matTooltip="{{ element[col] }}" [ngClass]="isSticky(col, element)"> {{ element[col] }}
              </td>
            </div>

            <ng-template #btnAction>
              <th mat-header-cell *matHeaderCellDef class="border text-white"> </th>
              <td mat-cell *matCellDef="let element; let i = index" class="text-center">
                <button mat-mini-fab class="btn-open-detalle mx-3" color="primary" matTooltip="Ver detalle"
                  (click)="openModalData(element)">
                  <mat-icon>description</mat-icon>
                </button>
              </td>
            </ng-template>
          </ng-container>



          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

      </div>


      <mat-paginator #detallePaginator [pageSizeOptions]="[1000, 500]"></mat-paginator>
    </div>
  </div>
</div>
