<div class="spinner-container" *ngIf="!contentLoaded">
  <mat-spinner></mat-spinner>
</div>

<div class="row">
  <div class="col-6 mx-auto text-center text-white">
    <app-alert [titulo]="'Monitor de procesos'"></app-alert>
  </div>
</div>


<div class="row ">
  <div class="col-12 p-3 mb-2 border text-right bg-light">
      <button mat-button class="primary" (click)="getData()">Actualizar</button>
  </div>
</div>


<div class="row">
  <div class="col-12">
    <div class="container">
      <div class="row">
        <div class="col-12 bg-info text-white nombreProceso">
          <h1>Novedades</h1>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <div class="container">
            <p>Estado reprocesos: {{datosProcesos.vEstadoReprocesos}}</p>
            <p>Fecha-Hora de último reproceso: <span [ngClass]="{'alert-fecha': novedadNoProcHoy}">{{datosProcesos.vUltimoReproceso | date: 'dd/MM/yyyy HH:mm:ss'}}</span></p>
            <p>Cantidad reprocesos pendientes: {{datosProcesos.vCantidadReprocesosPendientes}}<p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="container">
      <div class="row">
        <div class="col-12 bg-info text-white nombreProceso">
          <h1>Avisos</h1>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <div class="container">
            <p>Estado reprocesos: {{datosProcesos.vEstadoProcAvisos}}</p>
            <p>Fecha-Hora de último reproceso: <span [ngClass]="{'alert-fecha': avisoNoProcHoy}">{{datosProcesos.vUltimoProcAvisos | date: 'dd/MM/yyyy HH:mm:ss'}}</span></p>
            <p>Cantidad reprocesos pendientes: {{datosProcesos.vCantidadProcAvisosPend}}<p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="container">
      <div class="row">
        <div class="col-12 bg-info text-white nombreProceso">
          <h1>Justificaciones</h1>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <div class="container">
            <p>Estado reprocesos: {{datosProcesos.vEstadoProcJustificaciones}}</p>
            <p>Fecha-Hora de último reproceso: <span [ngClass]="{'alert-fecha': justificacionNoProcHoy}">{{datosProcesos.vUltimoProcJustificaciones | date: 'dd/MM/yyyy HH:mm:ss'}}</span></p>
            <p>Cantidad reprocesos pendientes: {{datosProcesos.vCantidadProcJustificacionesPend}}<p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>





