<mat-card class="card-actions h-100">
  <mat-card-content>
    <div class="container-fluid">
      <div class="row w-100">
        <div class="col-2 d-flex align-items-center  p-0 justify-content-center">
          <div class="text-center mx-2">
            <button mat-icon-button class="icon-action-print" matTooltip="Exportar a pdf" aria-label="Exportar a pdf"
              (click)="printFile()">
              <i class="far fa-file-pdf"></i>
            </button>

            <button mat-mini-fab class="btn-open-nomina ml-2" color="primary" matTooltip="Buscar"
              (click)="openModalNomina()">
              <mat-icon>search</mat-icon>
            </button>
          </div>
        </div>

        <!-- <mat-divider [vertical]="true"></mat-divider> -->

        <!-- <div class="col-2 d-flex align-items-center">
          <div class="flex-box">
            <button mat-mini-fab class="btn-open-nomina" color="primary" matTooltip="Buscar"
              (click)="openModalNomina()">
              <mat-icon>search</mat-icon>
            </button>
          </div>
        </div> -->

        <mat-divider [vertical]="true"></mat-divider>

        <div class="col-3 d-flex align-items-center px-1 justify-content-center text-center">
          <div class="info-empleado">
            <p>{{ getEmpleadoSelected() }}</p>
            <p>{{ empleadoSelected?.Seccion }}</p>
          </div>
        </div>

        <mat-divider [vertical]="true"></mat-divider>

        <div class="col-4 periodo-select-col">
          <mat-checkbox class="d-block text-center pt-1 chb-todos-periodos" [checked]="chkSeleccionarPeriodo"
            (change)="checkboxSelect($event)">
            Seleccionar periodo
          </mat-checkbox>

          <mat-form-field appearance="outline" class="flex-container flex-item periodo-select">
            <mat-label>Periodo</mat-label>
            <mat-select (selectionChange)="getPeriodoEmit($event)"  [(ngModel)]="periodoPreset" [disabled]="!chkSeleccionarPeriodo">
              <mat-option *ngFor="let item of periodosAnualesList" [value]="item" >
                {{ item }}
              </mat-option>
            </mat-select>
          </mat-form-field>


          <!-- POR AHORA NO SE VA A USAR -->
          <!-- <mat-checkbox class="d-block text-center pt-1" [checked]="chkSeleccionarPeriodo" (change)="checkboxSelect($event)">
            Todos los periodos
          </mat-checkbox>

          <mat-divider></mat-divider>

          <div class="flex-container">
            <div class="flex-box">
              <mat-form-field appearance="outline" class="mat-form-field-date">
                <mat-label>Desde</mat-label>
                <input matInput [matDatepicker]="dpStart" [formControl]="start" [min]="minDate">
                <mat-datepicker-toggle matSuffix [for]="dpStart"></mat-datepicker-toggle>
                <mat-datepicker #dpStart [disabled]="chkSeleccionarPeriodo">
                </mat-datepicker>
              </mat-form-field>
            </div>

            <div class="flex-box">
              <mat-form-field appearance="outline" class="mat-form-field-date">
                <mat-label>Hasta</mat-label>
                <input matInput [matDatepicker]="dpEnd" [formControl]="end" [min]="start.value" [max]="maxRangeDate">
                <mat-datepicker-toggle matSuffix [for]="dpEnd"></mat-datepicker-toggle>
                <mat-datepicker #dpEnd [disabled]="chkSeleccionarPeriodo || !start.value"></mat-datepicker>
              </mat-form-field>
            </div>
          </div> -->
        </div>

        <mat-divider [vertical]="true"></mat-divider>

        <div class="col d-flex align-items-center justify-content-center pr-0">
          <button mat-raised-button color="primary" (click)="buscar()">Buscar</button>
        </div>
      </div>
    </div>

  </mat-card-content>
</mat-card>
