import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TipoConfiguracionEnum } from 'src/app/enums/configuracion.enum';
import { HtmlComponent } from 'src/app/models/general/html-component.interface';
import { ParamEntity } from 'src/app/models/general/param.model';
import { Configuracion } from 'src/app/models/rrhh/configuracion.model';
import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { ConfiguracionService } from 'src/app/services/configuracion.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';

@Component({
  selector: 'app-modal-incidencias-agrupadores',
  templateUrl: './modal-incidencias-agrupadores.component.html',
  styleUrls: ['./modal-incidencias-agrupadores.component.scss']
})
export class ModalIncidenciasAgrupadoresComponent implements OnInit, OnDestroy {
  configuracion: Configuracion;
  form: UntypedFormGroup;
  formTemplateArr: HtmlComponent[];
  title: string;

  constructor(
    public dialogRef: MatDialogRef<ModalIncidenciasAgrupadoresComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private configuracionService: ConfiguracionService,
    private userStorageService: UserValuesService,
    private _snackBar: SnackBarService,
    private datepipe: DatePipe
  ) {
    this.configuracion = data.configuracion;
    const type = this.configuracion.Tipo === TipoConfiguracionEnum.MODIFICAR ? 'Editar' : 'Alta';
    this.title = `${type} - ${this.configuracion.AliasTabla}`;
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({});
    this.createForm(this.configuracion.Tipo == TipoConfiguracionEnum.MODIFICAR);
  }

  ngOnDestroy() {
    this.form = new UntypedFormGroup({});
  }

  cerrarModal(result: { status, mensaje }) {
    this.dialogRef.close(result);
  }

  /*----------FORMULARIO------------*/

  private createForm(update: boolean) {
    this.formTemplateArr = this.configuracion.Elements;
    let group = {};
    this.formTemplateArr.forEach(itemTemplate => {
      let value = '';
      if (update) {
        value = this.configuracion.Row[itemTemplate.FormcontrolName];
      }
      group[itemTemplate.FormcontrolName] = new UntypedFormControl({ value, disabled: itemTemplate.Disabled });
    })
    this.form = new UntypedFormGroup(group);
  }

  submitForm() {
    const paramEntity = new ParamEntity();
    paramEntity.Json = JSON.stringify(this.formatObjectKeys(this.form.getRawValue(), /^O_/i));
    paramEntity.IdEmpresa = this.userStorageService.getUsuarioValues.IdEmpresa;
    paramEntity.IdUsuario = this.userStorageService.getUsuarioValues.IdUsuario;
    paramEntity.NombreTabla = this.configuracion.NombreTabla;
    paramEntity.Accion = this.configuracion.Tipo;

    this.configuracionService.guardarNuevaConfiguracionService(paramEntity).subscribe((result: ResponseHelper) => {
      let resultModal;
      if (result.Result === '1') {
        resultModal = {
          mensaje: result.Mensaje,
          status: true
        };
        this.cerrarModal(resultModal);
      } else {
        this.showToast(result.Mensaje, 'danger');
      }
    },
      (error) => {
        this.showToast('Hubo un error. Intente nuevamente', 'danger');
      });
  }

  private formatObjectKeys(object, regexp) {
    let group = {};

    const objectKeys = Object.keys(object)
    const objectValues: any[] = Object.values(object);
    objectKeys.forEach((key, index) => {
      key = key.replace(regexp, '');
      let value = (objectValues[index] && objectValues[index].Key) ? objectValues[index].Value : objectValues[index];

      if ((/(\/)|(-)/).test(value)) {
        try {
          value = this.datepipe.transform(value, 'dd-MM-yyyy');
        } catch (error) { }
      }
      group[key] = value ? value.toString() : '';
    });
    return group;
  }

  private showToast(mensaje, tipo = 'success') {
    this._snackBar.openSnackBar(`snack-${tipo}`, mensaje, 3000);
  }

}


