<div [ngClass]="{'bg-opacity': menuMarcacionOpened}"></div>
<div class="container">
  <div class="row mb-4">
    <div class="col-6 mx-auto text-center text-white">
      <app-alert [titulo]="'Avisos'"></app-alert>
    </div>
  </div>
  <div class="row">
    <div class="col-2 align-self-center">
      <mat-label class="label-legajo shadow-sm">
        {{ (aviso.IdLegajo) ? 'Leg. ' + aviso.IdLegajo : 'Legajo' }}</mat-label>
    </div>
    <div class="col-1 align-self-center">
      <button mat-mini-fab class="btn-open-nomina" color="primary" matTooltip="Buscar" (click)="openModalNomina()">
        <mat-icon>search</mat-icon>
      </button>
    </div>
    <div class="col-6 align-self-center" *ngIf="aviso.IdLegajo">
      <mat-chip-list>
        <mat-chip class="mx-auto position-relative">
          <span>{{aviso.Nombre + ' ' + aviso.Apellido + ' (' + aviso.Seccion + ')' }}</span>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-4">
      <mat-form-field class="w-100 select-form-field">
        <mat-label>Incidencia</mat-label>
        <mat-select [disabled]="!aviso.IdLegajo" [(ngModel)]="aviso.IdIncidencia"
          (selectionChange)="getSeleccionIncidencia($event)">
          <mat-option *ngFor="let item of listIncidenciasCmb" [matTooltip]="item.Detalle" [value]="item.Id">
            {{ item.Detalle }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-4" *ngIf="cmbPatologiaHabilitado">
      <mat-form-field class="w-100 select-form-field">
        <mat-label>Patología</mat-label>
        <mat-select [(ngModel)]="aviso.IdPatologia" (selectionChange)="getSeleccionPatologia($event)">
          <mat-option *ngFor="let item of listPatologiasCmb" [matTooltip]="item.Detalle" [value]="item.Id">
            {{ item.Detalle }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col text-center">
      <app-datepicker (dateEmit)="getStartDatePickerEmit($event)" [dateClass]="'datePickerMargin'" [dateLabel]="'Desde'"
        [emitOnInit]="true" [disabled]="!aviso.IdLegajo" [dateInput]="dateInput" [fullWidth]="true"
        [maxDate]="null"></app-datepicker>
    </div>
    <div class="col text-center">
      <mat-form-field>
        <mat-label>Días</mat-label>
        <input [disabled]="!aviso.IdLegajo" type="text" matInput placeholder="Ingrese hasta 3 dígitos"
          [(ngModel)]="aviso.Dias" (blur)="calculaFecha()" (keydown)="soloNumeros($event)" maxlength="3">
      </mat-form-field>
    </div>
    <div class="col text-center d-flex justify-content-center align-items-center">
      <mat-form-field>
        <mat-label>Hasta (inclusive)</mat-label>
        <input [disabled]="true" type="text" matInput value="{{fechaCalculadaHasta | date: 'dd/MM/yyyy'}}">
      </mat-form-field>
    </div>
    <div class="col text-center d-flex justify-content-center align-items-center">
      <mat-form-field>
        <mat-label>Retorno</mat-label>
        <input [disabled]="true" type="text" matInput value="{{fechaCalculadaRetorno | date: 'dd/MM/yyyy'}}">
      </mat-form-field>
    </div>

  </div>
  <div class="row justify-content-center">
    <div class="col">
      <mat-form-field class="w-100">
        <mat-label>Observaciones</mat-label>
        <textarea [disabled]="!aviso.IdLegajo" matInput [(ngModel)]="aviso.Observaciones"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-4">
      <button mat-stroked-button color="primary" (click)="openModalConfirmacion()"
        [disabled]="!aviso.IdLegajo">Guardar</button>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-2">
      <mat-form-field class="w-100">
        <mat-label>Filtrar</mat-label>
        <input matInput (keyup)="applyFilter($event)">
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <table mat-table [dataSource]="dataSourceAviso" class="mat-elevation-z8 table-parte-diario">
        <ng-container matColumnDef="fecha">
          <th mat-header-cell *matHeaderCellDef class="text-center"> Fecha </th>
          <td mat-cell *matCellDef="let element" class="text-center"> {{element.FechaDesde | date: 'dd/MM/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="nIncidencia">
          <th mat-header-cell *matHeaderCellDef class="text-center"> Incidencia </th>
          <td mat-cell *matCellDef="let element" matTooltip="{{ element.Nincidencia }}" class="text-center">
            {{element.Nincidencia}} </td>
        </ng-container>

        <ng-container matColumnDef="nEstado">
          <th mat-header-cell *matHeaderCellDef class="text-center"> Estado </th>
          <td mat-cell *matCellDef="let element" matTooltip="{{ element.Nestado }}" class="text-center">
            {{element.Nestado}} </td>
        </ng-container>

        <ng-container matColumnDef="observaciones">
          <th mat-header-cell *matHeaderCellDef class="text-center"> Observaciones </th>
          <td mat-cell *matCellDef="let element" matTooltip="{{ element.Observaciones }}" class="text-center"> {{
            element.Observaciones }} </td>
        </ng-container>

        <ng-container matColumnDef="nPatologia">
          <th mat-header-cell *matHeaderCellDef class="text-center"> Patología </th>
          <td mat-cell *matCellDef="let element" matTooltip="{{ element.Npatologia }}" class="text-center">
            {{element.Npatologia}} </td>
        </ng-container>

        <ng-container matColumnDef="fum">
          <th mat-header-cell *matHeaderCellDef class="text-center"> Fecha Registro </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            {{element.FechaRegistro | date: 'dd/MM/yyyy HH:mm:ss'}} </td>
        </ng-container>

        <ng-container matColumnDef="nUsuario">
          <th mat-header-cell *matHeaderCellDef class="text-center"> Usuario </th>
          <td mat-cell *matCellDef="let element" matTooltip="{{element.Nusuario}}" class="text-center">
            {{element.Nusuario}} </td>
        </ng-container>

        <ng-container matColumnDef="accion">
          <th mat-header-cell *matHeaderCellDef class="text-center"> Acción </th>
          <td mat-cell *matCellDef="let element" class="pl-0 text-center">
            <mat-icon matTooltip="Anular" class="icon-action" [matMenuTriggerFor]="menu"
              *ngIf="element.IdEstado == 1 && element.IdNovIncidencia!=0" (menuOpened)="toggleMenuOpened()"
              (menuClosed)="toggleMenuOpened()">close</mat-icon>
            <mat-menu #menu="matMenu" class="p-3 text-center menu-marcacion">
              <p>¿Anular aviso?</p>
              <button cdkFocusInitial mat-stroked-button color="primary" class="m-2"
                (click)="anularAviso(element)">Si</button>
              <button mat-stroked-button class="m-2">No</button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
          [ngClass]="{'css_justificacion_anulada': row.IdEstado == 3,'css_justificacion': row.IdEstado == 2,'css_anulado': row.IdEstado == 0, hovered: row.hovered, sombreado: row.Seleccionado}"
          (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[50, 80]"></mat-paginator>
    </div>
  </div>
</div>