<div class="container-fluid mt-3">
  <div class="spinner-container" *ngIf="!contentLoaded">
    <mat-spinner></mat-spinner>
  </div>
  <div class="row">
    <div class="col-6 mx-auto text-center text-white">
      <app-alert [titulo]="'Visor de novedades (Timeline)'"></app-alert>
    </div>
  </div>
  <div class="row">
    <div class="col-6 ml-auto">
      <app-filter-card [showRangePicker]="true" (filterEmit)="filter($event)" [hasLegajoInput]="false"
        [hasExportExcelButton]="true" (printExcelEmit)="printFile($event)"
        [exportExcelButtonDisabled]="false" (valuesRangeEmit)="getRangeValues($event)">
      </app-filter-card>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-2">
      <mat-form-field class="w-90">
        <mat-label>Filtrar</mat-label>
        <input matInput [(ngModel)]="inputFilterText" (keyup)="applyFilter($event)">
      </mat-form-field>
    </div>
    <div class="col-6 ml-auto">
        <div class="row  justify-content-end mr-1">
          <div class="col-6 border border-1 rounded">
              <br>
              <mat-checkbox class="w-100 filters text-secondary" color="primary" [(ngModel)]="checkSoloNovedades">Ver sólo Legajos con Novedades</mat-checkbox>
          </div>


          <div class="col-4 border border-1 rounded">
            <mat-form-field class="w-100 filters">
              <mat-label>Novedad</mat-label>
              <input type="text" placeholder="Concepto" matInput [matAutocomplete]="auto" #searchInput>
              <mat-autocomplete class="autocomplete" autoActiveFirstOption #auto="matAutocomplete"
                (optionSelected)="getNovedadSelected($event)" [displayWith]="displayFn" [selected]="novedadFormControl.value">
                <mat-option *ngFor="let itemOption of agrupadoresList | filter:searchInput.value: ['N_AGRUPADOR'] "
                  [matTooltip]="itemOption.N_AGRUPADOR" [value]="itemOption">
                  {{ itemOption.N_AGRUPADOR }}
                </mat-option>
              </mat-autocomplete>
              <!-- <mat-hint *ngIf="item.error" class="error">Debe seleccionar un elemento de la lista</mat-hint> -->
            </mat-form-field>
          </div>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="table-container">
        <table #matTable mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <!-- Agrupadores -->
          <ng-container *ngFor="let groupCol of groupColDef" [matColumnDef]="groupCol.colDef">
            <th mat-header-cell *matHeaderCellDef [style.text-align]="'center'" [attr.colspan]="groupCol.colSpan"
              class="bg-agrupador text-white">
              {{ groupCol.colDef }}
            </th>
          </ng-container>

          <!-- Filas subcolumnas -->
          <ng-container *ngFor="let col of columnsDef, let i = index" [matColumnDef]="col"
            [sticky]="isSticky(col, null)">
            <!-- <div *ngIf="col !== 'accion';else btnAction"> -->
            <th class="border bg-title" mat-header-cell *matHeaderCellDef> {{ col }} </th>
            <td class="border" mat-cell *matCellDef="let element" matTooltip="{{ getCellTooltip(element[col], col) }}"
              [ngClass]="isSticky(col, element)" (click)="get(col, element)">
              {{ getCellData(element[col], col) }}
            </td>
            <!-- </div> -->

            <!-- <ng-template #btnAction>
              <th mat-header-cell *matHeaderCellDef class="border text-white"> </th>
              <td mat-cell *matCellDef="let element; let i = index" class="text-center">
                <mat-checkbox #chkBox (change)="checkboxSelect($event, element); selected = i">
                </mat-checkbox>
              </td>
            </ng-template> -->

          </ng-container>

          <!-- Nombres de agrupadores -->
          <tr mat-header-row *matHeaderRowDef="groupColHeaders; sticky: true"></tr>

          <!-- Nombres de columnas -->
          <tr mat-header-row *matHeaderRowDef="columnsDef; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsDef;" [ngClass]="getRowClassCondition(row)"></tr>
        </table>
      </div>


      <mat-paginator #detallePaginator [pageSizeOptions]="[1000, 500]"></mat-paginator>
    </div>
  </div>
</div>
