<div class="container-fluid p-3">
  <div class="row">
    <div class="col-md-6 mx-auto text-center text-white">
      <app-alert [titulo]="'Reportes Individuales'"></app-alert>
    </div>
  </div>
  <div class="row my-4">
    <div class="col-6">
      <mat-card class="card-actions h-100">
        <div class="flex-container">
          <div class="w-70 mx-auto">
            <app-selection-report-individual-list (selectedOptionEmit)="getReporteSelected($event)">
            </app-selection-report-individual-list>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="col-6">
      <app-filter-individual (filterEmit)="search($event)" (printExcelEmit)="printExcel($event)">
      </app-filter-individual>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <!-- <div class="table-container"> -->
      <app-table-report-individual [setTableParams]="paramEntity" [dataSource]="dataSource" [columns]="columns">
      </app-table-report-individual>
      <!-- </div>       -->
    </div>
  </div>
</div>