export enum TipoConfiguracionEnum {
  NUEVO = 'i',
  MODIFICAR = 'u',
  ELIMINAR = 'd',
  ANULAR = 'a'
}

export enum TipoAgrupadorEnum {
  INCIDENCIA_AGRUPADORES = 1
}

export enum TipoJornadaEnum {
  FLEXIBLE = 1,
  ESPECIFICA = 2
}

export enum TipoTurnoEnum {
  SIMPLE = 1,
  DOBLE = 2
}

export enum TipoLoginFormEnum {
  AUTOGESTION = 1,
  ADMINISTRACION = 2
}
