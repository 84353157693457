import { Router, NavigationEnd } from '@angular/router';
import { UserValuesService } from './services/utils/user-values.service';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { LoadingInterceptorService } from './services/utils/loader-interceptor.service';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserAutogestionService } from './services/utils/user-autogestion.service';
import { CookieService } from 'ngx-cookie-service';
import { UserHubService } from 'src/app/services/utils/user-hub.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';
  showNav$: Observable<boolean>;
  showNavAutogestion$: Observable<boolean>;

  constructor(
    private router: Router,
    private loadingInterceptorService: LoadingInterceptorService,
    private spinnerNgx: NgxSpinnerService,
    private userValuesService: UserValuesService,
    private userAutogestionService: UserAutogestionService,
    private cookieService: CookieService,
    private _userHubService: UserHubService,
    private dateAdapter?: DateAdapter<any> 
  ) {
    this.dateAdapter.setLocale('es');
    this.showNav$ = this.userValuesService.isLoggedInBehavior;
    this.showNavAutogestion$ = this.userAutogestionService.isLoggedInAutogestionBehavior;

  }

  ngOnInit(): void {
    // Suscribirse a eventos de cambio de ruta
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Verificar la ruta actual
        const currentRoute = this.router.url.trim();

        // Puedes realizar acciones específicas basadas en la ruta actual aquí
        if (currentRoute === '/login') {
          this._userHubService.stopReconnectionCheck();
        } else {
          this._userHubService.startReconnectionCheck();
        }
      }
    });
  }

}
