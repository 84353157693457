import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Form, FormBuilder, UntypedFormControl, FormGroup } from '@angular/forms';
import { MatSelectionList, MatListOption } from '@angular/material/list';
import { ConfiguracionJornada } from 'src/app/models/rrhh/configuracion-jornada';

@Component({
  selector: 'app-list-jornadas',
  templateUrl: './list-jornadas.component.html',
  styleUrls: ['./list-jornadas.component.scss']
})
export class ListJornadasComponent implements OnInit, OnChanges {
  @Input() result: ConfiguracionJornada[];
  @ViewChild('matSelection', { static: true }) matSelection: MatSelectionList;
  @Output() elementSelectedEmit = new EventEmitter();
  @Output() clearFormsEmit = new EventEmitter();
  @ViewChild('matOptions') allSelected: MatListOption;
  control = new UntypedFormControl();
  @Input() set selectedValue(value) {
    if (value) {
      this.control.setValue([value]);
    }
  };

  constructor() { }

  ngOnInit() {
    this.matSelection.selectedOptions = new SelectionModel<MatListOption>(false);
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  getSelected(e) {
    if (e.options[0]?.selected) {
      const element: ConfiguracionJornada = e.options[0]?.value;
      this.elementSelectedEmit.emit(element);
      return;
    }
    this.clearForms();
  }

  clearForms() {
    const selected = this.matSelection.selectedOptions.selected.find(x => x.selected);
    if (selected) {
      selected.selected = false;
    }
    this.elementSelectedEmit.emit([]);
    this.clearFormsEmit.emit(true);
  }

  compareObjects(optionA: ConfiguracionJornada, optionB: ConfiguracionJornada) {
    if (optionA.IdJornada === optionB.IdJornada) {
      return true;
    }
    return false;
  }

}
