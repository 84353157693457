<h1 mat-dialog-title class="bg-primary titulo-modal">
  Asignar incidencia
  <mat-icon class="btn-close" (click)="cerrarModal()">clear</mat-icon>
</h1>
<div mat-dialog-content class="m-2">
  <div class="container">
    <div class="row">
      <div class="col">
        <mat-form-field class="w-100">
          <mat-label>Seleccione incidencia</mat-label>
          <input type="text" placeholder="" aria-label="Seleccione Jornada" matInput [matAutocomplete]="auto"
            [formControl]="myControl">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
            (optionSelected)="getSeleccionIncidencia($event)" [displayWith]="displayFn">
            <mat-option *ngFor="let item of filteredOptions | async" [matTooltip]="item.Detalle" [value]="item">
              {{ item.Detalle }}
            </mat-option>
            <!-- </mat-select> -->
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="w-100">
          <mat-label>Observaciones</mat-label>
          <textarea matInput [(ngModel)]="observaciones" [maxlength]="50"></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-button (click)="guardar()" cdkFocusInitial class="mr-3">Guardar</button>
</div>