import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'style'
})
export class StylePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    value = this.convertString(value);
    switch (value) {
      case 'estructura':
        return 'gray'
      case 'jornadas':
        return 'blue'
      case 'nomina':
        return 'yellow';
      case 'novedades':
        return 'lightblue';
      case 'sistema':
        return 'green';

      default:
        break;
    }
    return null;
  }

  private convertString(value) {
    value = value.toLowerCase().replace('ó', 'o').replace('organizacional', '').trim();
    return value;
  }

}
