import { DatePipe, formatDate } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TipoConfiguracionEnum } from 'src/app/enums/configuracion.enum';
import { HtmlComponent } from 'src/app/models/general/html-component.interface';
import { ParamEntity } from 'src/app/models/general/param.model';
import { Configuracion } from 'src/app/models/rrhh/configuracion.model';
import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { ConfiguracionService } from 'src/app/services/configuracion.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';

@Component({
  selector: 'app-modal-nomina-sectores',
  templateUrl: './modal-nomina-sectores.component.html',
  styleUrls: ['./modal-nomina-sectores.component.scss']
})
export class ModalNominaSectoresComponent implements OnInit, OnDestroy {
  paramEntity = new ParamEntity<any>();
  configuracion: Configuracion;
  form: UntypedFormGroup;
  formTemplateArr: HtmlComponent[];
  title: string;
  filteredOptions: Observable<any>[] = [];
  autocompleteArr: any[] = [];
  historialList = [];
  historialFilteredList = [];
  tipoConfiguracionEnum = TipoConfiguracionEnum;

  constructor(
    public dialogRef: MatDialogRef<ModalNominaSectoresComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private configuracionService: ConfiguracionService,
    private userStorageService: UserValuesService,
    private _snackBar: SnackBarService,
    public datepipe: DatePipe
  ) {
    this.configuracion = data.configuracion;
    const type = this.configuracion.Tipo === this.tipoConfiguracionEnum.MODIFICAR ? 'Editar' : 'Alta';
    this.title = `${type} - ${this.configuracion.AliasTabla}`;
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({});
    this.historialList = this.configuracion.FilterList.filter(x => x.key === 'S_HISTORIAL')[0].value;
    this.createForm(this.configuracion.Tipo == TipoConfiguracionEnum.MODIFICAR);
    this.detectFormControlChanges();
  }

  ngOnDestroy() {
    this.form = new UntypedFormGroup({});
  }

  cerrarModal(result: { status, mensaje }) {
    this.dialogRef.close(result);
  }

  /*----------FORMULARIO------------*/

  private createForm(update: boolean) {
    this.formTemplateArr = this.configuracion.Elements;
    let group = {};
    this.formTemplateArr.forEach(itemTemplate => {
      let value = itemTemplate.SetDefault ? itemTemplate.SetDefault : '';

      if (update) {
        value = this.configuracion.Row[itemTemplate.FormcontrolName];
      }
      if (itemTemplate.Type === 'autocomplete') {
        const value = new BehaviorSubject<any>(itemTemplate.Options).asObservable();
        this.filteredOptions.push(value);
        this.autocompleteArr.push(itemTemplate.Options);
      }
      if (itemTemplate.InputType === 'date' && value) {
        const dateArr = value.split('/');
        value = dateArr[2] + '/' + dateArr[1] + '/' + dateArr[0];
        value = formatDate(value, 'yyyy-MM-dd', 'en');
      }
      group[itemTemplate.FormcontrolName] = new UntypedFormControl({ value, disabled: itemTemplate.Disabled });
    })
    this.form = new UntypedFormGroup(group);
  }

  private detectFormControlChanges() {
    this.form.controls['ID_LEGAJO'].valueChanges.subscribe(x => {
      if (x && x.Key) {
        x = x.Value;
        this.historialFilteredList = this.historialList.filter(value => value.Value.includes(x));
      } else {
        this.historialFilteredList = [];
      }
    })
  }


  submitForm() {
    this.validarDatos()
  }

  validarDatos() {
    var parameters: any = this.formatObjectKeys(this.form.getRawValue(), /^O_/i);
    this.paramEntity = new ParamEntity();
    this.paramEntity.Json = JSON.stringify({
      pIdLegajo: parameters.ID_LEGAJO,
      pFechaDesde: parameters.FECHA_INICIO,
      pFechaHasta: parameters.FECHA_FIN,
      pNombreTabla: this.configuracion.NombreTabla,
      pAccion: this.configuracion.Tipo
    });

    this.configuracionService.getValidarDatosABM(this.paramEntity).subscribe((result: ResponseHelper) => {
      if (result.Result === '1') {
        this.submit();
      } else {
        this.showToast(result.Mensaje, 'danger');
      }
    },
      (error) => {
        this.showToast('Hubo un error. Intente nuevamente', 'danger');
      });
  }

  submit() {
    const paramEntity = new ParamEntity();
    paramEntity.Json = JSON.stringify(this.formatObjectKeys(this.form.getRawValue(), /^O_/i));
    paramEntity.IdEmpresa = this.userStorageService.getUsuarioValues.IdEmpresa;
    paramEntity.IdUsuario = this.userStorageService.getUsuarioValues.IdUsuario;
    paramEntity.NombreTabla = this.configuracion.NombreTabla;
    paramEntity.Accion = this.configuracion.Tipo;

    this.configuracionService.guardarNuevaConfiguracionService(paramEntity).subscribe((result: ResponseHelper) => {
      let resultModal;
      if (result.Result === '1') {
        resultModal = {
          mensaje: result.Mensaje,
          status: true
        };
        this.cerrarModal(resultModal);
      } else {
        this.showToast(result.Mensaje, 'danger');
      }
    },
      (error) => {
        this.showToast('Hubo un error. Intente nuevamente', 'danger');
      });
  }








  private formatObjectKeys(object, regexp) {
    let group = {};

    const objectKeys = Object.keys(object)
    const objectValues: any[] = Object.values(object);
    objectKeys.forEach((key, index) => {
      key = key.replace(regexp, '');
      let value = (objectValues[index] && objectValues[index].Key) ? objectValues[index].Value : objectValues[index];

      if ((/(\/)|(-)/).test(value)) {
        try {
          value = this.datepipe.transform(value, 'dd-MM-yyyy');
        } catch (error) { }
      }
      group[key] = value ? value.toString() : '';
    });
    // if (this.configuracion.Tipo == TipoConfiguracionEnum.MODIFICAR) {
    //   delete group['FECHA_FIN'];
    // }
    return group;
  }

  private showToast(mensaje, tipo = 'success') {
    this._snackBar.openSnackBar(`snack-${tipo}`, mensaje, 3000);
  }

  getFilteredList(value, item = null, index?) {
    value = value.target.value;
    this.filteredOptions[index] = this.subscribeEventChange(value, item, index);
  }

  subscribeEventChange(value, item, index) {
    const value$ = of(value);
    return value$
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.Key),
        map(value => this._filter(value, item, index))
      );
  }

  private _filter(value, item, index): any[] {
    const filterValue = value.toLowerCase();
    const arrResult = this.formTemplateArr.filter(x => x.Type === 'autocomplete')[index].Options.filter(option => option.Key.toLowerCase().includes(filterValue));
    if (item) {
      // item.error = !value || (!arrResult.length && !this.lstJornadas.some(option => option.Detalle.toLowerCase().includes(filterValue)));
    }
    return arrResult;
  }

  displayFn(index, item): string {
    if (this.configuracion.Tipo == TipoConfiguracionEnum.MODIFICAR) {
      return this.autocompleteArr[index].find(x => x.Value === item.toString()).Key;
    }
    return item && item.Key ? item.Key : item;
  }

  getAutocompleteArray(index) {
    return this.filteredOptions[index];
  }

}
