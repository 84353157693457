import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(
    private http: HttpClient
  ) { }

  getImageLocalService(filenames: any[]): Observable<any> {
    let requests = [];
    filenames.forEach((filePath) => {
      const req = this.http.get(`assets/images/${filePath.val}`, { responseType: 'text' }).pipe();
      requests.push(req);
    });
    return forkJoin(requests);
  }

}
