import { SourceJornadas } from './../../../models/sources/sourceJornadas';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-usr-jornadas',
  templateUrl: './list-usr-jornadas.component.html',
  styleUrls: ['./list-usr-jornadas.component.scss']
})
export class ListUsrJornadasComponent implements OnInit {
  @Input() lstJornadas: SourceJornadas[];
  lstJornadasFiltered: SourceJornadas[];
  @Input() set idUsuarioJornadas(value) {
    if (!value.ids || !value.ids.length) {
      this.lstJornadasFiltered = [];
      return;
    }

    setTimeout(() => {
      this.lstJornadasFiltered = value.arr.filter(x => value.ids.includes(x.IdJornada));
    }, 800);
  }
  constructor() { }

  ngOnInit() {
  }

  clearForms() {
    this.lstJornadasFiltered = [];
  }

}
