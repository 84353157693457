import { ConfiguracionNomina } from './../../models/rrhh/configuracion-nomina';
import { SourceIncidencias } from './../../models/sources/sourceIncidencias';
import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { ParamEntity } from 'src/app/models/general/param.model';
import { SourceMetodosAutenticacion } from 'src/app/models/sources/sourceMetodosAutenticacion';
import { SourceEmpleados } from 'src/app/models/sources/sourceEmpleados';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { MatDialog } from '@angular/material/dialog';
import { MatLegacyListOption as MatListOption, MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { Usuario } from 'src/app/models/general/usuario.model';
import { ConfiguracionService } from 'src/app/services/configuracion.service';
import { SelectionModel } from '@angular/cdk/collections';
import { formatDate } from '@angular/common';
import { ModalConfirmacionComponent } from '../modals/modal-confirmacion/modal-confirmacion.component';
import { ModalNominaCofarsurComponent } from '../modals/modal-nomina-cofarsur/modal-nomina-cofarsur.component';
import { Nomina } from 'src/app/models/rrhh/nomina.model';
import { NominaService } from 'src/app/services/rrhh/nomina/nomina.service';
import moment from 'moment';

@Component({
  selector: 'app-configuracion-nomina',
  templateUrl: './configuracion-nomina.component.html',
  styleUrls: ['./configuracion-nomina.component.scss']
})
export class ConfiguracionNominaComponent implements OnInit {
  usuario: Usuario;
  configuracionNominaSourcesOriginal: ConfiguracionNomina;
  configuracionNominaSources: ConfiguracionNomina;
  // rolOptions: SourceUsuariosRoles[];
  metodosAutenticacionOptions: SourceMetodosAutenticacion[];
  sourceIncidencias: SourceIncidencias[];
  sourceNomina: SourceEmpleados[];
  idUsuarioPorPlantilla: number;
  idPlantillaNomina: number;
  idPlantillaJornada: number;
  idLegajosUsuariosNomina: number[];
  idUsuarioJornadas: number[];
  paramEntity = new ParamEntity();
  legajoNew = true;
  idLegajoSelected: number;
  idLegajoGuardado: number = 0;
  formNomina: UntypedFormGroup;
  inputFiltroLegajo = new UntypedFormControl();
  oldFiltroLegajo: string;
  controlNomina = new UntypedFormControl();
  gestionaIncidencias = false;
  metodoShooter = false;
  idEquiposSeleccionados: number[];
  @ViewChild('matSelectionNomina', { static: true }) matSelectionNomina: MatSelectionList;
  @ViewChild('matSelectionEquipos', { static: true }) matSelectionEquipos: MatSelectionList;

  SourceShooterEquipos: any[];
  listEquiposChecked: any[];
  listNominaCofarsur: any[];

  constructor(
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private userValuesService: UserValuesService,
    private configuracionService: ConfiguracionService,
    private _snackBar: SnackBarService,
    private _nominaService: NominaService
  ) {
    this.usuario = this.userValuesService.getUsuarioValues;
  }

  ngOnInit() {
    this.datosRefresh();
  }

  datosRefresh() {
    this.matSelectionNomina.selectedOptions = new SelectionModel<MatListOption>(false);
    this.configuracionNominaSourcesOriginal = new ConfiguracionNomina();
    this.configuracionNominaSources = new ConfiguracionNomina();
    this.createForm();
    this.getNominaSources();
    this.obtenerNominaCofarsur();
  }

  private createForm() {
    this.formNomina = this.formBuilder.group({
      idLegajo: [0],
      idLegajoMarcacion: [],
      idLegajoLiquidacion: [],
      apellido: [],
      nombre: [],
      cuit: [],
      dni: [],
      fechaNacimiento: [],
      codigoSeguridadShooterBot: [],
      gestionaIncidencia: [],
      IdIncidencia: [],
      ExcluidoIndicadores: [],
      idEquipoShooter: [],
      idMetodoAutenticacion: []
    })
  }

  private getNominaSources() {
    const paramEntity = new ParamEntity();
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    setTimeout(() => {
      this.configuracionService.getSourceConfiguracionNomina(paramEntity).subscribe((result: any) => {
        this.configuracionNominaSourcesOriginal = new ConfiguracionNomina(result);
        this.configuracionNominaSources = new ConfiguracionNomina(result);
        this.SourceShooterEquipos = this.configuracionNominaSourcesOriginal.SourceShooterEquipos;
        this.nuevoLegajo();
      });
    }, 1000);
  }

  async obtenerNominaCofarsur() {
    let resultado = await this._nominaService.getNominaCofarsur();
    this.listNominaCofarsur = resultado.Data;
  }

  applyFilter(event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.empleadoFilter(filterValue);
  }

  empleadoFilter(value: string) {
    const filterValue = value;
    if (filterValue) {
      this.configuracionNominaSources.SourceNominaTotal = this.configuracionNominaSourcesOriginal.SourceNominaTotal.filter(x =>
        (x.APELLIDO + ' ' + x.NOMBRE).toLowerCase().includes(filterValue.toLowerCase())
        || x.APELLIDO.toLowerCase().includes(filterValue.toLowerCase())
        || x.NOMBRE.toLowerCase().includes(filterValue.toLowerCase())
        || x.ID_LEGAJO.toString().includes(filterValue))
      
    } else {
      this.configuracionNominaSources.SourceNominaTotal = this.configuracionNominaSourcesOriginal.SourceNominaTotal;
    }
    this.oldFiltroLegajo = this.inputFiltroLegajo.value;
  }

  nuevoLegajo() {
    this.clearForms();
  }

  clearForms() {
    this.legajoNew = true;
    this.formNomina.reset();
    this.clearChecksNomina();
    this.clearChecksEquipos();
    this.gestionaIncidencias = false;
    this.metodoShooter = false;
  }


  clearChecksNomina() {
    this.matSelectionNomina.options.forEach(x => {
      x._setSelected(false);
    });
  }

  clearChecksEquipos() {
    this.matSelectionEquipos.options.forEach(x => {
      x._setSelected(false);
    });
  }


  compareObjectsNomina(optionA, optionB) {
    if (optionA.ID_LEGAJO === optionB.ID_LEGAJO) {
      return true;
    }
    return false;
  }

  compareObjectsMetodoAutenticacion(optionA, optionB) {
    if (optionA === optionB) {
      return true;
    }
    return false;
  }

  compareObjectsEquipos(optionA, optionB) {
    if (optionA === optionB) {
      return true;
    }
    return false;
  }

  getSeleccionMetodoAutenticacion(e) {
    this.metodoShooter = e.value == 1 || e.value == 2 ? true : false;
  }

  getGestionaIncidencia(e) {
    this.gestionaIncidencias = e.checked;
  }

  getExcluidoIndicadores(e) {

  }

  getSelectedLegajo(e) {
    const empleado = e.options[0]?.value;
    if (e.options[0]?.selected) {
      this.selectLegajo(empleado);

    } else {
      this.clearForms();
    }
  }

  selectLegajo(empleado) {
    this.formNomina.controls.idLegajo.setValue(empleado.ID_LEGAJO);
    this.formNomina.controls.apellido.setValue(empleado.APELLIDO);
    this.formNomina.controls.cuit.setValue(empleado.CUIL);
    this.formNomina.controls.idMetodoAutenticacion.setValue(empleado.ID_METODO_AUTENTICACION);
    this.formNomina.controls.gestionaIncidencia.setValue(JSON.parse(empleado.ASIGNA_INCIDENCIA));
    this.formNomina.controls.ExcluidoIndicadores.setValue(JSON.parse(empleado.EXCLUIDO_INDICADORES));
    this.formNomina.controls.idLegajoMarcacion.setValue(empleado.ID_LEGAJO_MARCACION);
    this.formNomina.controls.idLegajoLiquidacion.setValue(empleado.ID_LEGAJO_LIQUIDACION);
    this.formNomina.controls.nombre.setValue(empleado.NOMBRE);
    this.formNomina.controls.dni.setValue(empleado.DNI);
    this.formNomina.controls.codigoSeguridadShooterBot.setValue(empleado.CODIGO_AUTENTICACION);
    this.formNomina.controls.IdIncidencia.setValue(empleado.ID_INCIDENCIA);
    const fechaNacimiento = empleado.FECHA_NACIMIENTO; 
    const fechaFormateada = moment(fechaNacimiento, "DD/MM/YYYY").format("YYYY-MM-DD");
    this.formNomina.controls.fechaNacimiento.setValue(fechaFormateada);
    this.legajoNew = empleado.ID_LEGAJO != 0 ? false : true;
    this.gestionaIncidencias = this.formNomina.controls.gestionaIncidencia.value;
    if (empleado.ID_METODO_AUTENTICACION == 1 || empleado.ID_METODO_AUTENTICACION == 2) {
      this.metodoShooter = true;
      this.setCheckValues(empleado.ID_LEGAJO);
    } else {
      this.metodoShooter = false;
    }
  }

  setCheckLegajo(idLegajo: number) {
    this.matSelectionNomina.options.forEach(x => {
      if (Number(x.value.ID_LEGAJO) == Number(idLegajo)) {
        x._setSelected(true);
      }
    })
  }

  setCheckValues(idLegajo: number) {
    this.listEquiposChecked = [];
    var listEquipoxLegajo: number[] = this.configuracionNominaSourcesOriginal.SourceShooterLegajosEquipos.filter(x => x.ID_LEGAJO === idLegajo).map(x => Number(x.ID_EQUIPO));

    this.matSelectionEquipos.options.forEach(x => {
      if (listEquipoxLegajo.indexOf(Number(x.value)) > -1) {
        x._setSelected(true);
        this.listEquiposChecked.push(Number(x.value));
      } else {
        x._setSelected(false);
      }
    });
  }

  equiposSeleccionadosRefresh() {
    this.idEquiposSeleccionados = [];
    this.matSelectionEquipos.options.forEach(x => {
      if (x.selected) {
        this.idEquiposSeleccionados.push(Number(x.value));
      }
    })
  }

  submitForm(accion?: string) {
    this.equiposSeleccionadosRefresh();

    if (this.validarDatos()) {
      const dialogRef = this.dialog.open(ModalConfirmacionComponent, {
        width: '500px',
        height: '120px',
        autoFocus: false,
        data: {
          titulo: accion == 'Eliminar' ? '¿Está seguro que desea anular el Legajo seleccionado?' : '¿Desea gardar los cambios realizados?'
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          console.log(new Date(this.formNomina.getRawValue().fechaNacimiento));

          let dateNacimiento = new Date(this.formNomina.getRawValue().fechaNacimiento);
          dateNacimiento.setMinutes(dateNacimiento.getMinutes() + dateNacimiento.getTimezoneOffset());
          let newDate: string = formatDate(dateNacimiento, 'dd/MM/yyyy', 'es-AR');

          this.idLegajoGuardado = Number(this.formNomina.getRawValue().idLegajo);

          const param = new ParamEntity();
          param.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
          param.IdUsuario = this.usuario.IdUsuario;
          param.Json = JSON.stringify({
            IdLegajo: this.formNomina.getRawValue().idLegajo,
            Apellido: this.formNomina.getRawValue().apellido,
            Nombre: this.formNomina.getRawValue().nombre,
            FechaNacimiento: newDate,
            Cuil: this.formNomina.getRawValue().cuit,
            IdLegajoLiquidacion: this.formNomina.getRawValue().idLegajoLiquidacion,
            IdLegajoMarcacion: this.formNomina.getRawValue().idLegajoMarcacion,
            Dni: this.formNomina.getRawValue().dni,
            IdMetodoAutenticacion: this.formNomina.getRawValue().idMetodoAutenticacion,
            CodigoSeguridad: this.formNomina.getRawValue().codigoSeguridadShooterBot,
            IdIncidencia: this.gestionaIncidencias ? this.formNomina.getRawValue().IdIncidencia : 0,
            ExluidoIndicadores: this.formNomina.getRawValue().ExcluidoIndicadores ? 1 : 0,
            EquiposHabilitados: this.idEquiposSeleccionados,
            Accion: this.legajoNew ? 'Nuevo' : 'Modificar',
          });

          this.configuracionService.guardarConfiguracionNomina(param).subscribe((result: ResponseHelper) => {
            if (result.Ok) {
              this.matSelectionNomina.selectedOptions = new SelectionModel<MatListOption>(false);
              this.clearForms();
              this.getNominaSources();

              setTimeout(() => {
                this.setCheckLegajo(Number(this.idLegajoGuardado))
                this.inputFiltroLegajo.setValue(this.oldFiltroLegajo);
                this.empleadoFilter(this.oldFiltroLegajo);
                this.selectLegajo(this.configuracionNominaSourcesOriginal.SourceNominaTotal.filter(x => Number(x.ID_LEGAJO) === Number(this.idLegajoGuardado)).slice()[0]);
              }, 1500);

              this._snackBar.openSnackBar('snack-success', accion == 'Eliminar' ? 'Legajo anulado' : 'Se guardó correctamente', 3000);
            } else {
              this._snackBar.openSnackBar('danger', 'Hubo un error. Intente nuevamente', 3000);
            }

          },
            () => {
              this._snackBar.openSnackBar('danger', 'Hubo un error. Intente nuevamente', 3000)
            })
        }
      })
    }
  }


  private validarDatos() {
    if (this.formNomina.getRawValue().idLegajo == null || this.formNomina.getRawValue().idLegajo.length == 0) {
      this.mostrarMensajeValidacion('Debe ingresar un Número de Legajo.');
      return false;
    }

    if (this.formNomina.getRawValue().idLegajoLiquidacion == null || this.formNomina.getRawValue().idLegajoLiquidacion.length == 0) {
      this.mostrarMensajeValidacion('Debe ingresar un Número de Legajo de Liquidación.');
      return false;
    }

    if (this.formNomina.getRawValue().apellido == null || this.formNomina.getRawValue().apellido.length == 0) {
      this.mostrarMensajeValidacion('Debe ingresar el Apellido.');
      return false;
    }

    if (this.formNomina.getRawValue().nombre == null || this.formNomina.getRawValue().nombre.length == 0) {
      this.mostrarMensajeValidacion('Debe ingresar el Nombre.');
      return false;
    }

    if (this.formNomina.getRawValue().cuit == null || this.formNomina.getRawValue().cuit.length == 0) {
      this.mostrarMensajeValidacion('Debe ingresar el CUIL.');
      return false;
    }

    if (this.formNomina.getRawValue().dni == null || this.formNomina.getRawValue().dni.length == 0) {
      this.mostrarMensajeValidacion('Debe ingresar el DNI.');
      return false;
    }

    if (this.formNomina.getRawValue().fechaNacimiento == null || this.formNomina.getRawValue().fechaNacimiento.length == 0) {
      this.mostrarMensajeValidacion('Debe ingresar la Fecha de Nacimiento.');
      return false;
    }

    if (this.gestionaIncidencias && this.formNomina.getRawValue().IdIncidencia <= 0) {
      this.mostrarMensajeValidacion('Debe ingresar Selecciónar alguna Incidencia.');
      return false;
    }

    if (this.formNomina.getRawValue().idMetodoAutenticacion <= 0) {
      this.mostrarMensajeValidacion('Debe ingresar algún Método de Autenticación.');
      return false;
    }

    if ((this.formNomina.getRawValue().idMetodoAutenticacion == 1 || this.formNomina.getRawValue().idMetodoAutenticacion == 2) && (this.formNomina.getRawValue().codigoSeguridadShooterBot == null || this.formNomina.getRawValue().codigoSeguridadShooterBot.length == 0)) {
      this.mostrarMensajeValidacion('Debe ingresar un Código de Autenticación.');
      return false;
    }

    if ((this.formNomina.getRawValue().idMetodoAutenticacion == 1 || this.formNomina.getRawValue().idMetodoAutenticacion == 2) && (this.formNomina.getRawValue().codigoSeguridadShooterBot.length < 3)) {
      this.mostrarMensajeValidacion('El Código de Autenticación no puede ser menor de 3 caracteres');
      return false;
    }

    if ((this.formNomina.getRawValue().idMetodoAutenticacion == 1 || this.formNomina.getRawValue().idMetodoAutenticacion == 2) && (this.idEquiposSeleccionados == null || this.idEquiposSeleccionados.length == 0)) {
      this.mostrarMensajeValidacion('Debe seleccionar algún Equipo ShooterBot Habilitado.');
      return false;
    }


    return true;
  }

  private mostrarMensajeValidacion(mensaje: string) {
    this._snackBar.openSnackBar('snack-danger', mensaje, 5000);
  }

  openModalNomina() {
    const dialogRef = this.dialog.open(ModalNominaCofarsurComponent, {
      width: '1200px',
      height: '550px',
      panelClass: 'modal-marcacion',
      autoFocus: false,
      data: {
        nominaSniper: this.configuracionNominaSources.SourceNominaTotal,
        nominaCofarsur: this.listNominaCofarsur
      }
    });
    dialogRef.beforeClosed().subscribe((result: any) => {
      if (result) {
        this.formNomina.controls.idLegajo.setValue(result.IdLegajo);
        this.formNomina.controls.idLegajoLiquidacion.setValue(result.IdLegajoLiquidacion);
        this.formNomina.controls.apellido.setValue(result.Apellido);
        this.formNomina.controls.nombre.setValue(result.Nombre);
        this.formNomina.controls.cuit.setValue(result.Cuil);
        // Object.assign(this.justificacion, result);
        // this.getJustificacionesGrilla(this.justificacion.IdLegajo);
      }
    });
  }

}
