<div class="card-inner-padding">
  <mat-card-header>
    <!-- <mat-card-title>Login</mat-card-title> -->
  </mat-card-header>
  <mat-card-content class="p-2 mb-0">
  </mat-card-content>
  <mat-card-actions class="p-2 text-center">
    <button class="btn-block mx-auto" mat-raised-button (click)="goToAutogestion()" color="primary">Autogestión</button>
    <button mat-raised-button (click)="goToAdministracion()" type="button" class="btn-block mx-auto my-2">
      Administración
    </button>
  </mat-card-actions>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="mostrarProgressBar"></mat-progress-bar>