import { Component, OnInit, Inject } from '@angular/core';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-instructivo-video',
  templateUrl: './modal-instructivo-video.component.html',
  styleUrls: ['./modal-instructivo-video.component.scss']
})
export class ModalInstructivoVideoComponent implements OnInit {

  objeto: any;

  constructor(
    public dialogRef: MatDialogRef<ModalInstructivoVideoComponent>,
    public dialog: MatDialog,
    private _snackBar: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.objeto = this.data;
  }

  ngOnInit() {
  }

  cerrarModal(estado) {
    this.dialogRef.close(estado);
  }

}

export interface DialogData {
  titulo: string;
  // obj: any;
}

