import { SourceEmpleados } from "../sources/sourceEmpleados";
import { SourceEstados } from "../sources/sourceEstados";
import { SourceJornadas } from "../sources/sourceJornadas";
import { SourcePlantillasJornadas } from "../sources/sourcePlantillasJornadas";
import { SourcePlantillasJornadasXjornada } from "../sources/sourcePlantillasJornadasXjornada";
import { SourcePlantillasNomina } from "../sources/sourcePlantillasNomina";
import { SourcePlantillasNominaXempleados } from "../sources/sourcePlantillasNominaXempleados";
import { SourceUsuarios } from "../sources/sourceUsuarios";
import { SourceUsuariosRoles } from "../sources/sourceUsuariosRoles";
import { SourceUsuariosXempleados } from "../sources/sourceUsuariosXempleados";
import { SourceUsuariosXjornada } from "../sources/sourceUsuariosXjornada";
import { SourceUsuariosXplantillaJornada } from "../sources/sourceUsuariosXplantillaJornada";
import { SourceUsuariosXplantillaNomina } from "../sources/sourceUsuariosXplantillaNomina";

export class ConfiguracionUsuarios {
  SourceUsuarios: SourceUsuarios[];
  SourceUsuariosRoles: SourceUsuariosRoles[];
  SourceEstados: SourceEstados[];
  SourcePlantillasNomina: SourcePlantillasNomina[];
  SourceEmpleados: SourceEmpleados[];
  SourcePlantillasNominaXempleados: SourcePlantillasNominaXempleados[];
  SourceUsuariosXplantillaNomina: SourceUsuariosXplantillaNomina[];
  SourcePlantillasJornadas: SourcePlantillasJornadas[];
  SourceJornadas: SourceJornadas[];
  SourcePlantillasJornadasXjornada: SourcePlantillasJornadasXjornada[];
  SourceUsuariosXplantillaJornada: SourceUsuariosXplantillaJornada[];
  SourceUsuariosXempleados: SourceUsuariosXempleados[];
  SourceUsuariosXjornada: SourceUsuariosXjornada[];

  constructor() {
    this.SourceUsuarios = [];
    this.SourceUsuariosRoles = [];
    this.SourceEstados = [];
    this.SourcePlantillasNomina = [];
    this.SourceEmpleados = [];
  }
}
