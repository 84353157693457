import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { ParamEntity } from 'src/app/models/general/param.model';
import { Usuario } from 'src/app/models/general/usuario.model';
import { ConfiguracionUsuarios } from 'src/app/models/rrhh/configuracion-usuarios';
import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { SourceJornadas } from 'src/app/models/sources/sourceJornadas';
import { SourcePlantillasJornadas } from 'src/app/models/sources/sourcePlantillasJornadas';
import { ConfiguracionService } from 'src/app/services/configuracion.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { ModalConfirmacionComponent } from '../modals/modal-confirmacion/modal-confirmacion.component';
import { ConfigPlantillasJornadasListJornadasComponent } from './config-plantillas-jornadas-list-jornadas/config-plantillas-jornadas-list-jornadas.component';
import { ConfigPlantillasJornadasListPlantillasComponent } from './config-plantillas-jornadas-list-plantillas/config-plantillas-jornadas-list-plantillas.component';


@Component({
  selector: 'app-configuracion-plantillas-jornadas',
  templateUrl: './configuracion-plantillas-jornadas.component.html',
  styleUrls: ['./configuracion-plantillas-jornadas.component.scss']
})
export class ConfiguracionPlantillasJornadasComponent implements OnInit {
  usuario: Usuario;
  configuracionUsuariosSources = new ConfiguracionUsuarios();
  formData: UntypedFormGroup;
  newValue: boolean = true;
  paramEntity = new ParamEntity();
  idJornada: number[];
  formUsuarios: UntypedFormGroup;
  inputFiltroPlantillasJornadas = new UntypedFormControl();
  inputFiltroJornadas = new UntypedFormControl();
  idPlantillaJornada: number = null;
  inputFiltroEmpleados = new UntypedFormControl();

  // SOURCES
  sourcePlantillasJornadasFiltered: SourcePlantillasJornadas[] = [];
  sourceJornadasFiltered: SourceJornadas[] = [];


  @ViewChild(ConfigPlantillasJornadasListJornadasComponent) configPlantillasJornadasListJornadasComponent: ConfigPlantillasJornadasListJornadasComponent;
  @ViewChild(ConfigPlantillasJornadasListPlantillasComponent) configPlantillasJornadasListPlantillasComponent: ConfigPlantillasJornadasListPlantillasComponent;


  constructor(
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private userValuesService: UserValuesService,
    private configuracionService: ConfiguracionService,
    private _snackBar: SnackBarService
  ) {
    this.usuario = this.userValuesService.getUsuarioValues;
    this.paramEntity.IdPlantillasJornada = [];
  }

  ngOnInit() {
    this.datosRefresh();
    this.subscribeInputs();
  }

  subscribeInputs() {
    this.inputFiltroEmpleados.valueChanges.subscribe((value) => {
      if (value) {
        this.sourceJornadasFiltered = this.configuracionUsuariosSources.SourceJornadas.filter(x => x.Njornada.toLowerCase().includes(value.toLowerCase()));
      } else {
        this.sourceJornadasFiltered = this.configuracionUsuariosSources.SourceJornadas;
      }
    });
  }

  private getConfiguracionSources() {
    const paramEntity = new ParamEntity();
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    setTimeout(() => {
      this.configuracionService.getSourceConfPlantillasJornadas(paramEntity).subscribe((result: ConfiguracionUsuarios) => {
        this.configuracionUsuariosSources = result;
        this.sourcePlantillasJornadasFiltered = this.configuracionUsuariosSources.SourcePlantillasJornadas;
        this.sourceJornadasFiltered = this.configuracionUsuariosSources.SourceJornadas;

        this.configPlantillasJornadasListPlantillasComponent.dataSourceList = this.sourcePlantillasJornadasFiltered;
        this.configPlantillasJornadasListJornadasComponent.dataSourceList = this.sourceJornadasFiltered;
        this.configPlantillasJornadasListJornadasComponent.JornadasXplantillaList = this.configuracionUsuariosSources.SourcePlantillasJornadasXjornada;
      });
    }, 1000);
  }

  datosRefresh() {
    this.createForm();
    this.getConfiguracionSources();

  }

  getPlantillaSelected(dataSelected: SourcePlantillasJornadas) {
    this.newValue = false;
    if (dataSelected) {
      this.idPlantillaJornada = dataSelected.IdPlantillaJornada;
      this.formData.controls.nombre.setValue(dataSelected.NplantillaJornada);
      this.configPlantillasJornadasListJornadasComponent.setCheckValues(dataSelected.IdPlantillaJornada)
    } else {
      this.clearForm();
    }
  }

  nuevaPlantilla() {
    this.clearForm();
  }

  clearForm() {
    this.idPlantillaJornada = null;
    this.formData.controls.nombre.setValue('');
    this.configPlantillasJornadasListJornadasComponent.clearForms();
    this.configPlantillasJornadasListPlantillasComponent.clearForms();
    this.newValue = true;
  }


  getOptionSelected(e) {
  }


  private createForm() {
    this.newValue = true;
    this.formData = this.formBuilder.group({
      nombre: [''],
    });
  }


  async submitForm(accion?: string) {
    const dialogRef = this.dialog.open(ModalConfirmacionComponent, {
      width: '500px',
      height: '200px',
      autoFocus: false,
      data: {
        titulo: accion == 'Eliminar' ? '¿Está seguro que desea eliminar la plantilla seleccionada?' : '¿Desea gardar los cambios realizados?'
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (this.validarDatos()) {
          this.paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
          this.paramEntity.IdUsuario = this.usuario.IdUsuario;
          this.paramEntity.Json = JSON.stringify({
            nombrePlantilla: this.formData.controls.nombre.value,
            IdPlantillaJornada: this.idPlantillaJornada == null ? 0 : this.idPlantillaJornada,
            IdsJornadas: this.configPlantillasJornadasListJornadasComponent.listJornadasChecked,
            accion: accion == 'Eliminar' ? 'Eliminar' : '-'
          });

          this.configuracionService.postAbmPlantillasJornadas(this.paramEntity).subscribe((result: ResponseHelper) => {
            if (result.Ok) {
              this._snackBar.openSnackBar('snack-success', accion == 'Eliminar' ? 'Plantilla eliminada' : 'Se guardó correctamente', 3000);
              this.datosRefresh();
            }
          });
        }

      }
    })
  };

  validarDatos() {
    if (this.formData.controls.nombre.value == null || this.formData.controls.nombre.value.length == 0) {
      this.mostrarMensajeValidacion('Debe ingresar un nombre de plantilla.');
      return false;
    }
    return true;
  }

  private mostrarMensajeValidacion(mensaje: string) {
    this._snackBar.openSnackBar('snack-danger', mensaje, 5000);
  }

  getIdJornadaSelected(ids: number[]) {
    // //console.log(ids);
    // this.paramEntity = ids;
  }

}
