<h1 mat-dialog-title class="bg-primary titulo-modal">Nómina
  <mat-icon class="btn-close" (click)="cerrarModal()">clear</mat-icon>
</h1>
<div mat-dialog-content>

  <div class="row">
    <div class="col">
      <mat-form-field class="filter-input">
        <mat-label>Filtrar</mat-label>
        <input matInput (keyup)="filtrarGrilla($event)" sniperAutofocus>
      </mat-form-field>
    </div>

    <div class="col text-center align-items-center d-flex justify-content-end pr-5">
      <mat-slide-toggle [checked]="true" (change)="getCheckboxSelected($event.checked)">Sólo nómina activa
      </mat-slide-toggle>
    </div>
  </div>




  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="Seccion">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Seccion </th>
      <td mat-cell *matCellDef="let element" class="text-left"> {{element.Seccion}} </td>
    </ng-container>

    <ng-container matColumnDef="IdLegajo">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Id Legajo </th>
      <td mat-cell *matCellDef="let element" class="text-left"> {{element.IdLegajo}} </td>
    </ng-container>

    <ng-container matColumnDef="Apellido">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Apellido </th>
      <td mat-cell *matCellDef="let element" class="text-left"> {{element.Apellido}} </td>
    </ng-container>

    <ng-container matColumnDef="Nombre">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Nombre </th>
      <td mat-cell *matCellDef="let element" class="text-left"> {{element.Nombre}} </td>
    </ng-container>

    <ng-container matColumnDef="Cuil">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Cuil </th>
      <td mat-cell *matCellDef="let element" class="text-center"> {{element.Cuil}} </td>
    </ng-container>

    <ng-container matColumnDef="Fingreso">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Fec. Ingreso </th>
      <td mat-cell *matCellDef="let element" class="text-center"> {{element.FechaIngreso | date: 'dd/MM/yyyy' }} </td>
    </ng-container>

    <ng-container matColumnDef="Fegreso">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Fec. Egreso </th>
      <td mat-cell *matCellDef="let element" class="text-center"> {{ (element.FechaEgreso) ? (element.FechaEgreso |
        date: 'dd/MM/yyyy') : '' }} </td>
    </ng-container>

    <ng-container matColumnDef="antiguedad">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Antigüedad </th>
      <td mat-cell matTooltip="{{ element.AntiguedadStr }}" *matCellDef="let element" class="text-center"> {{
        element.AntiguedadStr }} </td>
    </ng-container>

    <ng-container matColumnDef="Accion">
      <th mat-header-cell *matHeaderCellDef class="text-center"> </th>
      <td mat-cell *matCellDef="let element" (click)="agregarPersona(element)">
        <mat-icon matTooltip="Seleccionar" class="ico-seleccion"
          [ngClass]="{ 'ico-seleccionado': element.Seleccionado }">person_add</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      [ngClass]="{'css_anulado': row.FechaEgreso !== null, hovered: row.hovered, sombreado: row.Seleccionado}"
      (mouseover)="row.Hovered = true" (mouseout)="row.Hovered = false" (click)="getRowData(row)"></tr>

  </table>
  <mat-paginator [pageSizeOptions]="[100, 50, 10]"></mat-paginator>

</div>