import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { Observable } from 'rxjs';
import { datePeriodConverter } from 'src/app/functions/date-period';
import { CmbSector } from 'src/app/models/general/cmb-sector.model';
import { Filters } from 'src/app/models/general/filters.model';
import { ParamEntity } from 'src/app/models/general/param.model';
import { tcontroldetParams } from 'src/app/models/general/tcontrolDet.entity';
import { FileService } from 'src/app/services/file.service';
import { TableroService } from 'src/app/services/tablero.service';
import { SenderService } from 'src/app/services/utils/sender.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-filter-card',
  templateUrl: './filter-card.component.html',
  styleUrls: ['./filter-card.component.scss']
})
export class FilterCardComponent implements OnInit {
  /*INPUT OPTIONS*/
  @Input() hasExportExcelButton: boolean;
  @Input() hasLegajoInput: boolean;
  @Input() filterEmitInit = true;
  @Input() exportExcelButtonDisabled = false;
  @Input() showRangePicker = false;
  @Input() allowOneMoreDay = false;
  @Input() hasRangeMaxDate;

  /*OUTPUT OPTIONS*/
  @Output() filterEmit = new EventEmitter<Filters>();
  @Output() sectorListEmit = new EventEmitter<CmbSector[]>();
  @Output() printExcelEmit = new EventEmitter();
  @Output() valuesRangeEmit = new EventEmitter<{ start, end }>();

  /*VARIABLES*/
  date = new UntypedFormControl(moment());
  maxDate = new Date();
  minDate: Date;
  maxRangeDate: Date;
  idSeccion = 0;
  cmbSectorList: CmbSector[] = [];

  periodoConverted: string;
  idSector: number;
  filteredPeriod: string;
  pdfReq = true;
  legajo: number;
  loadingFile: boolean;
  dataSended$ = new Observable<ParamEntity<any>>();

  // DTP Range
  start = new UntypedFormControl({ value: null, disabled: true })
  end = new UntypedFormControl({ value: '', disabled: true });

  constructor(
    private userValuesService: UserValuesService,
    private tableroService: TableroService,
    private fileService: FileService,
    private _snackBar: SnackBarService,
    private senderService: SenderService
  ) {
    this.dataSended$ = this.senderService.$objSourceParams;
    this.dataSended$.subscribe((values: ParamEntity<any>) => {
      if (values.ClearTable) {
        this.start = new UntypedFormControl({ value: null, disabled: true })
        this.end = new UntypedFormControl({ value: null, disabled: true });
      }
    });
  }

  ngOnInit() {
    this.getMindate();
    this.getSectorList();
    if (this.filterEmitInit) {
      this.filter();
    }
    if (this.allowOneMoreDay && this.maxDate.getDate() >= 25) {
      this.maxDate = new Date();
      this.maxDate.setDate(24);
      this.maxDate.setMonth(this.maxDate.getMonth() + 1);
      this.date.setValue(moment({ year: this.maxDate.getMonth() == 11 ? this.maxDate.getFullYear() : this.maxDate.getFullYear(), month: this.maxDate.getMonth(), day: 1 }));
    }
    this.checkRangeStartDateChanged();
  }

  private getMindate() {
    let paramEntity = new ParamEntity<any>();
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    this.tableroService.getMindateService(paramEntity).subscribe((result: Date) => {
      if (result) {
        this.minDate = new Date(result);
      }
    });
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }


  desdeChanged() {
    let fecha_inicio = new Date(new Date(this.start.value));
    this.end.setValue(fecha_inicio);
  }

  filter() {
    var periodResult: any;
    if (this.showRangePicker) {
      // PARA MEJORAR
      // let startInitDate = this.calcularFechaMaximaRango(new Date(), true, true)
      // let endInitDate = this.calcularFechaMaximaRango(new Date(), true);

      if ((!this.start.value && !this.end.value)) {
        return;
      }

      const start = this.start.value.toDate();
      const end = moment.isMoment(this.end.value) ? this.end.value.toDate() : this.end.value;
      periodResult = { desde: start, hasta: end };

    } else {
      periodResult = datePeriodConverter(this.date.value.toDate(), true);
    }
    this.periodoConverted = periodResult;
    let idSector = 0;
    if (this.idSeccion) {
      idSector = this.idSeccion;
    }
    const emitObj: Filters = {
      periodo: this.periodoConverted,
      idSector,
      legajo: this.legajo
    };
    this.filterEmit.emit(emitObj);
  }

  private getSectorList() {
    let paramEntity = new ParamEntity<any>();
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    paramEntity.Periodo = this.periodoConverted;
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    // this.toggleSpinner(true);
    this.tableroService.getSectoresService(paramEntity).subscribe((result: CmbSector[]) => {
      if (result) {
        this.cmbSectorList = result;
        this.sectorListEmit.emit(this.cmbSectorList);
      }
    });
  }

  printFile(soloDescarga: boolean) {
    this.printExcelEmit.emit(true);
  }

  private checkRangeStartDateChanged() {
    this.start.valueChanges.subscribe((value) => {
      if (value) {
        this.maxRangeDate = this.calcularFechaMaximaRango(value.toDate());
        let fecha_inicio = new Date(new Date(this.start.value));
        this.end.setValue(fecha_inicio);
      }
    });


    this.end.valueChanges.subscribe((value) => {
      if (value && this.start.value) {
        this.valuesRangeEmit.emit({ start: this.start.value.toDate(), end: moment.isMoment(value) ? value.toDate() : value });
      }
    })
  }

  calcularFechaMaximaRango(date, mesActual = false, primerDia = false) {
    const month = date.getMonth();
    const year = date.getFullYear();
    let fechaResult = new Date(year, month + 2, 0); // OBTENGO EL ULTIMO DIA DEL MES SIGUIENTE
    if (mesActual) {
      fechaResult = primerDia ? new Date(year, month, 1) : new Date(year, month + 1, 0);
    }
    return fechaResult;
  }

}
