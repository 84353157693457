import { Injectable } from '@angular/core';
import { HubConnectionBuilder } from '@aspnet/signalr';
import { Observable, Subject } from 'rxjs';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UserHubService {

  hubConnection: any;
  private storageKey = 'userHubConnectionId';
  private reconnectionCheckActiva: boolean = false;
  private lUsername: string;
  private connectionStatusSubject = new Subject<{ id: string, status: string, username: string, connectedAt: Date }>();
  connectionStatus: Observable<{ id: string, status: string, username: string, connectedAt: Date }> = this.connectionStatusSubject.asObservable();

  private connectionInfoSubject = new Subject<{ id: string, status: string, username: string, connectedAt: Date }[]>();
  connectionInfo: Observable<{ id: string, status: string, username: string, connectedAt: Date }[]> = this.connectionInfoSubject.asObservable();

  private printedErrorsCache: Set<string> = new Set<string>();
  private printedMessagesCache: Set<string> = new Set<string>();

  private checkConnectionInterval: any;

  constructor(
    private _userValuesService: UserValuesService,
  ) {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl('/userHub')
      .build();

    this.hubConnection.onclose(error => {
      if (error) {
        this.printErrorOnce(`Desconexión del UserHub SignalR con error: ${error}`);
      } else {
        this.printMessageOnce('Desconexión exitosa del UserHub SignalR');
      }

      this.connectionStatusSubject.next({ id: 'user-hub-id', status: 'closed', username: this.lUsername, connectedAt: new Date() });

      this.startReconnectionCheck();
    });

    this.hubConnection.on('ConnectionStatus', (data: { id: string, status: string, username: string, connectedAt: Date }[]) => {
      this.connectionInfoSubject.next(data);
    });
  }

  startConnection(username: string): Promise<void> {
    if (!this.isConnectionOpen()) {
      return new Promise<void>((resolve, reject) => {
        this.hubConnection.start().then(() => {
          this.hubConnection.invoke('SetUsername', username);
          this.lUsername = username.toLowerCase();

          this.hubConnection.invoke('GetConnectionId').then((connectionId: string) => {
            this._userValuesService.setUserHubId(connectionId);
            this.setStoreConnectionId(connectionId);
          });

          this.connectionStatusSubject.next({ id: 'user-hub-id', status: 'connected', username: this.lUsername, connectedAt: new Date() });

          resolve();
        }).catch(error => {
          this.printErrorOnce('Error al iniciar la conexión: ' + error);
          reject(error);
        });
      });
    } else {
      this.printMessageOnce('La conexión con UserHub ya está abierta o en proceso de apertura.');
      return Promise.resolve();
    }
  }

  private isConnectionOpen(): boolean {
    return this.hubConnection.connection.connectionState === 1;
  }

  closeConnection(connectionId?: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.hubConnection && this.hubConnection.connection.connectionState === 1) {
        const stopConnection = () => {
          this.hubConnection.stop()
            .then(() => {
              this.connectionStatusSubject.next({ id: 'user-hub-id', status: 'closed', username: this.lUsername, connectedAt: new Date() });
              this.clearStoreConnectionId();
              resolve();
            })
            .catch(error => {
              this.printErrorOnce('Error al detener la conexión con UserHub: ' + error);
              reject(error);
            });
        };

        if (connectionId) {
          this.hubConnection.invoke('CloseConnection', connectionId)
            .then(stopConnection)
            .catch(error => {
              this.printErrorOnce(`Error al cerrar la conexión ${connectionId}: ${error}`);
              reject(error);
            });
        } else {
          this.hubConnection.stop()
            .then(stopConnection)
            .catch(error => {
              this.printErrorOnce('Error al detener la conexión con UserHub: ' + error);
              reject(error);
            });
        }
      } else {
        resolve();
      }
    });
  }

  private setStoreConnectionId(connectionId: string): void {
    localStorage.setItem(this.storageKey, connectionId);
  }

  private clearStoreConnectionId(): void {
    localStorage.removeItem(this.storageKey);
  }

  public startReconnectionCheck(): void {
    if (!this.reconnectionCheckActiva) {
      this.checkConnectionInterval = setInterval(() => {
        this.checkAndReconnect();
        this.reconnectionCheckActiva = true;
      }, 3000);
    }
  }
  
  public stopReconnectionCheck(): void {
    if (this.reconnectionCheckActiva) {
      console.log('stopReconnectionCheck')
      clearInterval(this.checkConnectionInterval);
      this.reconnectionCheckActiva = false;
    }
  }
  
  private checkAndReconnect(): void {
    const storedConnectionId = localStorage.getItem(this.storageKey);
    if (storedConnectionId) {
      this.isConnectionStatus(storedConnectionId)
        .then(status => {
          if (status === "closed") {
            // Verificar si la conexión está cerrada antes de intentar reconectar
            if (!this.isConnectionOpen()) {
              this.startConnection(this._userValuesService.getUsuarioValues.NomUsuario.toLowerCase() + ' (Reconnected)')
                .then(() => console.log('Reconectado con el UserHub exitosamente.'))
                .catch(error => this.printErrorOnce('Error al intentar reconectar con UserHub: ' + error));
            }
          }
        })
        .catch(error => this.printErrorOnce('Error al verificar la conexión con UserHub: ' + error));
    }
  }

  isConnectionStatus(connectionId: string): Promise<string> {
    if (this.isConnectionOpen()) {
      return this.hubConnection.invoke('IsConnectionStatus', connectionId);
    } else {
      return Promise.resolve("closed");
    }
  }

  private printErrorOnce(error: string): void {
    if (!this.printedErrorsCache.has(error)) {
      console.error(error);
      this.printedErrorsCache.add(error);
    }
  }

  private printMessageOnce(message: string): void {
    if (!this.printedMessagesCache.has(message)) {
      console.log(message);
      this.printedMessagesCache.add(message);
    }
  }

}
