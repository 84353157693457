import { ParamEntity } from 'src/app/models/general/param.model';
import { ResponseHelper } from './../../../models/sistema/responseHelper';
import { JornadaHabitual } from './../../../models/rrhh/novedades/jornada-habitual.model';
import { Component, OnInit, Inject } from '@angular/core';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { NovedadesService } from 'src/app/services/rrhh/novedades/novedades.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { CmbEntity } from 'src/app/models/general/cmbEntity.model';
import { ModalConfirmacionComponent } from '../modal-confirmacion/modal-confirmacion.component';
import { UntypedFormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-jornadas-habituales',
  templateUrl: './modal-jornadas-habituales.component.html',
  styleUrls: ['./modal-jornadas-habituales.component.scss']
})
export class ModalJornadasHabitualesComponent implements OnInit {
  diasList = [
    { id: 0, Header: 'Lunes', value: null, error: false },
    { id: 0, Header: 'Martes', value: null, error: false },
    { id: 0, Header: 'Miércoles', value: null, error: false },
    { id: 0, Header: 'Jueves', value: null, error: false },
    { id: 0, Header: 'Viernes', value: null, error: false },
    { id: 0, Header: 'Sábado', value: null, error: false },
    { id: 0, Header: 'Domingo', value: null, error: false }
  ];
  objeto = new JornadaHabitual();
  lstJornadas: CmbEntity[] = [];
  controlsList = new UntypedFormControl();
  filteredOptions: Observable<any[]>;
  canSubmit = true;

  constructor(
    public dialogRef: MatDialogRef<ModalJornadasHabitualesComponent>,
    private userValuesService: UserValuesService,
    private novedadesService: NovedadesService,
    public dialog: MatDialog,
    private _snackBar: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.objeto = data.obj;
  }

  ngOnInit() {
    this.loadJornadasCmb();
    this.getIdObjeto();
  }

  getIdObjeto() {
    let idList: { key, value }[] = [];
    idList.push({ key: this.objeto.NjLunes, value: this.objeto.IdJlunes });
    idList.push({ key: this.objeto.NjMartes, value: this.objeto.IdJmartes });
    idList.push({ key: this.objeto.NjMiercoles, value: this.objeto.IdJmiercoles });
    idList.push({ key: this.objeto.NjJueves, value: this.objeto.IdJjueves });
    idList.push({ key: this.objeto.NjViernes, value: this.objeto.IdJviernes });
    idList.push({ key: this.objeto.NjSabado, value: this.objeto.IdJsabado });
    idList.push({ key: this.objeto.NjDomingo, value: this.objeto.IdJdomingo });

    for (let index = 0; index < this.diasList.length; index++) {
      this.diasList[index].id = idList[index].value;
      this.diasList[index].value = idList[index].key;
    }
  }

  cerrarModal() {
    this.dialogRef.close(true);
  }

  subscribeEventChange(value, item) {
    const value$ = of(value);
    return value$
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.Detalle),
        map(value => this._filter(value, item))
      );
  }

  private _filter(value, item): any[] {
    const filterValue = value.toLowerCase();
    const arrResult = this.lstJornadas.filter(option => option.Detalle.toLowerCase().includes(filterValue));
    //console.log(arrResult)
    if (item) {
      item.error = !value || (!arrResult.length && !this.lstJornadas.some(option => option.Detalle.toLowerCase().includes(filterValue)));
    }
    return arrResult;
  }

  getFilteredList(value, item = null) {
    //console.log(value)
    //console.log(item)

    this.filteredOptions = this.subscribeEventChange(value, item);
  }

  loadJornadasCmb() {

    let params = [];
    params.push(null);
    params.push(this.userValuesService.getUsuarioValues.IdEmpresa);
    params.push(this.userValuesService.getUsuarioValues.IdUsuario);
    this.novedadesService.getListJornadasUser(params).subscribe((result: CmbEntity[]) => {
      this.lstJornadas = result;
      this.getFilteredList('');
    });

    //this.novedadesService.getListJornadas().subscribe((result: CmbEntity[]) => {
    //  this.lstJornadas = result;
    //});
  }

  guardarJornada() {
    const paramEntity = new ParamEntity();
    this.objeto.IdJlunes = this.diasList[0].id;
    this.objeto.IdJmartes = this.diasList[1].id;
    this.objeto.IdJmiercoles = this.diasList[2].id;
    this.objeto.IdJjueves = this.diasList[3].id;
    this.objeto.IdJviernes = this.diasList[4].id;
    this.objeto.IdJsabado = this.diasList[5].id;
    this.objeto.IdJdomingo = this.diasList[6].id;
    paramEntity.GenericEntity = this.objeto;
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    paramEntity.IdLegajo = this.objeto.IdLegajo;

    this.novedadesService.guardarJornadaHabitual(paramEntity).subscribe((result: ResponseHelper) => {
      if (result.Ok) {
        this._snackBar.openSnackBar('snack-success', 'Jornada guardada correctamente', 3000);
      } else {
        this._snackBar.openSnackBar('snack-danger', result.Mensaje, 3000);
      }
    }, (error) => { this._snackBar.openSnackBar('snack-danger', error.error, 3000); });
  }

  openModalData() {
    const dialogRef = this.dialog.open(ModalConfirmacionComponent, {
      width: '500px',
      height: '120px',
      autoFocus: false,
      data: {
        titulo: '¿Desea modificar las jornadas habituales?'
      }
    });
    dialogRef.beforeClosed().subscribe((result) => {
      if (result) {
        this.guardarJornada();
      }
    });
  }

  displayFn(item): string {
    return item && item.Detalle ? item.Detalle : item;
  }

  validateList() {
    return this.diasList.some(x => x.error);
  }

}

export interface DialogData {
  titulo: string;
  obj: any;
}
