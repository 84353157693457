<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button mat-icon-button [matMenuTriggerFor]="mobileMenu" class="mobile-menu">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #mobileMenu="matMenu">
      <button [ngClass]="{'hide-menu-icon': !item.submenu}" mat-menu-item *ngFor="let item of navMenuList"
        [routerLink]="item.link ? [item.link] : []" [matMenuTriggerFor]="item.submenu ? subMenuMobile : placeholder">
        {{ item.header }}

        <mat-menu #placeholder="matMenu" class="d-none"></mat-menu>
        <mat-menu #subMenuMobile="matMenu">
          <button mat-menu-item class="hide-menu-icon" *ngFor="let submenuItem of item.submenu"
            [routerLink]="submenuItem.link ? [submenuItem.link] : []">
            {{ submenuItem.header }}
          </button>
        </mat-menu>
      </button>
    </mat-menu>


    <span class="menu-spacer"></span>
    <div class="desktop-menu">
      <a href="javascript:void(0)" mat-button [matMenuTriggerFor]="desktopMenu" *ngFor="let item of navMenuList"
        [routerLink]="item.link ? [item.link] : []">
        {{ item.header }}
        <mat-icon *ngIf="item.submenu" class="btn-menu-icon">chevron_right</mat-icon>

        <mat-menu #desktopMenu="matMenu">
          <button mat-menu-item *ngFor="let submenuItem of item.submenu"
            [routerLink]="submenuItem.link ? [submenuItem.link] : []">
            {{ submenuItem.header }}
          </button>
        </mat-menu>
      </a>

    </div>

    <app-close-session-button></app-close-session-button>
  </mat-toolbar-row>
</mat-toolbar>