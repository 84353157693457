import { Component, OnDestroy, OnInit } from '@angular/core';
import { Filters } from 'src/app/models/general/filters.model';
import { ParamEntity } from 'src/app/models/general/param.model';
import { ReporteService } from 'src/app/services/reporte.service';
import { SenderService } from 'src/app/services/utils/sender.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';

@Component({
  selector: 'app-calendarizados',
  templateUrl: './calendarizados.component.html',
  styleUrls: ['./calendarizados.component.scss']
})
export class CalendarizadosComponent implements OnInit, OnDestroy {
  paramEntity = new ParamEntity<any>();
  filters = new Filters();

  constructor(
    private userValuesService: UserValuesService,
    private senderService: SenderService,
    private reporteService: ReporteService,
    private _snackBar: SnackBarService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.paramEntity.ClearTable = true;
    this.senderService.enviarObjetoFiltros(this.paramEntity);
  }

  filter(event?: Filters, fromMultipleFilters = true, clearTable = false) {
    this.paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    this.paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    if (fromMultipleFilters) {
      this.paramEntity.Periodo = '';
      this.paramEntity.PeriodoDesde = event.periodo.desde;
      this.paramEntity.PeriodoHasta = event.periodo.hasta;
      this.paramEntity.IdSeccion = event.idSector;
      this.paramEntity.IdLegajo = event.legajo;
    } else {
      this.paramEntity.SpName = event.reporte.SpName;
    }

    this.paramEntity.ClearTable = clearTable;

    this.paramEntity = { ...this.paramEntity };
    this.senderService.enviarObjetoFiltros(this.paramEntity);
  }

  printExcel($event) {
    setTimeout(() => {
      this._snackBar.openSnackBar('snack-warning', 'Generando archivo. Por favor espere.');
    }, 1000);
    this.reporteService.getExcelFileService(this.paramEntity).subscribe((res) => {
      if (res) {
        setTimeout(() => {
          this._snackBar.dismissSnackbar();
        }, 3000);
      }
    });
  }

}
