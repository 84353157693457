import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatListOption, MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { SourcePlantillasNomina } from 'src/app/models/sources/sourcePlantillasNomina';


@Component({
  selector: 'app-config-plantillas-nomina-list-plantillas',
  templateUrl: './config-plantillas-nomina-list-plantillas.component.html',
  styleUrls: ['./config-plantillas-nomina-list-plantillas.component.scss']
})
export class ConfigPlantillasNominaListPlantillasComponent implements OnInit {
  @Input() dataSourceList: SourcePlantillasNomina[];
  control = new UntypedFormControl();


  @ViewChild('matSelection', { static: true }) matSelection: MatSelectionList;
  elementsSelected: { value; selected }[];
  @Output() elementSelectedEmit = new EventEmitter();
  @Output() editElementEmit = new EventEmitter();

  constructor(
  ) { }

  ngOnInit() {
    // this.dataShareService.selectedElement$.subscribe((selected: any[]) => {
    //   if (selected) {
    //     this.elementsSelected = selected;
    //   }
    // });
    this.matSelection.selectedOptions = new SelectionModel<MatListOption>(false);
  }

  getSelected(e: MatSelectionListChange) {
    const element = e.options[0].selected ? e.options[0].value : null;
    this.elementSelectedEmit.emit(element);
  }

  setCheckValues(idPlantilla: number) {
    this.matSelection.options.forEach(x => {
      if (x.value.IdPlantillaNomina == idPlantilla) {
        x._setSelected(true);
      } else {
        x._setSelected(false);
      }
    })
  }

  clearForms() {
    this.matSelection.deselectAll();
    this.elementSelectedEmit.emit([]);
  }

}
