import { Nomina } from "../rrhh/nomina.model";
import { Reporte } from "../rrhh/reporte.model";

export class Filters {
  periodo?: any;
  idSector?: number;
  legajo?: number;
  reporte?: Reporte;
  isPeriodoSeleccionado?: boolean;
  empleadoSelected?: Nomina;
  periodoSelect?: any;
}
