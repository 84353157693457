export class TableroDetail {
  TableroItem: TableroCard;
  TableroFilters: TableroFilters;
}

interface TableroFilters {
  Periodo: any;
  IdSeccion: number;
  Seccion: string;
}

export class TableroCard {
  IdControl: number;
  Ncontrol: string;
  ControlDesc: string;
  Orden: number;
  Valor1: any;
  Valor2: any;
  IdControlTipo: any;
  disabled: boolean;
  Url: any;
  DownFilename: any;
  PorcentajeMesAnterior: any;
  DetalleMesAnterior: any;
  MuestraComparativos: any;
  MuestraDotacionTeorica: any;
  DotacionTeorica: any;
  EstadoObjeto: any;
  ActivarAlerta: boolean;
  MensajeAlerta: string;
}
