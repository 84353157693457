<div class="container-fluid p-3">
  <div class="row">
    <div class="col-md-6 mx-auto text-center text-white">
      <app-alert [titulo]="'Informes'"></app-alert>
    </div>
  </div>
  <div class="row my-4">
    <div class="col-12 p-1">
      <mat-card class="card-bg card-filters" [ngClass]="{'ver-mas': verMas}">
        <p class="mb-0">Filtros</p>
        <hr class="line">
        <div class="container-fluid">
          <div class="row mt-2">
            <div class="col-3">
              <mat-form-field #reportList appearance="outline" class="w-100 select-form-field">
                <mat-label>Reportes</mat-label>
                <mat-select [(ngModel)]="selectedReporte" (selectionChange)="getReporteSelected($event)">
                  <mat-option *ngFor="let reporte of reportesList" [matTooltip]="reporte.NReporte" [value]="reporte">
                    {{ reporte.NReporte }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>
            <div class="col-2">
              <mat-form-field appearance="outline" class="mat-form-field-date w-100">
                <mat-label>Desde</mat-label>
                <input (dateChange)="selectPeriodoDesde($event)" matInput [formControl]="desdeFc"
                  [matDatepicker]="dpStart">
                <mat-datepicker-toggle matSuffix [for]="dpStart"></mat-datepicker-toggle>
                <mat-datepicker #dpStart panelClass="example-month-picker">
                </mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-2">
              <mat-form-field appearance="outline" class="mat-form-field-date w-100">
                <mat-label>Hasta</mat-label>
                <input (dateChange)="selectPeriodoHasta($event)" matInput [formControl]="hastaFc"
                  [matDatepicker]="dpEnd" [min]="minDate">
                <mat-datepicker-toggle matSuffix [for]="dpEnd"></mat-datepicker-toggle>
                <mat-datepicker #dpEnd panelClass="example-month-picker">
                </mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-2">
              <mat-form-field appearance="outline" class="seccion select-form-field w-100">
                <mat-label>Sección</mat-label>
                <mat-select (selectionChange)="getSeccionSelected($event)">
                  <mat-option *ngFor="let item of cmbSectorList" [matTooltip]="item.Nsector" [value]="item.IdSector">
                    {{ item.Nsector }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-3">
              <button mat-raised-button color="primary" class="ml-4 mt-1" (click)="obtenerInforme($event)"
                [disabled]="!jsonValues.pNReporte">Generar Informe</button>
            </div>
          </div>
        </div>
        <hr class="line">
        <div class="ver-mas-wrapper">
          <div *ngIf="verMas" class="arrow-up"></div>
          <div *ngIf="!verMas" class="arrow-down"></div>
          <a href="javascript:void(0)" (click)="setVerMas()" class="btn-ver-mas">
            <p class="mt-1">{{ verMas ? 'Menos filtros' : 'Más filtros' }}</p>
          </a>
        </div>
        <div class="container-fluid mt-1" *ngIf="verMas">
          <div class="row">
            <div class="col">
              <div class="d-flex align-items-center legajo-wrapper">
                <mat-slide-toggle [(ngModel)]="seleccionaLegajo" (change)="setSeleccionaLegajo($event)">Colaborador
                  específico</mat-slide-toggle>
                <button *ngIf="seleccionaLegajo" mat-mini-fab class="btn-open-nomina ml-5" color="primary"
                  matTooltip="Buscar colaborador" (click)="openModalNomina()">
                  <mat-icon>search</mat-icon>
                </button>
                <mat-chip-list *ngIf="nomina && seleccionaLegajo" class="ml-3">
                  <mat-chip class="mx-auto position-relative">
                    <span>{{ (nomina.IdLegajo) ? nomina.IdLegajo + ' - ' + nomina.Apellido + ' ' + nomina.Nombre : '-'
                      }}</span>
                  </mat-chip>
                </mat-chip-list>

              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-slide-toggle [(ngModel)]="esNominaActiva" (change)="setNominaActiva()">Solo nómina
                activa</mat-slide-toggle>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
