import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ParamEntity } from 'src/app/models/general/param.model';
import { Configuracion } from 'src/app/models/rrhh/configuracion.model';
import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { ConfiguracionService } from 'src/app/services/configuracion.service';
import { SenderService } from 'src/app/services/utils/sender.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';

@Component({
  selector: 'app-configuraciones',
  templateUrl: './configuraciones.component.html',
  styleUrls: ['./configuraciones.component.scss']
})
export class ConfiguracionesComponent implements OnInit {
  configuracionList: Array<Configuracion>;
  result: ResponseHelper;

  constructor(
    private configuracionService: ConfiguracionService,
    private userValuesService: UserValuesService,
    private senderService: SenderService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getConfiguracionesGeneral();
  }

  private getConfiguracionesGeneral() {
    const paramEntity = new ParamEntity();
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    this.configuracionService.getAbmGeneralService(paramEntity).subscribe((result: ResponseHelper) => {
      if (result) {
        this.result = result;
        this.configuracionList = result.DataResponse;
      }
    });
  }

  redirigirAbm(item: Configuracion) {
    if (item.MenuEnabled) {
      this.senderService.enviarObjetoConfiguracion(item);
      this.router.navigateByUrl(item.Url);
    }
  }

}
