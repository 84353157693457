import { AfterViewChecked, AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { default as _rollupMoment, Moment } from 'moment';
import * as _moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Filters } from 'src/app/models/general/filters.model';
import { NovedadesService } from 'src/app/services/rrhh/novedades/novedades.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { ParamEntity } from 'src/app/models/general/param.model';
import { ReporteService } from 'src/app/services/reporte.service';
import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { getKeyValue } from 'src/app/functions/key-value';
import { ModalDetalleHsExtrasComponent } from '../modals/modal-detalle-hs-extras/modal-detalle-hs-extras.component';
import { FileService } from 'src/app/services/file.service';
import { ExcelJsonParametersRequest } from './../../models/general/excelJsonParametersRequest';
import { MatTableDataSource, MatTable, MatRow } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { startWith } from 'rxjs/operators';


const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const ELEMENT_DATA: any[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
];

@Component({
  selector: 'app-hs-extras',
  templateUrl: './hs-extras.component.html',
  styleUrls: ['./hs-extras.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
  ]
})
export class HsExtrasComponent implements OnInit, AfterViewInit {
  dataSource = new MatTableDataSource<any>([]);
  columnsDef: string[];
  contentLoaded = false;
  paramEntity = new ParamEntity<any>();
  displayedColumns: string[];
  @ViewChild('detallePaginator', { static: true }) paginator: MatPaginator;
  onLoad = false;

  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatSort, { static: true }) sorter: MatSort;
  @ViewChildren(MatRow) rows: QueryList<any>;

  constructor(
    private novedadesService: NovedadesService,
    private userValuesService: UserValuesService,
    private _snackBar: SnackBarService,
    private dialog: MatDialog,
    private fileService: FileService,
  ) { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.onLoad = true;
  }

  ngAfterViewInit() {
  }

  filter(event: Filters, fromMultipleFilters = true) {
    this.paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    this.paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    if (fromMultipleFilters) {
      let fechaActual = new Date();
      if (fechaActual.getDate() >= 25) {
        let fechaCustom = new Date(fechaActual.getMonth() == 11 ? (fechaActual.getFullYear() + 1) : (fechaActual.getFullYear()), (fechaActual.getUTCMonth() + 1), 1);
        let añoCustom = fechaCustom.getFullYear().toString();
        let mesCustom = (fechaCustom.getUTCMonth() + 1).toString().padStart(2, '0');
        let periodoCustom = añoCustom + mesCustom;
        if (event.periodo == periodoCustom || this.onLoad) {
          this.paramEntity.Periodo = periodoCustom;
          if (this.onLoad) {
            this.onLoad = false;
          }
        } else {
          this.paramEntity.Periodo = event.periodo;
        }

      } else {
        this.paramEntity.Periodo = event.periodo;
      }

      this.paramEntity.IdSeccion = event.idSector;
      this.paramEntity.IdLegajo = event.legajo;
      this.paramEntity.SpName = 'SP_LIQ_HS_EXTRAS_AUT_RES_GET';
    }
    this.getTableData();
  }

  private getTableData() {
    const paramEntity = new ParamEntity();
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    paramEntity.IdSeccion = this.paramEntity.IdSeccion;
    paramEntity.Periodo = this.paramEntity.Periodo;

    paramEntity.SpName = this.paramEntity.SpName;
    this.novedadesService.getLiqHsExtras(paramEntity).subscribe((result: any[]) => {
      if (result) {
        this.displayedColumns = getKeyValue(result)[0];
        this.displayedColumns.unshift('accion');
        this.dataSource.data = result;
      }
    },
      (error) => { this._snackBar.openSnackBar('snack-danger', 'Hubo un error', 3000); },
      () => {
        this.hideSpinner();
      });

  }

  hideSpinner() {
    this.rows.changes.pipe(startWith([undefined])).subscribe(() => {
      if (this.allRowsRendered()) {
        setTimeout(() => {
          this.contentLoaded = true;
        }, 2000);
      }
    });
  }


  printFile(soloDescarga: boolean, url?, fileName?) {
    const paramEntity = { ...this.paramEntity };
    paramEntity.Json = JSON.stringify({
      "pSpName": 'SP_LIQ_HS_EXTRAS_AUT_RES_GET',
      "pIdUsuario": this.userValuesService.getUsuarioValues.IdUsuario,
      "pIdSeccion": this.paramEntity.IdSeccion,
      "pPeriodo": this.paramEntity.Periodo
    })

    let params = new ExcelJsonParametersRequest;
    params.pJsonParameters = paramEntity.Json;
    params.fileName = "Hs. Extras (Resumen).xlsx";

    // SI ES TRUE, USO TIMEOUT PARA QUE NO SE PISE CON EL MENSAJE ANTERIOR
    setTimeout(() => {
      this._snackBar.openSnackBar('snack-warning', 'Generando archivo. Por favor espere.');
    }, 1000);

    this.fileService.downloadXlsxExcel_StoredProcedure(params).subscribe((resultDownload) => {
      if (resultDownload) {
        if (!soloDescarga) {
          // SI SE DESCARGA CORRECTAMENTE LIMPIO TODO Y ACTUALIZO LOS DATOS
          //console.log("Descarga Ok");
        }
        // USO EL TIMEOUT DE NUEVO PARA ELIMINAR EL MENSAJE ANTERIOR
        setTimeout(() => {
          this._snackBar.dismissSnackbar();
        }, 3000);

      } else {
        this._snackBar.openSnackBar('snack-danger', 'Hubo un error al intentar descargar el archivo', 3000);
      }
      //this.loadingFile = false; // VUELVO A HABILITAR LOS CONTROLER
    }, (errorDescarga) => {
      this._snackBar.openSnackBar('snack-danger', errorDescarga, 5000);
      //this.loadingFile = false; // VUELVO A HABILITAR LOS CONTROLER
    });
  }


  applyFilter(event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  isSticky(col, element) {
    var style;

    if (element) {
      // SE APLICA CLASE A LAS COLUMNAS FIJAS
      if (col === 'IMPARES' && Number(element.IMPARES) > 0) { // ESTILO PARA LA COL IMPARES CON CONDICION
        style = style + ' ' + 'bg-celda';
      }
      if (col === 'SIN_INCIDENCIA' && Number(element.SIN_INCIDENCIA) > 0) { // ESTILO PARA LA COL SIN_INCIDENCIA CON CONDICION
        style = style + ' ' + 'bg-celda';
      }


      if ((col === 'HS_50_AUT' || col === 'HS_50_CALC') && Number(element.HS_50_AUT) == 0 && Number(element.HS_50_CALC) == 0) {
        return 'SinHsExtras';
      }

      if ((col === 'HS_100_AUT' || col === 'HS_100_CALC') && Number(element.HS_100_AUT) == 0 && Number(element.HS_100_CALC) == 0) {
        return 'SinHsExtras';
      }


      if (col === 'HS_50_CALC' && Number(element.HS_50_CALC) != 0) {
        style = style + ' ' + 'NoCero';
      }
      if (col === 'HS_50_AUT' && Number(element.HS_50_AUT) != 0) {
        style = style + ' ' + 'NoCero';
      }

      if (col === 'HS_100_CALC' && Number(element.HS_100_CALC) != 0) {
        style = style + ' ' + 'NoCero';
      }
      if (col === 'HS_100_AUT' && Number(element.HS_100_AUT) != 0) {
        style = style + ' ' + 'NoCero';
      }

      if (col === 'HS_AUSENCIA' && Number(element.HS_AUSENCIA) != 0) {
        style = style + ' ' + 'ConHsAuse';
      }

      if (col === 'HS_AUSENCIA' && Number(element.HS_AUSENCIA) == 0) {
        style = style + ' ' + 'SinHsAuse';
      }


      if ((col === 'HS_50_AUT' || col === 'HS_50_CALC') && Number(element.HS_50_AUT) < Number(element.HS_50_CALC)) {
        style = style + ' ' + 'HsExtrasAutParcial';
      }

      if ((col === 'HS_100_AUT' || col === 'HS_100_CALC') && Number(element.HS_100_AUT) < Number(element.HS_100_CALC)) {
        style = style + ' ' + 'HsExtrasAutParcial';
      }


      if ((col === 'HS_50_AUT' || col === 'HS_50_CALC') && Number(element.HS_50_AUT) == Number(element.HS_50_CALC)) {
        style = style + ' ' + 'HsExtrasOK';
      }

      if ((col === 'HS_100_AUT' || col === 'HS_100_CALC') && Number(element.HS_100_AUT) == Number(element.HS_100_CALC)) {
        style = style + ' ' + 'HsExtrasOK';
      }


      if (col === 'HS_FERIADO_TRAB' && Number(element.HS_FERIADO_TRAB) > 0) {
        style = style + ' ' + 'feriado_trabajado';
      }
      if (col === 'HS_FERIADO_TRAB' && Number(element.HS_FERIADO_TRAB) == 0) {
        style = style + ' ' + 'feriado_no_trabajado';
      }


      return style;
    }
  }
  openModalData(element) {
    // this.row = element;
    this.paramEntity.GenericEntity = element;
    const matConfig: MatDialogConfig = {
      width: '100%',
      maxWidth: '90vw',
      minHeight: '90vh',
      height: 'auto',
      panelClass: ['modal-marcacion', 'modal-insert-config'],
      autoFocus: false,
      data: this.paramEntity
    };
    const dialogRef = this.dialog.open(ModalDetalleHsExtrasComponent, matConfig);
    dialogRef.beforeClosed().subscribe((result) => {
      if (result) {
        this.getTableData();
      }
    });
  }

  allRowsRendered(): boolean {
    return this.rows && this.rows.length > 0;
  }

}
