<h1 mat-dialog-title class="bg-primary titulo-modal">{{ objeto.titulo }}
  <mat-icon class="btn-close" (click)="cerrarModal(null)">clear</mat-icon>
</h1>
<div mat-dialog-content>
  <div class="container h-100">
    <div class="row">
      <div class="col p-3">
        <mat-list [multiple]="false" class="selection-empleados">
          <mat-list-item class="mat-list-item-empleado" role="listitem" *ngFor="let item of objeto.obj">
            {{ ('Leg.' + item.ID_LEGAJO + ' - ' + item.NOMBRE + ' ' + item.APELLIDO) | titlecase }}
          </mat-list-item>
        </mat-list>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <mat-form-field class="w-100">
          <mat-label>Seleccione jornada</mat-label>
          <input type="text" placeholder="" aria-label="Seleccione Jornada" matInput [formControl]="myControl"
            [matAutocomplete]="auto">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="getSeleccionJornada($event)"
            [displayWith]="displayFn">
            <!-- <mat-select [(ngModel)]="objeto.IdJornada" (selectionChange)="getSeleccionJornada($event)"> -->
            <mat-option *ngFor="let item of filteredOptions | async" [matTooltip]="item.Detalle" [value]="item">
              {{ item.Detalle }}
            </mat-option>
            <!-- </mat-select> -->
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field appearance="outline" class="mat-form-field-date d-block">
          <mat-label>Desde</mat-label>
          <input matInput [matDatepicker]="dpStart" [formControl]="start" [min]="objeto.rangeDates.start"
            [max]="objeto.rangeDates.end">
          <mat-datepicker-toggle matSuffix [for]="dpStart"></mat-datepicker-toggle>
          <mat-datepicker #dpStart [disabled]="false"></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-3">
        <mat-form-field appearance="outline" class="mat-form-field-date d-block">
          <mat-label>Hasta</mat-label>
          <input matInput [matDatepicker]="dpEnd" [formControl]="end" [min]="start.value" [max]="objeto.rangeDates.end">
          <mat-datepicker-toggle matSuffix [for]="dpEnd"></mat-datepicker-toggle>
          <mat-datepicker #dpEnd [disabled]="!start.value"></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-2">
        <button mat-mini-fab class="btn-open-detalle mx-3" color="primary" matTooltip="Agregar" (click)="addDate()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-list role="list" class="result-list">
          <mat-list-item role="listitem" *ngFor="let item of dateRows">
            Desde: {{ item.fecha_desde }} - Hasta: {{ item.fecha_hasta }} - Jornada: {{ getJornada(item.idJornada) }}
          </mat-list-item>
        </mat-list>
      </div>
    </div>

  </div>
</div>

<div mat-dialog-actions class="justify-content-end p-5">
  <button mat-raised-button color="primary" class="ml-4" (click)="openModalData()">Guardar</button>
</div>