<h1 mat-dialog-title class="bg-primary titulo-modal">Nómina
  <mat-icon class="btn-close" (click)="cerrarModal()">clear</mat-icon>
</h1>
<div mat-dialog-content>

  <div class="row">
    <div class="col">
      <mat-form-field class="filter-input">
        <mat-label>Filtrar</mat-label>
        <input matInput (keyup)="filtrarGrilla($event)" sniperAutofocus>
      </mat-form-field>
    </div>

    <div class="col text-center align-items-center d-flex justify-content-end pr-5">
      <mat-slide-toggle [checked]="true" (change)="getCheckboxSelected($event.checked)">Sólo nómina activa
      </mat-slide-toggle>
    </div>
  </div>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="IdLegajo">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Id Legajo </th>
      <td mat-cell *matCellDef="let element" class="text-left"> {{element.IdLegajo}} </td>
    </ng-container>

    <ng-container matColumnDef="Apellido">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Apellido </th>
      <td mat-cell *matCellDef="let element" class="text-left" matTooltip="{{ element.Apellido }}"
      [ngClass]="{'css-validacion-dato-incorrecto': element.idLegajoExist && !element.ApellidoCorrecto}"
      > {{element.Apellido}} </td>
    </ng-container>

    <ng-container matColumnDef="Nombre">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Nombre </th>
      <td mat-cell *matCellDef="let element" class="text-left" matTooltip="{{ element.Nombre }}"
      [ngClass]="{'css-validacion-dato-incorrecto': element.idLegajoExist && !element.NombreCorrecto}"
      > {{element.Nombre}} </td>
    </ng-container>

    <ng-container matColumnDef="Cuil">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Cuil </th>
      <td mat-cell *matCellDef="let element" class="text-center"
      [ngClass]="{'css-validacion-dato-incorrecto': element.idLegajoExist && !element.CuilCorrecto}"
      > {{element.Cuil}} </td>
    </ng-container>

    <ng-container matColumnDef="Sucursal">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Sucursal </th>
      <td mat-cell *matCellDef="let element" class="text-center" matTooltip="{{ element.Sucursal }}"> {{element.Sucursal}} </td>
    </ng-container>

    <ng-container matColumnDef="Area">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Area </th>
      <td mat-cell *matCellDef="let element" class="text-center"  matTooltip="{{ element.Area }}"> {{element.Area}} </td>
    </ng-container>

    <ng-container matColumnDef="IdLegajoLiquidacion">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Id Legajo Liquidacion </th>
      <td mat-cell *matCellDef="let element" class="text-left"> {{element.IdLegajoLiquidacion}} </td>
    </ng-container>

    <ng-container matColumnDef="FechaIngreso">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Fecha ingreso </th>
      <td mat-cell *matCellDef="let element" class="text-left"
      [ngClass]="{'css-validacion-dato-incorrecto': element.idLegajoExist && !element.FechaIngresoCorrecta}"
      > {{element.FechaIngreso}} </td>
    </ng-container>

    <ng-container matColumnDef="FechaEgreso">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Fecha egreso </th>
      <td mat-cell *matCellDef="let element" class="text-left"
      [ngClass]="{'css-validacion-dato-incorrecto': element.idLegajoExist && !element.FechaEgresoCorrecta}"
      > {{element.FechaEgreso}} </td>
    </ng-container>


    <ng-container matColumnDef="Accion" class="accion-column">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Accion </th>
      <td mat-cell *matCellDef="let element" (click)="agregarPersona(element)">
        <mat-icon matTooltip="Seleccionar" class="ico-seleccion"
          [ngClass]="{ 'ico-seleccionado': element.Seleccionado }"
          *ngIf="!element.idLegajoExist">person_add</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      [ngClass]="{'css_anulado': row.FechaEgreso !== null,
                  'css-validacion-datos-correctos': row.SinErrores,
                  'css-validacion-legajo-no-registrado': !row.SinErrores && !row.idLegajoExist,
                  hovered: row.hovered, sombreado: row.Seleccionado}"
      (mouseover)="row.Hovered = true" (mouseout)="row.Hovered = false" (click)="getRowData(row)"></tr>

  </table>
  <mat-paginator [pageSizeOptions]="[100, 50, 10]"></mat-paginator>

</div>
