<h1 mat-dialog-title class="bg-primary titulo-modal">Detalle
  <mat-icon class="btn-close" (click)="cerrarModal()">clear</mat-icon>
</h1>
<div mat-dialog-content>
  <div class="container-fluid h-100">
    <div class="spinner-container" *ngIf="!contentLoaded">
      <mat-spinner></mat-spinner>
    </div>
    <div class="row">
      <div class="col-3">
        <p>Legajo: {{ dataObj.GenericEntity.ID_LEGAJO }}</p>
        <p>Apellido: {{ dataObj.GenericEntity.APELLIDO | titlecase }}</p>
        <p>Nombre: {{ dataObj.GenericEntity.NOMBRE | titlecase }}</p>
      </div>
      <div class="col-3">
        <p>Sección: {{ dataObj.GenericEntity.SECCION | titlecase }}</p>
        <p>Periodo: {{ dataObj.Periodo }}</p>
      </div>
      <div class="col d-flex justify-content-end align-items-end mb-3">
        <div class="text-center mx-2">
          <button mat-raised-button class="mx-3 btn-gray" (click)="autorizarTodas()">Autorizar todas</button>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-2">
        <mat-form-field class="w-90">
          <mat-label>Filtrar</mat-label>
          <input matInput (keyup)="applyFilter($event)">
        </mat-form-field>
      </div>
    </div>

    <div class="table-container">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-parte-diario">
        <ng-container *ngFor="let col of displayedColumns; let i = index" [matColumnDef]="col" [sticky]="isSticky(col)">
          <div *ngIf="col != undefined && col === 'accion'">
            <th class="border bg-secondary  text-white" mat-header-cell *matHeaderCellDef> </th>
            <td class="border" mat-cell *matCellDef="let element">
              <button mat-mini-fab class="btn-open-detalle mx-3" color="primary" matTooltip="Modificar Novedades"
                (click)="openModalData(element)">
                <mat-icon>event</mat-icon>
              </button>
            </td>
          </div>
          <div *ngIf="col != undefined && col !== 'accion'">
            <th class="border bg-secondary  text-white" mat-header-cell *matHeaderCellDef> {{ formatColText(col) }}
            </th>
            <td class="border" mat-cell *matCellDef="let element; let tdI = index" [ngClass]="isSticky(col, element)">
              <div [ngSwitch]="checkCol(col)">
                <div *ngSwitchCase="'aut'">
                  <mat-form-field class="d-block">
                    <input [id]="'row-' + tdI"
                        matInput
                        type="text"
                        [value]="element[col]"
                        (input)="setInputEnabledTable(element, col, $event)"
                        name="{{col + '-' + i}}"
                        class="text-center" />
                  </mat-form-field>
                </div>

                <div *ngSwitchCase="'sel'">
                  <mat-select [id]="'select-row-' + tdI"
                              [(value)]="element[col]"
                              (selectionChange)="getSelectedOption($event, col, element)"
                              [disabled]="setSelectDisabled(col, element)"
                              [matTooltipDisabled]="showSelectToolTip(col)"
                              [matTooltip]="setSelectToolTip(col, element)"
                              >
                    <mat-option *ngFor="let item of selectOptionsArr(col)" [value]="item.Id">
                      {{ item.Detalle }}
                    </mat-option>
                  </mat-select>
                </div>

                <div *ngSwitchCase="'input'">
                  <mat-form-field class="d-block">
                    <input matInput
                           [id]="'row-' + tdI"
                           type="text"
                           [value]="element[col]"
                           (input)="setInputTable(element, col, $event)"
                           [attr.maxLength]="setInputMaxLength(col)"
                           name="{{col + '-' + i}}"
                           class="text-center"
                           [matTooltipDisabled]="showInputToolTip(col)"
                           [matTooltip]="element[col]"
                           />
                  </mat-form-field>
                </div>

                <div *ngSwitchDefault>
                  <div [ngClass]="getStyle(col, element)" matTooltip="{{ element[col] }}">
                    {{ element[col] }}
                  </div>
                </div>
              </div>
            </td>
          </div>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <!-- <mat-paginator #detallePaginator [pageSizeOptions]="[1000, 500]"></mat-paginator> -->
    </div>

    <div class="row my-3">
      <div class="col-3 ml-auto text-right">
        <button mat-raised-button class="mx-3 btn-gray" (click)="guardar()">Confirmar</button>
      </div>

    </div>
  </div>
</div>
