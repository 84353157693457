import { Component, NgZone, OnInit } from '@angular/core';
import { UserHubService } from 'src/app/services/utils/user-hub.service';

@Component({
  selector: 'app-user-hub-monitor',
  templateUrl: './user-hub-monitor.component.html',
  styleUrls: ['./user-hub-monitor.component.scss']
})
export class UserHubMonitorComponent implements OnInit {

  connections: { id: string, status: string, username: string, connectedAt: Date }[] = [];

  constructor(private userHubService: UserHubService, private ngZone: NgZone) { }

  ngOnInit(): void {
    // Suscribirse a cambios en la información de conexión
    this.userHubService.connectionInfo.subscribe(connectionInfo => {
      this.ngZone.run(() => {
        this.updateConnectionInfo(connectionInfo);
      });
    });

    // Verificar el estado de la conexión y actuar en consecuencia
    if (this.userHubService.hubConnection) {
      if (this.userHubService.hubConnection.connection.connectionState === 1) {
        // Si la conexión está abierta, ejecutar this.getInitialConnectionInfo()
        this.getInitialConnectionInfo();
      } else {
        // Si la conexión está cerrada, iniciar el temporizador después de un breve retraso
        setTimeout(() => {
          this.getInitialConnectionInfo();
        }, 5000);
      }
    }
  }

  private getInitialConnectionInfo(): void {
    // Invocar la función del hub para obtener el listado inicial de conexiones
    this.userHubService.hubConnection.invoke('GetConnections')
      .then((connectionInfo: { id: string, status: string, username: string, connectedAt: Date }[]) => {
        this.ngZone.run(() => {
          this.updateConnectionInfo(connectionInfo);
        });
      })
      .catch(error => {
        console.error('Error al obtener el listado inicial de conexiones:', error);
      });
  }

  private updateConnectionInfo(connectionInfo): void {
    this.connections = connectionInfo;
  }
}
