import { SourcePlantillasNominaXempleados } from './../../../models/sources/sourcePlantillasNominaXempleados';
import { Component, OnInit, ViewChild, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { SourceEmpleados } from 'src/app/models/sources/sourceEmpleados';


@Component({
  selector: 'app-config-plantillas-nomina-list-nomina',
  templateUrl: './config-plantillas-nomina-list-nomina.component.html',
  styleUrls: ['./config-plantillas-nomina-list-nomina.component.scss']
})
export class ConfigPlantillasNominaListNominaComponent implements OnInit {
  control = new UntypedFormControl();
  listEmpleadosChecked: number[] = [];
  @Input() dataSourceList: SourceEmpleados[];
  @Input() EmpleadosXplantillaList: SourcePlantillasNominaXempleados[];

  @Output() optionsDataSelectedEmit = new EventEmitter();

  @ViewChild('matSelection') matSelection: MatSelectionList;

  constructor() {

  }

  ngOnInit() {

  }


  getSelected(e) {
    if (e.options[0]?.selected) {
      this.listEmpleadosChecked.push(e.options[0].value.IdLegajo);
    } else {
      const index = this.listEmpleadosChecked.findIndex(x => x === e.options[0].value.IdLegajo);
      this.listEmpleadosChecked.splice(index, 1);
    }
  }

  setCheckValues(idPlantilla: number, newSource?: SourceEmpleados[]) {
    this.listEmpleadosChecked = [];
    var listEmpleadosxPlantilla: number[] = this.EmpleadosXplantillaList.filter(x => x.IdPlantillaNomina === idPlantilla).map(x => x.IdLegajo);

    this.matSelection.options.forEach(x => {
      if (listEmpleadosxPlantilla.indexOf(x.value.IdLegajo) > -1) {
        x._setSelected(true);
        this.listEmpleadosChecked.push(x.value.IdLegajo);
      } else {
        x._setSelected(false);
      }
    });
  }

  clearForms() {
    this.matSelection.deselectAll();
    this.listEmpleadosChecked = [];
    this.optionsDataSelectedEmit.emit([]);
  }

}
