import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, property?: string[]): any[] {

    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter(it => {
      if (property) {
        let filterStr = '';
        property.forEach((propertyResult) => {
          filterStr += it[propertyResult];
        })

        return filterStr.toLocaleLowerCase().includes(searchText);
      }
      return it.toLocaleLowerCase().includes(searchText);
    });
  }
}