import { formatDate } from "@angular/common";

export function datePeriodConverter(value, monthFirst = false, withSlash = true) {
  const dateFilter = formatDate(value, 'MM/yyyy', 'es-AR').split('/');
  let valueFormatted = dateFilter[1] + '' + dateFilter[0];
  if (!monthFirst) {
    valueFormatted = dateFilter[0] + (withSlash ? '/' : '') + dateFilter[1];
  }
  return valueFormatted;
}

export function dateStrSplitter(value: string, asDate = false): Date | string[] {
  const arrDate = value.split('/');
  if (asDate) {
    return new Date(Number(arrDate[2]), Number(arrDate[1]) - 1, Number(arrDate[0]))
  }
  return arrDate;
}
