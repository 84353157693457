import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TipoLoginFormEnum } from 'src/app/enums/configuracion.enum';

@Component({
  selector: 'app-login-selector',
  templateUrl: './login-selector.component.html',
  styleUrls: ['./login-selector.component.scss']
})
export class LoginSelectorComponent implements OnInit {
  @Output() tipoFormularioEmit = new EventEmitter<TipoLoginFormEnum>();
  mostrarProgressBar: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  goToAutogestion() {
    this.tipoFormularioEmit.emit(1);
  }

  goToAdministracion() {
    this.tipoFormularioEmit.emit(2);
  }

}
