import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageConfig'
})
export class ImageConfigPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    value = this.convertString(args);
    let url = 'assets/images/configuraciones/';
    switch (value) {
      case 'estructura':
        url += `estructura.png`;
        break;
      case 'jornadas':
        url += `jornadas.png`;
        break;
      case 'nomina':
        url += `nomina_alt.png`;
        break;
      case 'novedades':
        url += `novedades.png`;
        break;
      case 'sistema':
        url += `sistema.png`;

      default:
        break;
    }
    return url;
  }

  private convertString(value) {
    value = value.toLowerCase().replace('ó', 'o').replace('organizacional', '').trim();
    return value;
  }

}
