<h1 mat-dialog-title class="bg-primary titulo-modal">
  {{ title }}
  <mat-icon class="btn-close" (click)="cerrarModal(null)">clear</mat-icon>
</h1>
<div mat-dialog-content>
  <div class="container">
    <div class="row">
      <div class="col">
        <form [formGroup]="form">
          <div class="flex-container-wrapper" [ngClass]="{'justify-content-center': formTemplateArr.length === 1}">
            <div *ngFor="let item of formTemplateArr; let i = index;" class="flex-box-item">
              <div [ngSwitch]="item.Type">
                <div *ngSwitchCase="'textbox'">
                  <mat-form-field appearance="outline" *ngIf="!item.Hidden">
                    <mat-label>{{ item.Label }}</mat-label>
                    <input matInput [type]="item.InputType" [formControlName]="item.FormcontrolName" placeholder="{{ item.Placeholder }}"
                    (keydown)="soloNumeros($event, item.SoloNumero)" [maxlength]="item.MaxLength">
                  </mat-form-field>
                </div>
                <div *ngSwitchCase="'checkbox'">
                  <mat-checkbox [formControlName]="item.FormcontrolName">{{ item.Label }}</mat-checkbox>
                </div>
                <div *ngSwitchCase="'select'">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ item.Label }}</mat-label>
                    <mat-select [formControlName]="item.FormcontrolName">
                      <mat-option *ngFor="let optionItem of item.Options" [value]="optionItem.Value">
                        {{optionItem.Key}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button mat-button (click)="cerrarModal(null)">Cerrar</button>
  <button mat-button cdkFocusInitial (click)="submitForm()">Guardar</button>
</div>
