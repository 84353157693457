<form class="form" [formGroup]="form" (ngSubmit)="submitForm($event)">
  <div class="col-12">
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" class="flex-item">
          <mat-label>Descripción</mat-label>
          <input class="input-text" matInput type="text" formControlName="Njornada" placeholder="Descripción"
            [maxlength]="150">
        </mat-form-field>
      </div>
    </div>

    <div class="row ">
      <div class="col-4">
        <mat-form-field appearance="outline" class="flex-item">
          <mat-label>Estado</mat-label>
          <mat-select formControlName="IdEstado">
            <mat-option *ngFor="let item of estadoOptions" [value]="item.Id">
              {{ item.Detalle }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field appearance="outline" class="flex-item">
          <mat-label>Horas Teóricas</mat-label>
          <input class="input-text" matInput type="text" formControlName="Hteoricas" placeholder="Hs Teoricas" [maxlength]="4">
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field appearance="outline" class="flex-item">
          <mat-label>Minutos de refrigerio</mat-label>
          <input class="input-text" matInput type="text" formControlName="RefrirgerioMinutos"
            placeholder="Minutos de refrigerio" (keydown)="soloNumeros($event, true)" [maxlength]="3">
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <mat-slide-toggle formControlName="EsFranco" [color]="'primary'" class="flex-item" (change)="getEsFranco($event)">
          Es Franco
        </mat-slide-toggle>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <mat-slide-toggle formControlName="gestionaIncidencia" [color]="'primary'" class="flex-item" (change)="getGestionaIncidencia($event)">
          Gestiona Incidencia
        </mat-slide-toggle>
      </div>
      <div class="col-6">
        <mat-form-field appearance="outline" class="flex-item">
          <mat-label>Seleccione incidencia</mat-label>
          <mat-select formControlName="IdIncidencia" (selectionChange)="getSeleccionIncidencia($event)">
            <mat-option *ngFor="let item of lstIncidencias" [matTooltip]="item.Detalle" [value]="item.Id">
              {{ item.Detalle }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <mat-form-field appearance="outline" class="flex-item">
          <mat-label>Tipo</mat-label>
          <mat-select formControlName="TipoJornada" (selectionChange)="getSeleccionTipoJornada($event)">
            <mat-option *ngFor="let item of tipoOptions" [value]="item.Id">
              {{ item.Detalle }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field appearance="outline" class="flex-item">
          <mat-label>Turnos</mat-label>
          <mat-select formControlName="TipoTurno" (selectionChange)="getSeleccionTipoTurno($event)">
            <mat-option *ngFor="let item of turnosOptions" [value]="item.Id">
              {{ item.Detalle }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-4">
        <button mat-raised-button color="primary" class="btn-guardar flex-item">GUARDAR</button>
      </div>
    </div>
  </div>
</form>
