<div class="container-fluid">
  <div class="spinner-container" *ngIf="!contentLoaded">
    <mat-spinner></mat-spinner>
  </div>

  <div class="row mr-0">
    <div class="col-6 ml-auto">
      <app-filter-card (filterEmit)="filter($event)" [filterEmitInit]="true" (sectorListEmit)="setSectorList($event)"
        (printExcelEmit)="print($event)">
      </app-filter-card>
    </div>
  </div>
  <div class="row">
    <div class="col-7 mx-auto">
      <mat-card class="card-home-info" *ngIf="dotacionObj">
        <mat-card-title class="text-center"><strong>Dotación</strong></mat-card-title>
        <mat-card-content>
          <div class="container">
            <div class="row">
              <div class="col text-center box-dot" matTooltip="Ver detalle"
                (click)="redirectDotacion(22, 'Dotación Inicial')">
                <p class="p-dotacion">Inicio de Periodo:</p> <span class="span-dotacion">{{ dotacionObj.dInicial
                  }}</span>
              </div>
              <div class="col text-center box-dot" matTooltip="Ver detalle" (click)="redirectDotacion(24, 'Ingresos')">
                <p class="p-dotacion">Ingresos:</p> <span class="span-dotacion">{{ dotacionObj.ingresos }}</span>
              </div>
              <div class="col text-center box-dot" matTooltip="Ver detalle" (click)="redirectDotacion(25, 'Bajas')">
                <p class="p-dotacion">Bajas:</p> <span class="span-dotacion">{{ dotacionObj.bajas }}</span>
              </div>
              <div class="col text-center box-dot" matTooltip="Ver detalle"
                (click)="redirectDotacion(23, 'Dotación Final')">
                <p class="p-dotacion">Fin de Periodo:</p> <span class="span-dotacion">{{ dotacionObj.dFinal }}</span>
              </div>
            </div>
          </div>
        </mat-card-content>
        <!-- POR AHORA OCULTOS HASTA HACER EL DESARROLLO -->
        <!-- <mat-card-actions align="end" class="mt-2">
          <button mat-stroked-button class="btn-action" matTooltip="Ver detalle" [matTooltipPosition]="'above'">
            <mat-icon>
              <span class="material-icons-outlined">
                bar_chart
              </span>
            </mat-icon>
          </button>
          <button mat-stroked-button class="btn-action" matTooltip="Ver detalle" [matTooltipPosition]="'above'"
            >
            <mat-icon>
              <span class="material-icons-outlined">
                arrow_right_alt
              </span>
            </mat-icon>
          </button>
        </mat-card-actions> -->
      </mat-card>
    </div>
  </div>
  <div class="row justify-content-center">
    <div #cardsContainer class="cards-container" *ngIf="tableroCards[0]">
      <mat-card *ngFor="let itemCard of tableroCards[0]" class="card-item" [ngClass]="setBg(itemCard.EstadoObjeto)"
        matTooltip="{{ itemCard.ControlDesc }}">
        <mat-card-title class="text-center">{{ itemCard.Ncontrol }}</mat-card-title>
        <mat-card-content class="text-center mt-0">
          <p>
            <span style="font-size: 22px">{{ itemCard.Valor1 | number: '1.2-2' }}%</span><br>
            <span style="font-size: 14px; margin-bottom: 0px">{{ itemCard.Valor2 }}</span><br>
            <span style="font-size: 12px" *ngIf="itemCard.MuestraDotacionTeorica==1"> Dotación Teórica: {{
              itemCard.DotacionTeorica }}</span>
            <!--<span style="font-size: 12px" *ngIf="itemCard.MuestraComparativos==0"> <br> </span>-->
          </p>

        </mat-card-content>
        <mat-card-actions align="end" class="mt-2">
          <button mat-stroked-button class="btn-action" matTooltip="Ver detalle" [matTooltipPosition]="'above'"
            (click)="openModalChart(itemCard, true)">
            <mat-icon>
              <span class="material-icons-outlined">
                bar_chart
              </span>
            </mat-icon>
          </button>
          <button mat-stroked-button class="btn-action" matTooltip="Ver detalle" [matTooltipPosition]="'above'"
            (click)="redirect(itemCard)">
            <mat-icon>
              <span class="material-icons-outlined">
                arrow_right_alt
              </span>
            </mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>

  <div class="row mr-0">
    <div class="col">
      <app-swiper-cards *ngIf="tableroCards[1]" [tableroCards]="tableroCards[1]" (redirectEmit)="redirect($event)"
        (openModalEmit)="openModalChart($event, false)">
      </app-swiper-cards>
    </div>
  </div>
</div>

<app-message [mostrarMensaje]="mensajeObj?.ActivarAlerta" [mensaje]="mensajeObj?.MensajeAlerta"></app-message>