<div class="contenedor">
  <div class="container-fluid my-3 pb-2">
    <div class="row">
      <div class="col-6 mx-auto text-center text-white">
        <app-alert [titulo]="'Configuracion jornadas'"></app-alert>
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        <div class="row">
          <div class="col-12  style-tilte-list">
            Jornadas
          </div>
        </div>
        <div class="row">
          <div class="col-12 list-tollbar">
            <div class="row justify-content-end mr-1">
              <div class="col-10">
                <input type="text" [formControl]="inputFiltroLegajo" placeholder="Ingrese valor a buscar"
                  (keyup)="applyFilter($event)">
              </div>
              <div class="col-2">
                <mat-icon [matTooltip]="'Nueva jornada'" (click)="nuevaJornada()">add</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col style-list">
            <app-list-jornadas [result]="result" (elementSelectedEmit)="getJornadaSeleccionada($event)"
              (clearFormsEmit)="setClearForms($event)" [selectedValue]="jornadaSelected"></app-list-jornadas>
          </div>
        </div>
      </div>



      <div class="col-2 ">

        <div class="row">
          <div class="col-12 style-tilte-list">
            <p>Plantillas</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12 list-tollbar">
            <div class="row justify-content-end mr-1">
              <!--<div class="col-2">
                <mat-icon [matTooltip]="'Administrar Plantillas'" (click)="openModalPlantillas()">edit</mat-icon>
              </div>-->

              <!--<div class="col-2">
                <mat-icon [matTooltip]="'Agregar Plantilla'" (click)="openModalPlantillas()">add</mat-icon>
              </div>
              <div class="col-2">
                <mat-icon [matTooltip]="'Eliminar Plantilla'" (click)="EliminarPlantilla()">remove</mat-icon>
              </div>-->
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col style-list">
            <app-list-plantillas [plantillasList]="plantillasListResult"
              (plantillaSelectedEmit)="getPlantillaSelected($event)"></app-list-plantillas>
          </div>
        </div>

      </div>

      <div class="col-2 ">

        <div class="row">
          <div class="col-12 style-tilte-list">
            <p>Usuarios por Plantilla</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12 list-tollbar">
          </div>
        </div>

        <div class="row">
          <div class="col style-list">
            <app-list-usuarios-plantilla [dataUsuariosPlantilla]="dataUsuariosPlantillaFiltered">
            </app-list-usuarios-plantilla>
          </div>
        </div>

      </div>

      <div class="col-6">
        <div class="row mb-2">
          <div class="col-12">
            <app-form-config-a [configuracion]="jornadaSeleccionada" (tipoJornadaEmit)="setTipoJornada($event)"
              (tipoTurnoEmit)="setTipoTurno($event)" [setClearForm]="clearForms" (formSubmitEmit)="submitForm($event)">
            </app-form-config-a>
          </div>
        </div>

        <div class="row  mb-2">
          <div class="col-12">
            <app-form-config-b [configuracion]="jornadaSeleccionada"
              [disableForm]="tipoJornadaSelected === tipoJornadaEnum.FLEXIBLE" [setClearForm]="clearForms"
              (formSubmitEmit)="getFormTurnoUno($event)" (datosCalculadosTurnoEmit)="getDatosCalculadosTurno1($event)">
            </app-form-config-b>
          </div>
        </div>

        <div class="row  mb-2">
          <div class="col-12">
            <app-form-config-c [configuracion]="jornadaSeleccionada"
              [disableForm]="(tipoJornadaSelected === tipoJornadaEnum.ESPECIFICA && tipoTurnoSelected === tipoTurnoEnum.SIMPLE) || (tipoJornadaSelected === tipoJornadaEnum.FLEXIBLE)"
              [setClearForm]="clearForms" (formSubmitEmit)="getFormTurnoDos($event)"
              (datosCalculadosTurnoEmit)="getDatosCalculadosTurno2($event)">
            </app-form-config-c>
          </div>
        </div>

      </div>
    </div>

  </div>

</div>