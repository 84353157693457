<div class="container mt-3">
  <div class="row">
    <div class="col-6 mx-auto text-center text-white">
      <app-alert [titulo]="'Configuraciones'"></app-alert>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col">
      <div class="flex-container-wrapper">
        <div class="flex-box-item" *ngFor="let item of configuracionList | groupByField:'AbmAgrupador'">
          <figure class="figure-wrapper" [ngClass]="item.key | style">
            <div class="fig-content">
              <img [src]="item.value | imageConfig:item.key"
              alt="{{item.key}}" />
            </div>

            <figcaption class="figcaption">
              <h1>{{ item.key }}</h1>
              <ul>
                <li *ngFor="let subItem of item.value">
                  <a href="javascript:void(0)" class="item-menu" [ngClass]="{'not-allowed': !subItem.MenuEnabled}"
                   (click)="redirigirAbm(subItem)">{{ subItem.AliasTabla | titlecase }}</a>
                </li>
              </ul>
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  </div>
</div>
