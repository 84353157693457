import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { NavEntity } from 'src/app/models/general/nav-entity.interface';
import { UserAutogestionService } from 'src/app/services/utils/user-autogestion.service';

@Component({
  selector: 'app-menu-home-autogestion',
  templateUrl: './menu-home-autogestion.component.html',
  styleUrls: ['./menu-home-autogestion.component.scss']
})
export class MenuHomeAutogestionComponent implements OnInit {
  navMenuList: Array<NavEntity> = [];

  constructor(
    private userAutogestionService: UserAutogestionService,
    private router: Router
  ) { }

  ngOnInit() {
    this.setMenuItems();
  }

  setMenuItems() {
    this.navMenuList = this.userAutogestionService.getUsuarioValues.SistMenu ?
      JSON.parse(this.userAutogestionService.getUsuarioValues.SistMenu) : null;
  }
}
