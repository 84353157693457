<div class="contenedor">
  <div class="container-fluid my-3 pb-2">
    <div class="row">
      <div class="col-6 mx-auto text-center text-white">
        <app-alert [titulo]="'Configuración de Nómina'"></app-alert>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="row">
          <div class="col-12  style-tilte-list">
            Nómina
          </div>
        </div>
        <div class="row">
          <div class="col-12 list-tollbar">
            <div class="row justify-content-end mr-1">
              <div class="col-10">
                <input type="text" [formControl]="inputFiltroLegajo" placeholder="Ingrese valor a buscar"
                  (keyup)="applyFilter($event)">
              </div>
              <div class="col-2">
                <mat-icon [matTooltip]="'Nuevo Legajo'" (click)="nuevoLegajo()">add</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col style-list">
            <mat-selection-list #matSelectionNomina role="list" class="list-nomina"
              (selectionChange)="getSelectedLegajo($event)" [compareWith]="compareObjectsNomina">
              <mat-list-option #matOptions *ngFor="let item of configuracionNominaSources.SourceNominaTotal"
                [value]="item">
                {{ item.ID_LEGAJO + ' - ' + item.APELLIDO + ' ' + item.NOMBRE }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </div>
      </div>


      <div class="col-9">
        <form [formGroup]="formNomina" (ngSubmit)="submitForm()" class="mt-0">
          <div class="row">
            <div class="col-12 style-tilte-list">
              <p>Datos de Empleado</p>
            </div>
          </div>

          <div class="row">
            <div class="col-12 list-tollbar">
              <button mat-raised-button type="submit" color="primary" class="btn-guardar">GUARDAR</button>
            </div>
          </div>

          <div class="row">
            <div class="col-9 style-list mt-1">

                <div class="row">
                  <div class="col-4">
                    <mat-form-field appearance="fill" floatLabel="auto">
                      <mat-label>Id Legajo</mat-label>
                      <input matInput type="number" formControlName="idLegajo"
                        [ngClass]="{'legajoModificado': !legajoNew}" maxlength="10" [readonly]="!legajoNew" required>
                      <button type="button" mat-icon-button matSuffix matTooltip="Consultar nómina Cofarsur"
                        (click)="openModalNomina()">
                        <mat-icon class="ico-search-legajo">search</mat-icon>
                      </button>
                    </mat-form-field>
                  </div>

                  <div class="col-4">
                    <mat-form-field appearance="fill" floatLabel="auto">
                      <mat-label>Id Legajo de Marcación</mat-label>
                      <input matInput type="number" formControlName="idLegajoMarcacion"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="10">
                    </mat-form-field>
                  </div>


                  <div class="col-4">
                    <mat-form-field appearance="fill" floatLabel="auto">
                      <mat-label>Id Legajo de Liquidación</mat-label>
                      <input matInput type="number" formControlName="idLegajoLiquidacion"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="10" required>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-4">
                    <mat-form-field appearance="fill" [floatLabel]="'auto'">
                      <mat-label>Apellido</mat-label>
                      <input matInput type="text" formControlName="apellido" [maxlength]="50" required>
                    </mat-form-field>
                  </div>
                  <div class="col-4">
                    <mat-form-field appearance="fill" [floatLabel]="'auto'">
                      <mat-label>Nombre</mat-label>
                      <input matInput type="text" formControlName="nombre" [maxlength]="100" required>
                    </mat-form-field>
                  </div>
                </div>


                <div class="row">
                  <div class="col-4">
                    <mat-form-field appearance="fill" [floatLabel]="'auto'">
                      <mat-label>CUIT</mat-label>
                      <input matInput type="number" formControlName="cuit"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="11" required>
                    </mat-form-field>
                  </div>
                  <div class="col-4">
                    <mat-form-field appearance="fill" [floatLabel]="'auto'">
                      <mat-label>DNI</mat-label>
                      <input matInput type="number" formControlName="dni"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="10" required>
                    </mat-form-field>
                  </div>
                  <div class="col-4">
                    <mat-form-field appearance="fill" [floatLabel]="'auto'">
                      <mat-label>Fecha Nacimiento</mat-label>
                      <input matInput type="date" formControlName="fechaNacimiento" required>
                    </mat-form-field>
                  </div>
                </div>


                <div class="row">
                  <div class="col-4">
                    <mat-form-field appearance="fill" floatLabel="auto">
                      <mat-label></mat-label>
                      <mat-slide-toggle formControlName="ExcluidoIndicadores" [color]="'primary'" class="flex-item"
                        (change)="getExcluidoIndicadores($event)">
                        Excluir de indicadores
                      </mat-slide-toggle>
                      <textarea matInput hidden></textarea>
                    </mat-form-field>
                  </div>

                  <div class="col-4">
                    <mat-form-field appearance="fill" [floatLabel]="'auto'">
                      <mat-label></mat-label>
                      <mat-slide-toggle formControlName="gestionaIncidencia" [color]="'primary'" class="flex-item"
                        (change)="getGestionaIncidencia($event)">
                        ¿Asignar inciencia programada?
                      </mat-slide-toggle>
                      <textarea matInput hidden></textarea>
                    </mat-form-field>
                  </div>

                  <div class="col-4" *ngIf="gestionaIncidencias">
                    <mat-form-field appearance="fill" [floatLabel]="'auto'">
                      <mat-label>Incidencia programada</mat-label>
                      <mat-select formControlName="IdIncidencia">
                        <mat-option *ngFor="let item of configuracionNominaSources.SourceIncidencias"
                          [matTooltip]="item.N_INCIDENCIA" [value]="item.ID_INCIDENCIA">
                          {{ item.N_INCIDENCIA }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-4">
                    <mat-form-field appearance="fill" [floatLabel]="'auto'" *ngIf="configuracionNominaSources">
                      <mat-label>Método de Autentidación</mat-label>
                      <mat-select formControlName="idMetodoAutenticacion"
                        (selectionChange)="getSeleccionMetodoAutenticacion($event)">
                        <mat-option *ngFor="let optionItem of configuracionNominaSources.SourceMetodosAutenticacion"
                          [value]="optionItem.ID_METODO">
                          {{ optionItem.N_METODO }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-4" [hidden]="!metodoShooter">
                    <mat-form-field appearance="fill" [floatLabel]="'auto'">
                      <mat-label>Código de seguridad ShooterBot</mat-label>
                      <input matInput type="number" formControlName="codigoSeguridadShooterBot" [maxlength]="10">
                    </mat-form-field>
                  </div>
                </div>
            </div>
            <div class="col-3 style-list">
              <div class="col-12" [hidden]="!metodoShooter">
                <div class="row div-list-equipos-title">
                  Terminales habilitados
                </div>
                <div class="row div-list-equipos">
                  <mat-selection-list #matSelectionEquipos role="list" class="list-equipos equipos"
                    formControlName="idEquipoShooter" [compareWith]="compareObjectsEquipos">
                    <mat-list-option *ngFor="let itemShooter of SourceShooterEquipos" [value]="itemShooter.ID_EQUIPO">
                      {{ itemShooter.N_EQUIPO }}
                    </mat-list-option>
                  </mat-selection-list>
                </div>
              </div>
            </div>
            
          </div>
        </form>
      </div>

    </div>

  </div>
</div>