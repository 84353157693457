<div class="container mt-3">
  <div class="row">
    <div class="col-6 mx-auto text-center text-white">
      <app-alert [titulo]="title | titlecase"></app-alert>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col">
      <button mat-raised-button class="btn-gray" [disabled]="(firstRow && !firstRow.BTN_NUEVO) || false" (click)="openModal(tipoConfiguracionEnum.NUEVO)">Nuevo</button>
      <button mat-raised-button class="mx-3 btn-gray" [disabled]="!row || (row && !row.BTN_MODIFICAR)"
      (click)="openModal(tipoConfiguracionEnum.MODIFICAR)">Modificar</button>
      <!--<button mat-raised-button color="warn" [disabled]="(!row) || (row && !row.BTN_ANULAR)"
      (click)="openModal(tipoConfiguracionEnum.ANULAR)">Anular</button>-->
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <mat-form-field class="filter-input">
        <mat-label>Filtrar</mat-label>
        <input matInput (keyup)="filtrarGrilla($event)" sniperAutofocus>
      </mat-form-field>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-parte-diario">
        <ng-container *ngFor="let col of columns" [matColumnDef]="col">
          <div *ngIf="col !== 'accion';else btnAction">
            <th class="border bg-secondary text-white" mat-header-cell *matHeaderCellDef> {{ col }} </th>
            <td class="border" mat-cell *matCellDef="let element" matTooltip="{{ element[col] }}"> {{ element[col] }}
            </td>
          </div>

          <ng-template #btnAction>
            <th mat-header-cell *matHeaderCellDef class="border bg-secondary text-white"> </th>
            <td mat-cell *matCellDef="let element; let i = index" class="text-center">
              <mat-checkbox [checked]="selected === i" (change)="checkboxSelect($event, element); selected = i"></mat-checkbox>
            </td>
          </ng-template>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"
        [ngClass]="{'anulado': paintRow(row)}"></tr>
      </table>

      <mat-paginator #detallePaginator [pageSizeOptions]="[1000, 500]"></mat-paginator>
    </div>
  </div>
</div>
