<div class="cards-container">
  <div class="swiper-button-prev">
    <img src="assets/images/left_arrow_swipe.svg" alt="arrow_left" />
  </div>
  <swiper [config]="config">
    <div class="swiper-wrapper home-swiper">
      <div class="swiper-slide" *ngFor="let card of tableroCards">
        <mat-card class="card-item" matTooltip="{{ card.ControlDesc }}" [ngClass]="setBg(card.EstadoObjeto)">
          <mat-card-title class="text-center">{{ card.Ncontrol }}</mat-card-title>
          <mat-card-content class="text-center">
            <p class="mt-3">
              {{ card.Valor1 }}
            </p>
            <p>
              {{ card.Valor2 }}
            </p>
          </mat-card-content>
          <mat-card-actions align="end">
            <button mat-stroked-button class="btn-action" matTooltip="Ver detalle" [matTooltipPosition]="'above'"
              (click)="openModalChart(card)">
              <mat-icon>
                <span class="material-icons-outlined">
                  bar_chart
                </span>
              </mat-icon>
            </button>
            <button mat-stroked-button class="btn-action mx-2" matTooltip="Ver detalle" [matTooltipPosition]="'above'"
              (click)="redirect(card)">
              <mat-icon>
                <span class="material-icons-outlined">
                  arrow_right_alt
                </span>
              </mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </swiper>
  <div class="swiper-button-next">
    <img src="assets/images/right_arrow_swipe.svg" alt="arrow_rigth" />
  </div>
</div>