import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Filters } from 'src/app/models/general/filters.model';
import { Nomina } from 'src/app/models/rrhh/nomina.model';
import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { ReporteService } from 'src/app/services/reporte.service';
import { ModalNominaComponent } from '../../modals/modal-nomina/modal-nomina.component';

@Component({
  selector: 'app-filter-individual',
  templateUrl: './filter-individual.component.html',
  styleUrls: ['./filter-individual.component.scss']
})
export class FilterIndividualComponent implements OnInit {
  @Output() printExcelEmit = new EventEmitter();
  @Output() empleadoEmit = new EventEmitter<Nomina>();
  @Output() valuesRangeEmit = new EventEmitter<{ start, end }>();
  @Output() filterEmit = new EventEmitter<Filters>();
  empleadoSelected: Nomina;
  chkSeleccionarPeriodo = false;
  minDate: Date;
  maxRangeDate: Date;
  start: UntypedFormControl;
  end: UntypedFormControl;
  periodoConverted: string;
  legajo: number;
  periodosAnualesList: any[];
  periodoAnualSelected: any;
  periodoPreset: number;
  currentYear: number;

  constructor(
    private dialog: MatDialog,
    private reporteService: ReporteService
  ) { }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    this.periodoPreset = this.currentYear;
    this.setDateRangeValues();
    this.checkRangeStartDateChanged();
    this.getPeriodosAnuales();
  }

  setDateRangeValues() {
    // DTP Range
    this.start = new UntypedFormControl({ value: null, disabled: true })
    this.end = new UntypedFormControl({ value: '', disabled: true });
  }

  printFile(soloDescarga?: boolean) {
    var periodResult: any;
    if (!this.chkSeleccionarPeriodo) {
      if ((!this.start.value && !this.end.value)) {
        return;
      } else {
        const start = this.start.value.toDate();
        const end = this.end.value.toDate();
        periodResult = { desde: start, hasta: end };
      }
    }


    this.periodoConverted = periodResult ? periodResult : '';
    const emitObj: Filters = {
      periodo: this.periodoConverted,
      legajo: this.legajo == undefined ? 0 : this.legajo,
      isPeriodoSeleccionado: this.chkSeleccionarPeriodo,
      empleadoSelected: this.empleadoSelected,
      periodoSelect: this.periodoAnualSelected
    };
    this.printExcelEmit.emit(emitObj);
  }

  openModalNomina() {
    const dialogRef = this.dialog.open(ModalNominaComponent, {
      width: '1000px',
      height: '550px',
      panelClass: 'modal-marcacion',
      autoFocus: false
    });
    dialogRef.beforeClosed().subscribe((result: Nomina) => {
      if (result) {
        this.empleadoSelected = result;
        this.legajo = this.empleadoSelected.IdLegajo;
        this.empleadoEmit.emit(result);
      }
    });
  }

  getEmpleadoSelected() {
    return this.empleadoSelected ? (this.empleadoSelected.IdLegajo + ' - ' + this.empleadoSelected.Apellido + ' ' + this.empleadoSelected.Nombre) : '';
  }

  checkboxSelect(e) {
    this.chkSeleccionarPeriodo = e.checked;
    if (!e.checked) {
      this.periodoPreset = this.currentYear;
    }
    // this.setDateRangeValues();
  }

  private checkRangeStartDateChanged() {
    this.start.valueChanges.subscribe((value) => {
      if (value) {
        this.maxRangeDate = this.calcularFechaMaximaRango(value.toDate());
      }
    });

    this.end.valueChanges.subscribe((value) => {
      if (value && this.start.value) {
        this.valuesRangeEmit.emit({ start: this.start.value.toDate(), end: value.toDate() });
      }
    })
  }

  calcularFechaMaximaRango(date, mesActual = false, primerDia = false) {
    const month = date.getMonth();
    const year = date.getFullYear();
    let fechaResult = new Date(year, month + 2, 0); // OBTENGO EL ULTIMO DIA DEL MES SIGUIENTE
    if (mesActual) {
      fechaResult = primerDia ? new Date(year, month, 1) : new Date(year, month + 1, 0);
    }
    return fechaResult;
  }

  buscar() {
    var periodResult: any;
    // POR AHORA NO SE USA
    // if (!this.chkSeleccionarPeriodo) {
    //   if ((!this.start.value && !this.end.value)) {
    //     return;
    //   } else {
    //     const start = this.start.value.toDate();
    //     const end = this.end.value.toDate();
    //     periodResult = { desde: start, hasta: end };
    //   }
    // }

    // if (!this.periodoAnualSelected && this.chkSeleccionarPeriodo) {
    //   return;
    // }

    this.periodoConverted = periodResult ? periodResult : '';
    const emitObj: Filters = {
      periodo: this.periodoConverted,
      legajo: this.legajo == undefined ? 0 : this.legajo,
      isPeriodoSeleccionado: this.chkSeleccionarPeriodo,
      empleadoSelected: this.empleadoSelected,
      periodoSelect: this.periodoAnualSelected
    };
    this.filterEmit.emit(emitObj);
  }

  getPeriodosAnuales() {
    this.reporteService.getPeriodosAnualesEndpoint().subscribe((res: ResponseHelper) => {
      if (res.Ok) {
        this.periodosAnualesList = JSON.parse(res.Json).sort().reverse();
      }
    });
  }

  getPeriodoEmit(periodo) {
    this.periodoAnualSelected = periodo.value;
  }

}
