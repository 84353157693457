<mat-card class="card-actions">
  <div class="flex-container">
    <div class="flex-box" *ngIf="hasExportExcelButton">
      <div class="text-center mx-2">
        <button mat-icon-button class="icon-action-print" matTooltip="Exportar a pdf" aria-label="Exportar a pdf"
          (click)="printFile(true)" [disabled]="exportExcelButtonDisabled">
          <i class="far fa-file-pdf"></i>
        </button>
      </div>
    </div>

    <div *ngIf="!showRangePicker;else rangePicker">
      <div class="flex-box">
        <mat-form-field appearance="outline" class="mat-form-field-date">
          <mat-label>Periodo</mat-label>
          <input matInput [matDatepicker]="dp" [formControl]="date" [max]="maxDate" [min]="minDate">
          <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
          <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
            (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
          </mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <ng-template #rangePicker>
      <div class="flex-box">
        <mat-form-field appearance="outline" class="mat-form-field-date">
          <mat-label>Desde</mat-label>
          <input matInput [matDatepicker]="dpStart" [formControl]="start" [min]="minDate" (dateChange)="desdeChanged()">
          <mat-datepicker-toggle matSuffix [for]="dpStart"></mat-datepicker-toggle>
          <mat-datepicker #dpStart [disabled]="false">
          </mat-datepicker>
        </mat-form-field>
      </div>

      <div class="flex-box">
        <mat-form-field appearance="outline" class="mat-form-field-date">
          <mat-label>Hasta</mat-label>
          <input matInput [matDatepicker]="dpEnd" [formControl]="end" [min]="start.value" [max]="maxRangeDate">
          <mat-datepicker-toggle matSuffix [for]="dpEnd"></mat-datepicker-toggle>
          <mat-datepicker #dpEnd [disabled]="!start.value"></mat-datepicker>
        </mat-form-field>
      </div>
    </ng-template>


    <div class="flex-box">
      <div class="form-field-group">
        <mat-form-field class="seccion select-form-field w-100">
          <mat-label>Sección</mat-label>
          <mat-select [(ngModel)]="idSeccion">
            <mat-option *ngFor="let item of cmbSectorList" [matTooltip]="item.Nsector" [value]="item.IdSector">
              {{ item.Nsector }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="flex-box" *ngIf="hasLegajoInput">
      <mat-form-field class="input-legajo">
        <mat-label>Legajo</mat-label>
        <input matInput placeholder="" [(ngModel)]="legajo">
      </mat-form-field>
    </div>

    <div class="flex-box">
      <button mat-raised-button color="primary" class="ml-4" (click)="filter()">Filtrar</button>
    </div>
  </div>
</mat-card>