import { HomeAutogestionComponent } from './../home-autogestion/home-autogestion.component';
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AvisosAusenciasComponent } from "./avisos-ausencias/avisos-ausencias.component";
import { AuthGuard } from 'src/app/services/utils/auth.guard';

const routes: Routes = [
  { path: 'home-autogestion', canActivate: [AuthGuard], component: HomeAutogestionComponent },
  { path: 'avisos-ausencias', canActivate: [AuthGuard], component: AvisosAusenciasComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AutogestionRoutingModule {
}