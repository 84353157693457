<div class="card-inner-padding">
  <mat-card-header>
    <mat-card-title class="login-title">Bienvenido</mat-card-title>
    <mat-card-subtitle>Autogestión</mat-card-subtitle>
  </mat-card-header>
  <form (ngSubmit)="loginSubmit()">
    <mat-card-content class="p-2 mb-0">
      <mat-form-field>
        <input matInput [(ngModel)]="usuario.NomUsuario" placeholder="Usuario" name="username" required>
      </mat-form-field>
      <mat-form-field>
        <input matInput [(ngModel)]="usuario.Pass" placeholder="Contraseña" type="password" name="password" required>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions class="p-2 text-center">
      <button class="btn-block" mat-raised-button color="primary">Ingresar</button>
      <button (click)="volver()" type="button" class="btn btn-link btn-link-rec-pass">
        Volver
      </button>
    </mat-card-actions>
  </form>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="mostrarProgressBar"></mat-progress-bar>