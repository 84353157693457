import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gestion-mensaje',
  templateUrl: './gestion-mensaje.component.html',
  styleUrls: ['./gestion-mensaje.component.scss']
})
export class GestionMensajeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
