import { ModalGestionIncidenciaComponent } from './../modal-gestion-incidencia/modal-gestion-incidencia.component';
import { NovedadesEndPoint } from './../../../services/rrhh/novedades/novedades-endpoint';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Marcacion } from 'src/app/models/rrhh/marcacion.model';
import { Novedades } from 'src/app/models/rrhh/novedades/novedades.model';
import { CmbEntity } from 'src/app/models/general/cmbEntity.model';
import { Usuario } from 'src/app/models/general/usuario.model';
import { NovedadesService } from 'src/app/services/rrhh/novedades/novedades.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { ParamEntity } from 'src/app/models/general/param.model';
import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { ModalConfirmacionComponent } from '../modal-confirmacion/modal-confirmacion.component';
import { Incidencia } from 'src/app/models/rrhh/incidencia.model';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { formatDate } from '@angular/common';
import { map, startWith } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-modal-marcacion',
  templateUrl: './modal-marcacion.component.html',
  styleUrls: ['./modal-marcacion.component.scss']
})
export class ModalMarcacionComponent implements OnInit {

  marcacionColumns: string[] = [
    'Hora',
    'Tipo Marcación',
    'Fuente Marcación',
    'Equipo',
    'Estado',
    'Incidencia',
    'Foto',
    'Observaciones',
    'Accion'
  ];

  incidenciaColumns: string[] = [
    'Incidencia',
    'Observaciones',
    'Estado',
    'Patologia',
    'Fecha Modificacion',
    'nUsuario'
  ];


  myControl = new UntypedFormControl();
  filteredOptions: Observable<any[]>;

  dataSourceMarcacion = new MatTableDataSource<Marcacion>([]);
  dataSourceIncidencia = new MatTableDataSource<Incidencia>([]);
  @ViewChild('marcacionPaginator', { static: true }) marcacionPaginator: MatPaginator;
  @ViewChild('incidenciasPaginator', { static: true }) incidenciasPaginator: MatPaginator;

  objeto: Novedades;
  titulo: string;
  novedad;
  lstJornadas: CmbEntity[] = [];
  lstIncidencias: CmbEntity[] = [];
  lstPatologias: CmbEntity[] = [];
  usuario: Usuario;
  isBtnGuardarJornada = false;
  isBtnGuardarMarcacion = false;
  isBtnGuardarIncidencia = false;
  setHorarioHabilitado = true;
  menuMarcacionOpened = false;
  isBtnAnularMarcacion = true;
  isBtnHabilitarMarcacion = true;
  timePickerValue: string;
  tiempoActualReset: string;
  cmbPatologiaHabilitado: boolean;
  actualizaGrillaParteDiario = false;
  modalData: any;
  elementMarcacionSelected: Marcacion;

  constructor(
    public dialogRef: MatDialogRef<ModalMarcacionComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private novedadesService: NovedadesService,
    private userValuesService: UserValuesService,
    private _snackBar: SnackBarService) {
    this.usuario = this.userValuesService.getUsuarioValues;
    this.objeto = data.obj;

    let fechaActual;

    if (this.objeto.FechaDate) {
      fechaActual = new Date(this.objeto.FechaDate); // Aseguramos que es un objeto Date

      // Verificamos si la hora es 00:00:00
      if (fechaActual.getHours() === 0 && fechaActual.getMinutes() === 0 && fechaActual.getSeconds() === 0) {
        // Establecemos la hora actual en la fecha existente
        const ahora = new Date();
        fechaActual.setHours(ahora.getHours(), ahora.getMinutes(), ahora.getSeconds());
      }
    } else {
      // Si no hay valor de entrada, usamos la fecha actual
      fechaActual = new Date();
    }

    // Restamos 3 horas
    fechaActual.setHours(fechaActual.getHours() - 3);
    this.objeto.FechaDate = fechaActual;


    this.objeto.IdIncidencia = null;
    this.objeto.IdPatologia = -1; // seteo ese valor en caso de que no se guarde ningun dato al guardar la incidencia
    this.loadMarcaciones();
    this.loadIncidencias();
    this.titulo = this.data.titulo;
  }

  ngOnInit() {
    this.dataSourceMarcacion.paginator = this.marcacionPaginator;
    this.dataSourceIncidencia.paginator = this.incidenciasPaginator;
    this.loadJornadasCmb();
    this.loadIncidenciasCmb();
    this.loadPatologiasCmb();
    this.getData();
  }

  private getData() {
    const paramEntity = new ParamEntity();
    paramEntity.Json = JSON.stringify({
      pIdIncidencia: 0,
      pIdUsuario: this.usuario.IdUsuario
    });
    this.novedadesService.getModalMarcacionesData(paramEntity).subscribe((result: any) => {
      this.modalData = result;
    })
  }

  loadJornadasCmb() {

    let params = [];
    params.push(null);
    params.push(this.userValuesService.getUsuarioValues.IdEmpresa);
    params.push(this.usuario.IdUsuario);
    this.novedadesService.getListJornadasUser(params).subscribe((result: CmbEntity[]) => {
      this.lstJornadas = result;
      this.subscribeEventChange();
    });

    //Fer 24-07-2021: set default value
    let jorn = new CmbEntity;
    jorn.Id = this.objeto.IdJornada;
    jorn.Detalle = this.objeto.Njornada;
    this.myControl.setValue(jorn);
  }

  private subscribeEventChange() {
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.Detalle),
        map(value => this._filter(value))
      );
  }

  private _filter(value): any[] {
    const filterValue = value.toLowerCase();
    return this.lstJornadas.filter(option => option.Detalle.toLowerCase().includes(filterValue));
  }

  displayFn(item: CmbEntity): string {
    return item && item.Detalle ? item.Detalle : '';
  }

  loadIncidenciasCmb() {
    let params = [];
    params.push(null);
    params.push(this.usuario.IdRol);
    params.push(this.userValuesService.getUsuarioValues.IdEmpresa);

    this.novedadesService.getListIncidencias(params).subscribe((result: CmbEntity[]) => {
      this.lstIncidencias = result;
      this.setMostrarCmbPatologia();
    });
  }

  loadPatologiasCmb() {
    let paramEntity = new ParamEntity<object>();
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    paramEntity.IdLegajo = this.objeto.IdLegajo;
    paramEntity.FechaDate = this.objeto.FechaDate;
    paramEntity.IdPatologia = 0;

    this.novedadesService.getListPatologias(paramEntity).subscribe((result: CmbEntity[]) => {
      this.lstPatologias = result;
    });
  }

  loadMarcaciones() {
    let paramEntity = new ParamEntity<object>();
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    paramEntity.IdLegajo = this.objeto.IdLegajo;
    paramEntity.FechaDate = this.objeto.FechaDate;
    this.novedadesService.getListMarcaciones(paramEntity).subscribe((result: Marcacion[]) => {
      this.dataSourceMarcacion.data = result;
    }, (error) => { this._snackBar.openSnackBar('snack-danger', error.error, 3000); });
  }

  loadIncidencias() {
    let paramEntity = new ParamEntity<object>();
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    paramEntity.IdLegajo = this.objeto.IdLegajo;
    paramEntity.FechaDate = this.objeto.FechaDate;
    this.novedadesService.getIncidenciasGrilla(paramEntity).subscribe((result: Incidencia[]) => {
      this.dataSourceIncidencia.data = result;
    }, (error) => { this._snackBar.openSnackBar('snack-danger', error.error, 3000); });
  }

  cerrarModal() {
    const data = { actualiza: this.actualizaGrillaParteDiario, fecha: this.objeto.FechaDate };
    this.dialogRef.close(data);
  }

  calcularMovimientos() {
    if (this.objeto.Marcaciones && this.objeto.Nincidencia) {
      return this.objeto.Marcaciones + this.objeto.Nincidencia;
    }
  }

  getSeleccionJornada(e) {
    this.objeto.IdJornada = e.option.value.Id;
  }

  getSeleccionIncidencia(e) {
    this.objeto.IdIncidencia = e.value;
    // valido si el objeto seleccionado del combo tiene
    // el valor 1 para poder habilitar el combo de patologias
    this.setMostrarCmbPatologia();
  }

  getSeleccionPatologia(e) {
    this.objeto.IdPatologia = e.value;
  }

  guardarJornada() {
    this.isBtnGuardarJornada = true; // deshabilito en boton

    let paramEntity = new ParamEntity<object>();
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    paramEntity.IdLegajo = this.objeto.IdLegajo;
    paramEntity.FechaDate = this.objeto.FechaDate;
    paramEntity.IdJornada = this.objeto.IdJornada;
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    this.novedadesService.guardarJornada(paramEntity).subscribe((result: ResponseHelper) => {
      if (result.Ok) {
        this._snackBar.openSnackBar('snack-success', 'Jornada guardada correctamente', 3000);
        this.actualizaGrillaParteDiario = true;
      } else {
        this._snackBar.openSnackBar('snack-danger', result.Mensaje, 3000);
      }

    }, (error) => { this._snackBar.openSnackBar('snack-danger', error.error, 3000); });
    this.isBtnGuardarJornada = false;
  }

  guardarIncidencia() {
    this.isBtnGuardarIncidencia = true; // deshabilito en boton

    let paramEntity = new ParamEntity<Novedades>();
    paramEntity.GenericEntity = new Novedades();
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    paramEntity.IdLegajo = this.objeto.IdLegajo;
    paramEntity.FechaDateArray[0] = this.objeto.FechaDate;
    paramEntity.FechaDateArray[1] = this.objeto.FechaDate;
    paramEntity.IdIncidencia = this.objeto.IdIncidencia;
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    paramEntity.IdPatologia = this.objeto.IdPatologia;
    paramEntity.GenericEntity.Observaciones = this.objeto.Observaciones;

    this.novedadesService.guardarIncidencia(paramEntity).subscribe((result: ResponseHelper) => {
      if (result.Ok) {
        this._snackBar.openSnackBar('snack-success', 'Incidencia guardada correctamente', 3000);
        this.objeto.Observaciones = '';
        this.objeto.IdIncidencia = null;
        this.loadIncidencias();
        this.actualizaGrillaParteDiario = true;
      } else {
        this._snackBar.openSnackBar('snack-danger', result.Mensaje, 3000);
      }
    }, (error) => { this._snackBar.openSnackBar('snack-danger', error.error, 3000); });
    this.isBtnGuardarIncidencia = false;
  }

  anularMarcacion() {
    const marcacion = this.dataSourceMarcacion.data.find(x => x.Seleccionado);
    const paramEntity = new ParamEntity<Marcacion>();
    paramEntity.GenericEntity = new Marcacion();
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    paramEntity.IdLegajo = this.objeto.IdLegajo;
    paramEntity.GenericEntity.Hora = '';
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    paramEntity.GenericEntity.IdMarcacionFuente = 2;
    paramEntity.GenericEntity.IdMarcacionTipo = 2;
    paramEntity.GenericEntity.IdEstado = 0;
    paramEntity.GenericEntity.IdIncidencia = 0;
    paramEntity.GenericEntity.IdMarcacion = marcacion.IdMarcacion;

    this.novedadesService.anularMarcacion(paramEntity).subscribe((result: ResponseHelper) => {
      if (result.Ok) {
        this._snackBar.openSnackBar('snack-success', 'Marcación anulada correctamente', 3000);
        this.loadMarcaciones();
        this.actualizaGrillaParteDiario = true;
      } else {
        this._snackBar.openSnackBar('snack-danger', result.Mensaje, 3000);
      }
    }, (error) => { this._snackBar.openSnackBar('snack-danger', error.error, 3000); });

  }

  guardarMarcacion() {
    this.isBtnGuardarMarcacion = true; // deshabilito en boton
    if (!this.timePickerValue) {
      this._snackBar.openSnackBar('snack-danger', 'Debe completar el campo de horario', 3000);
      return false;
    }

    const paramEntity = new ParamEntity<Marcacion>();
    paramEntity.GenericEntity = new Marcacion();
    paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
    paramEntity.IdLegajo = this.objeto.IdLegajo;
    paramEntity.IdUsuario = this.userValuesService.getUsuarioValues.IdUsuario;
    paramEntity.FechaDate = this.objeto.FechaDate;
    paramEntity.GenericEntity.Hora = this.timePickerValue;
    paramEntity.GenericEntity.IdMarcacionFuente = 2;
    paramEntity.GenericEntity.IdMarcacionTipo = 2;
    paramEntity.GenericEntity.IdEstado = 1;
    paramEntity.GenericEntity.IdIncidencia = 0;
    paramEntity.GenericEntity.IdMarcacion = null;

    this.novedadesService.guardarMarcacion(paramEntity).subscribe((result: ResponseHelper) => {
      if (result.Ok) {
        this._snackBar.openSnackBar('snack-success', 'Marcación guardada correctamente', 3000);
        this.loadMarcaciones();
        this.actualizaGrillaParteDiario = true;
      } else {
        this._snackBar.openSnackBar('snack-danger', result.Mensaje, 3000);
      }
    }, (error) => { this._snackBar.openSnackBar('snack-danger', error.error, 3000); });
    this.isBtnGuardarMarcacion = false;
    this.setHorarioHabilitado = true;
    this.tiempoActualReset = '';
  }

  getRowData(marcacion: Marcacion) {
    this.dataSourceMarcacion.data.forEach(element => {
      if (element && element !== marcacion) {
        element.Seleccionado = false;
      }
    });
    marcacion.Seleccionado = !marcacion.Seleccionado;
    this.toggleAnularMarcacion(marcacion.Seleccionado);
  }

  toggleHorarioHabilitado() {
    this.setHorarioHabilitado = !this.setHorarioHabilitado;
  }

  toggleMarcacionMenuOpened() {
    this.menuMarcacionOpened = !this.menuMarcacionOpened;
  }

  toggleAnularMarcacion(habilitado) {
    this.isBtnAnularMarcacion = !habilitado;
    this.isBtnHabilitarMarcacion = !habilitado;
  }

  getTimePickerValue(value) {
    this.timePickerValue = value;
  }

  openModalData(tipo) {
    let titulo = '¿Desea guardar la ';
    switch (tipo) {
      case 'jornada':
        titulo += 'jornada?';
        break;
      case 'marcacion':
        titulo += 'marcación?';
        break;
      case 'incidencia':
        titulo += 'incidencia?';
        break;
      default:
        break;
    }

    const dialogRef = this.dialog.open(ModalConfirmacionComponent, {
      width: '500px',
      height: '120px',
      autoFocus: false,
      data: {
        titulo: titulo
      }
    });
    dialogRef.beforeClosed().subscribe((result) => {
      if (result) {
        switch (tipo) {
          case 'jornada':
            this.guardarJornada();
            break;
          case 'marcacion':
            this.guardarMarcacion();
            break;
          case 'incidencia':
            this.guardarIncidencia();
            break;
          default:
            break;
        }
      }
    });
  }

  setMostrarCmbPatologia() {
    this.cmbPatologiaHabilitado = this.objeto.IdIncidencia && this.lstIncidencias.find(x => x.Id === this.objeto.IdIncidencia).IdPreSet === 1;
  }

  openModalIncidencias(element) {
    this.elementMarcacionSelected = element;
    const dialogRef = this.dialog.open(ModalGestionIncidenciaComponent, {
      width: '500px',
      height: '270px',
      autoFocus: false,
      data: {
        elementSelected: element,
        modalData: this.modalData
      }
    });
    dialogRef.beforeClosed().subscribe((result) => {
      if (result) {
        this.getRowData(this.elementMarcacionSelected);
        this.loadMarcaciones();
        this.elementMarcacionSelected = null;
      }
    });
  }

  openModalAnularIncidencia(element) {
    const dialogRef = this.dialog.open(ModalConfirmacionComponent, {
      width: '500px',
      height: '120px',
      autoFocus: false,
      data: {
        titulo: '¿Anular la incidencia?'
      }
    });
    dialogRef.beforeClosed().subscribe((result) => {
      if (result) {
        this.anularIncidencia(element);
      }
    });
  }

  private anularIncidencia(element) {
    this.elementMarcacionSelected = element;
    const paramEntity = new ParamEntity();
    paramEntity.Json = JSON.stringify({
      pIdMarcacion: element.IdMarcacion,
      pIdIncidencia: element.IdIncidencia,
      pIdUsuario: this.userValuesService.getUsuarioValues.IdUsuario,
      pIdEstado: 0
    });
    this.novedadesService.anularIncidencia(paramEntity).subscribe((x: ResponseHelper) => {
      if (x.Ok) {
        this._snackBar.openSnackBar('snack-success', 'Asignación anulada con éxito', 3000);
        this.getRowData(this.elementMarcacionSelected);
        this.loadMarcaciones();
        this.elementMarcacionSelected = null;
      }
    })
  }
}

export interface DialogData {
  titulo: string;
  obj: any;
}
