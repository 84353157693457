<body>
  <header>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
    </ngx-spinner>

    <app-menu-nav *ngIf="showNav$ | async"></app-menu-nav>
    <app-menu-home-autogestion *ngIf="showNavAutogestion$ | async"></app-menu-home-autogestion>
  </header>

  <main>
    <router-outlet></router-outlet>
  </main>

  <footer>
  </footer>
</body>