<div class="container-fluid">
  <div class="row mb-4 text-center">
    <div class="col-6 mx-auto text-white">
      <app-view-title [titulo]="'Aviso de Ausencia'"></app-view-title>
    </div>
  </div>

  <div class="container">

    <div class="row justify-content-md-center">
      <div class="col-sm-12 col-md-6 mb-2">
        <mat-form-field class="w-100 select-form-field">
          <mat-label>Seleccione Motivo</mat-label>
          <mat-select [(ngModel)]="incidenciaSelected" (selectionChange)="getIncidenciaSelected($event)">
            <mat-option *ngFor="let item of listIncidenciasCmb" [matTooltip]="item.Detalle"
              [matTooltipClass]="'tooltip-select'" [value]="item">
              {{ item.Detalle }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>



    <div class="row justify-content-md-center">
      <div class="col-sm-12 col-md-6">
        <mat-form-field class="w-100 mb-2">
          <mat-label>Fecha</mat-label>
          <input matInput #inputDate [min]="null" [max]="null" [matDatepicker]="picker"
            (dateChange)="getStartDatePickerEmit($event)" [formControl]="fechaCtrl">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="row justify-content-md-center">
      <div class="col-sm-12 col-md-6">
        <mat-form-field class="w-100 mb-2">
          <mat-label>Días</mat-label>
          <input type="text" matInput placeholder="Ingrese hasta 3 dígitos" [(ngModel)]="cantidadDias"
            (keydown)="soloNumeros($event)" maxlength="3">
        </mat-form-field>
      </div>
    </div>

    <div class="row justify-content-md-center">
      <div class="col-sm-12 col-md-6">
        <mat-form-field class="w-100 mb-2">
          <mat-label>Observaciones</mat-label>
          <input type="text" matInput placeholder="" [(ngModel)]="observaciones">
        </mat-form-field>
      </div>
    </div>

    <div class="row justify-content-center mb-2">
      <div class="col-12 col-md-6 d-flex flex-wrap justify-content-between align-items-center">
        <div class="mb-2">
          <input #fileInput hidden="true" type="file" (change)="inputFileChange($event)" />
          <button mat-mini-fab color="primary" class="mb-2" (click)="fileInput.click()">
            <mat-icon>attach_file</mat-icon>
          </button>
        </div>
        <p class="mb-2">{{filename}}</p>
        <div class="mb-2">
          <button mat-raised-button color="primary" (click)="guardar()">
            Enviar
          </button>
        </div>
      </div>
    </div>



    <span class="w-100">
      <hr />
    </span>

    <div class="row justify-content-center mb-4">
      <div class="col-12">
        <h2>Historial</h2>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-12">
        <div class="mat-elevation-z8 table-responsive">
          <table mat-table [dataSource]="dataSource" class="table">

            <ng-container matColumnDef="ID_AVISO">
              <th mat-header-cell *matHeaderCellDef class="table-header"> ID_AVISO </th>
              <td mat-cell *matCellDef="let element" class="custom-cell"> {{element.ID_AVISO}} </td>
            </ng-container>

            <ng-container matColumnDef="INCIDENCIA">
              <th mat-header-cell *matHeaderCellDef class="table-header"> INCIDENCIA </th>
              <td mat-cell *matCellDef="let element" class="custom-cell"> {{element.INCIDENCIA}} </td>
            </ng-container>

            <ng-container matColumnDef="FECHA_DESDE">
              <th mat-header-cell *matHeaderCellDef class="table-header"> FECHA_DESDE </th>
              <td mat-cell *matCellDef="let element" class="custom-cell"> {{element.FECHA_DESDE}} </td>
            </ng-container>

            <ng-container matColumnDef="FECHA_HASTA">
              <th mat-header-cell *matHeaderCellDef class="table-header"> FECHA_HASTA </th>
              <td mat-cell *matCellDef="let element" class="custom-cell"> {{element.FECHA_HASTA}} </td>
            </ng-container>

            <ng-container matColumnDef="DIAS">
              <th mat-header-cell *matHeaderCellDef class="table-header"> DIAS </th>
              <td mat-cell *matCellDef="let element" class="custom-cell"> {{element.DIAS}} </td>
            </ng-container>

            <ng-container matColumnDef="FECHA_REGISTRO">
              <th mat-header-cell *matHeaderCellDef class="table-header"> FECHA_REGISTRO </th>
              <td mat-cell *matCellDef="let element" class="custom-cell"> {{element.FECHA_REGISTRO}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="custom-row highlight-row"
              (click)="openModalUpload(row.ID_AVISO)"></tr>
          </table>


          <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
