import { Component, OnInit } from '@angular/core';
import { ParamEntity } from 'src/app/models/general/param.model';
import { Configuracion } from 'src/app/models/rrhh/configuracion.model';
import { ResponseHelper } from 'src/app/models/sistema/responseHelper';
import { ConfiguracionService } from 'src/app/services/configuracion.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { DatePipe } from '@angular/common';
import { SnackBarService } from 'src/app/services/utils/snackBar.service';
import { MonitorProcesos } from 'src/app/models/general/monitor-procesos.model';

@Component({
  selector: 'app-monitor-procesos',
  templateUrl: './monitor-procesos.component.html',
  styleUrls: ['./monitor-procesos.component.scss']
})
export class MonitorProcesosComponent implements OnInit {
  contentLoaded = true;
  paramEntity = new ParamEntity<any>();
  datosProcesos = new MonitorProcesos;
  novedadNoProcHoy = false;
  avisoNoProcHoy = false;
  justificacionNoProcHoy = false;


  constructor(
    private configuracionService: ConfiguracionService,
    private userValuesService: UserValuesService,
    private datePipe: DatePipe,
    private _snackBar: SnackBarService,
  ) {

   }

  ngOnInit() {
    this.getData();
  }

  hideSpinner() {
    setTimeout(() => {
      this.contentLoaded = true;
    }, 3000);
  }

   getData() {
    const paramEntity = { ...this.paramEntity };
    paramEntity.Json = JSON.stringify({
      "pIdEmpresa": 1,
      "pIdUsuario": this.paramEntity.IdUsuario
    })

      this.configuracionService.getMonitorProcesos(paramEntity).subscribe((result: any) => {
      this.datosProcesos=JSON.parse(result[0].vDataJson);

      this.novedadNoProcHoy=this.datePipe.transform(this.datosProcesos.vUltimoReproceso, 'dd-MM-YYYY')<this.datePipe.transform(new Date(), 'dd-MM-YYYY')?true:false;
      this.avisoNoProcHoy=this.datePipe.transform(this.datosProcesos.vUltimoProcAvisos, 'dd-MM-YYYY')<this.datePipe.transform(new Date(), 'dd-MM-YYYY')?true:false;
      this.justificacionNoProcHoy=this.datePipe.transform(this.datosProcesos.vUltimoProcJustificaciones, 'dd-MM-YYYY')<this.datePipe.transform(new Date(), 'dd-MM-YYYY')?true:false;
    },
      (error) => { this._snackBar.openSnackBar('snack-danger', 'Hubo un error', 3000); });
    this.hideSpinner();
  }

}
