import { Nomina } from './../../../models/rrhh/nomina.model';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-modal-nomina-cofarsur',
  templateUrl: './modal-nomina-cofarsur.component.html',
  styleUrls: ['./modal-nomina-cofarsur.component.scss']
})
export class ModalNominaCofarsurComponent implements OnInit {
  displayedColumns: string[] = [
    'IdLegajo',
    'Apellido',
    'Nombre',
    'Cuil',
    'Sucursal',
    'Area',
    'IdLegajoLiquidacion',
    'FechaIngreso',
    'FechaEgreso',
    'Accion'
  ];

  dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator, { static: true }) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }
  nominaList: Nomina[] = [];
  listNominaCofarsur: any[] = [];
  listNominaSniper: any[] = [];
  nominaFiltrada: any[] = [];

  constructor(
    private dialogRef: MatDialogRef<ModalNominaCofarsurComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.listNominaCofarsur = data.nominaCofarsur;
    this.listNominaSniper = data.nominaSniper;
  }

  ngOnInit() {
    this.dataSource.filterPredicate = (data: Nomina, filter: string) => {
      return data.Sucursal.toLowerCase().includes(filter.toLowerCase())
        || data.IdLegajo.toString().includes(filter.toLowerCase())
        || data.ApellidoNombre.toLowerCase().includes(filter.toLowerCase())
    }

    this.getNominaGrilla();

  }

  cerrarModal() {
    this.dialogRef.close();
  }

  filtrarGrilla(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getNominaGrilla() {
    this.listNominaCofarsur = this.listNominaCofarsur.map((item) => {
      const partesNombre = item.apellido_nombre ? item.apellido_nombre.split(',') : ['', ''];
      const Apellido = partesNombre[0].trim();
      const Nombre = partesNombre.slice(1).join(',').trim();


      return {
        IdLegajo: item.id_legajo,
        Apellido: Apellido,
        Nombre: Nombre,
        ApellidoNombre: Apellido + ' ' + Nombre,
        Cuil: item.cuil,
        Sucursal: item.sucursal,
        Area: item.area,
        IdLegajoLiquidacion: item.legajo_liquidacion,
        FechaIngreso: item.fecha_ingreso === '' ? null : item.fecha_ingreso,
        FechaEgreso: item.fecha_egreso === '' ? null : item.fecha_egreso
      };
    });
    // this.dataSource.data = this.listNominaCofarsur; // SE COMENTA HASTA CONFIRMAR FUNCIONAMIENTO OK
    this.filterLists();

  }

  getRowData(marcacion: Nomina) {
    this.dataSource.data.forEach(element => {
      if (element && element !== marcacion) {
        element.Seleccionado = false;
      }
    });
    marcacion.Seleccionado = !marcacion.Seleccionado;
  }

  agregarPersona(marcacion: Nomina) {
    this.dialogRef.close(marcacion);
  }

  getCheckboxSelected(checked) {
    this.dataSource.data = this.nominaFiltrada.filter(x => checked ? !x.FechaEgreso : x.FechaIngreso);
  }

  private filterLists() {
    console.log('listNominaCofarsur', this.listNominaCofarsur);
    console.log('listNominaSniper', this.listNominaSniper);
    //Controles de datos
    this.nominaFiltrada = this.listNominaCofarsur.map(a => {
      const legajoSniperEncontrado = this.listNominaSniper.find(b => b.ID_LEGAJO === a.IdLegajo);
      const cuilCorrecto = legajoSniperEncontrado ? (legajoSniperEncontrado.CUIL === a.Cuil) : false;
      const apellidoCorrecto = legajoSniperEncontrado ? (legajoSniperEncontrado.APELLIDO === a.Apellido) : false;
      const nombreCorrecto = legajoSniperEncontrado ? (legajoSniperEncontrado.NOMBRE === a.Nombre) : false;
      const fechaIngresoCorrecta = legajoSniperEncontrado ? (legajoSniperEncontrado.FECHA_INGRESO === a.FechaIngreso) : false;
      const fechaEgresoCorrecta = legajoSniperEncontrado ? (legajoSniperEncontrado.FECHA_EGRESO === (a.FechaEgreso !== null && a.FechaEgreso !== undefined ? a.FechaEgreso : '')) : false;
      const sinErrores = cuilCorrecto && apellidoCorrecto && nombreCorrecto && fechaIngresoCorrecta && fechaEgresoCorrecta;


      return {
        ...a,
        idLegajoExist: legajoSniperEncontrado ? true : false,
        CuilCorrecto: cuilCorrecto,
        ApellidoCorrecto: apellidoCorrecto,
        NombreCorrecto: nombreCorrecto,
        FechaIngresoCorrecta: fechaIngresoCorrecta,
        FechaEgresoCorrecta: fechaEgresoCorrecta,
        SinErrores: sinErrores
      };
    });



    //Ordenar lista
    this.nominaFiltrada.sort((a, b) => {
      if (a.idLegajoExist < b.idLegajoExist) return -1;
      if (a.idLegajoExist > b.idLegajoExist) return 1;

      if (a.IdLegajo < b.IdLegajo) return -1;
      if (a.IdLegajo > b.IdLegajo) return 1;
      return 0;
    });

    this.dataSource.data = this.nominaFiltrada;
    this.getCheckboxSelected(true);
  }

}
