<div class="view">
  <div class="container mt-5">
    <div class="row">
      <div class="col-sm-12 col-md-6 mx-auto text-center text-white">
        <app-alert [titulo]="'Gestión Documental' | titlecase"></app-alert>
      </div>
    </div>

    <div [ngSwitch]="state">
      <div *ngSwitchCase="stateEnum.MOSTRAR_CODIGO">
        <app-gestion-codigo (codeEmitter)="getCodeEmitted($event)"></app-gestion-codigo>
      </div>

      <div *ngSwitchCase="stateEnum.MOSTRAR_FORM">
        <app-gestion-form [gestionDocumental]="gestionDocumental" (observacionesEmit)="getObservacion($event)"
                          (accionSelectedEmit)="getAccionSelected($event)" (guardarEmit)="guardar()"></app-gestion-form>
      </div>

      <div *ngSwitchCase="stateEnum.MOSTRAR_MENSAJE">
        <app-gestion-mensaje></app-gestion-mensaje>
      </div>
    </div>

  </div>
</div>
