<div class="bg-img-login w-100 d-inline-block h-100 position-absolute" [style.background]="'url(' + bgImage + ')'">
</div>

<div id="mySidenav" class="sidenav d-inline-block position-absolute h-100 p-3" [ngStyle]="{'width' : sideNavWidth }">
  <div class="d-flex mobile-alignment login-card-wrapper">
    <mat-card class="login-card p-0">
      <div class="d-flex justify-content-center align-items-center mt-2" *ngIf="showSelector">
        <h1>Módulos</h1>
      </div>

      <div *ngIf="showSelector;else showLogin">
        <app-login-selector (tipoFormularioEmit)="getTipoFormularioEmit($event)"></app-login-selector>
      </div>

      <ng-template #showLogin>
        <div [ngSwitch]="tipoFormulario">
          <div *ngSwitchCase="tipoLoginFormEnum.AUTOGESTION">
            <app-login-ingreso-autogestion (volverEmit)="volver()"></app-login-ingreso-autogestion>
          </div>

          <div *ngSwitchCase="tipoLoginFormEnum.ADMINISTRACION">
            <app-login-ingreso-administracion (volverEmit)="volver()" (toggleSpinnerEmit)="toggleSpinner($event)">
            </app-login-ingreso-administracion>
          </div>
        </div>

      </ng-template>



      <!--
      <app-login-recuperar-pass *ngIf="recuperaCuenta" (setIngresoLogin)="switchBoxLogin()"
        (toggleSpinnerEmit)="toggleSpinner($event)"></app-login-recuperar-pass> -->
    </mat-card>
  </div>
</div>