<div class="contenedor">
  <div class="container-fluid my-3 pb-2">
    <div class="row">
      <div class="col-6 mx-auto text-center text-white">
        <app-alert [titulo]="'Configuracion Plantillas de Nómina'"></app-alert>
      </div>
    </div>

    <div class="row">
    <div class="container">
    <div class="row">

      <div class="col-4">
        <div class="row">
          <div class="col-12 style-tilte-list">
            <span>Plantillas Nómina</span>
          </div>
        </div>


        <div class="row">
          <div class="col-12 list-tollbar">
            <div class="row justify-content-end mr-1">
              <div class="col-8">
                <!-- <input type="text" placeholder="Ingrese valor a buscar"  [formControl]="inputFiltroPlantillasNomina"> -->
              </div>
              <div class="col-2">
                <mat-icon [matTooltip]="'Crear Plantilla'" class="btn_nuevo" (click)="nuevaPlantilla()">add</mat-icon>
              </div>
              <div class="col-2">
                <mat-icon [matTooltip]="'Eliminar Plantilla'" class="btn_eliminar" (click)="submitForm('Eliminar')">delete</mat-icon>
              </div>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col style-list">
            <app-config-plantillas-nomina-list-plantillas [dataSourceList]="sourcePlantillasNominaFiltered" (elementSelectedEmit)="getPlantillaSelected($event)"></app-config-plantillas-nomina-list-plantillas>
          </div>
        </div>

      </div>

      <div class="col-4">
        <div class="row">
          <div class="col-12  style-tilte-list">
            <span>Nómina</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 list-tollbar">
            <div class="row justify-content-end mr-1">
              <div class="col-10">
                <!-- <input type="text" placeholder="Ingrese valor a buscar" [formControl]="inputFiltroEmpleados"> -->
              </div>
              <div class="col-2">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col style-list">
            <app-config-plantillas-nomina-list-nomina  [dataSourceList]="sourceEmpleadosFiltered"
            (optionsDataSelectedEmit)="getIdEmpleadoSelected($event)"
            ></app-config-plantillas-nomina-list-nomina>
          </div>
        </div>
      </div>


      <div class="col-4">

        <div class="row">
          <div class="col-12 style-tilte-list">
            <span>Datos de Plantilla</span>
          </div>
        </div>

        <div class="row">
          <div class="col-12 list-tollbar">
            <span>{{newValue?'Crear Plantilla':'Modificar Plantilla'}}</span>
          </div>
        </div>

        <div class="row">
          <div class="col style-list">
            <form [formGroup]="formData" (ngSubmit)="submitForm($event)">
              <mat-form-field appearance="outline">
                <mat-label>Nombre de Plantilla</mat-label>
                <input matInput type="text" formControlName="nombre" placeholder="Nombre de Plantilla">
              </mat-form-field>
              <button mat-raised-button type="submit" color="primary" class="btn-guardar">GUARDAR</button>
            </form>
          </div>
        </div>

      </div>

    </div>


    </div>

    </div>

  </div>

</div>
