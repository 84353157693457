<div class="container-fluid p-3">
  <div class="row">
    <div class="col-md-6 mx-auto text-center text-white">
      <app-alert [titulo]="'Reportes Generales'"></app-alert>
    </div>
  </div>
  <div class="row my-4">
    <div class="col-6 p-1">
      <mat-card class="card-actions">
        <div class="flex-container">
          <div class="w-70 mx-auto">
            <app-selection-list (selectedOptionEmit)="filter($event, false, true)"></app-selection-list>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="col-6 ">
      <app-filter-card [showRangePicker]="true" (filterEmit)="filter($event)" [hasLegajoInput]="false"
        [hasExportExcelButton]="true" (printExcelEmit)="printExcel($event)"
        [exportExcelButtonDisabled]="!paramEntity.SpName"></app-filter-card>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <!-- <div class="table-container"> -->
      <app-table-report [setTableParams]="paramEntity"></app-table-report>
      <!-- </div>       -->
    </div>
  </div>
</div>