<mat-form-field class="w-25">
  <mat-label>Filtrar</mat-label>
  <input matInput (keyup)="applyFilter($event)">
</mat-form-field>

<div class="table-container">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-parte-diario">
    <ng-container *ngFor="let col of columns" [matColumnDef]="col">
      <th class="border bg-secondary  text-white" mat-header-cell *matHeaderCellDef> {{ col }} </th>
      <td  class="border" mat-cell *matCellDef="let element"  matTooltip="{{ element[col] }}"> {{ element[col] }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;" (click)="openModalData(row)"></tr>
  </table>

  <mat-paginator #detallePaginator [pageSizeOptions]="[1000, 500]"></mat-paginator>
</div>
