import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core'
import { NovedadesService } from 'src/app/services/rrhh/novedades/novedades.service';
import { docVacacionesModel } from 'src/app/models/rrhh/documentos/docVacaciones.model';
import { ParamEntity } from 'src/app/models/general/param.model';
import { UserValuesService } from 'src/app/services/utils/user-values.service';
import { Observable } from 'rxjs';
import * as $ from 'jquery';
import * as htmlToImage from 'html-to-image';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-form-vacaciones',
  templateUrl: './modal-form-vacaciones.component.html',
  styleUrls: ['./modal-form-vacaciones.component.scss'],
})
export class ModalFormVacacionesComponent implements OnInit {
  objeto: any;
  docVacacionesList: docVacacionesModel = new docVacacionesModel();
  loading: boolean;
  public file: any;
  options: any;
  printButtonDisabled = true;

  // ESTILOS
  container;
  row;
  m2;
  col12;
  col6;
  mt2;
  mt5;
  title;
  pTitle;
  infoTitle;
  contentText;
  pContent;
  firmasContainer;
  firmaEmpresa;
  textSniper;
  firmaContainer;
  rowCentered;
  footer;
  imgfootercontainer;

  url = window.location.origin;

  constructor(
    private userValuesService: UserValuesService,
    private novedadesService: NovedadesService,
    public dialogRef: MatDialogRef<ModalFormVacacionesComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private el: ElementRef
  ) {
    this.objeto = this.data;
    this.url = `${this.url}/gestion-documental/${btoa(this.objeto.idDocumento)}`;
  }

  ngOnInit() {
    this.getDocVacacionesList(this.objeto.idDocumento);
    this.setStyles();
  }

  public getElement(): Observable<any> {
    return new Observable((resolve) => {
      resolve.next(document.getElementById('content'));
    })
  }

  private getDocVacacionesList(idDocumento: any) {
    if (idDocumento == undefined) {
      idDocumento = 1;
    }
    return new Promise((resolve, reject) => {
      const paramEntity = new ParamEntity<any>();
      paramEntity.IdEmpresa = this.userValuesService.getUsuarioValues.IdEmpresa;
      paramEntity.IdDocumento = idDocumento;
      this.novedadesService.getDocVacacionesList(paramEntity).subscribe((result: docVacacionesModel) => {
        this.docVacacionesList = result[0];
        resolve(true);
      });
    });

  }

  cerrarModal(estado) {
    this.dialogRef.close(estado);
  }

  print() {
    this.generateImage().then((imgResult) => {
      // var contents = $('#form_Vacaciones').html();
      // var frame1 = $('<iframe />');
      // const frameCero = frame1[0] as HTMLIFrameElement;
      // frameCero.name = "frame1";
      // frameCero.id = 'frame1';
      // frame1.css({ "position": "absolute", "top": "-1000000px" });
      // $("body").append(frame1);
      // const frameDoc: any = frameCero.contentWindow ?
      //   frameCero.contentWindow : frameCero.contentDocument.documentElement ? frameCero.contentDocument.documentElement : frameCero.contentDocument;


      // frameDoc.document.open();

      // nuevo
      var divContents = document.getElementById("form_Vacaciones").innerHTML;
      var a = window.open('', '', 'height=700, width=700');
      a.document.write('<html>');
      a.document.write('<body><br>');


      //Create a new HTML document.
      let newContent = $(divContents);
      newContent.find('qrcode').replaceWith(imgResult);
      const script = `
        <html>
          <head>
            <title>Formulario de vacaciones</title>
          </head>
          <body>
          ${newContent.html()}
          </body>
        </html>
      `;

      a.document.write(script);
      a.document.write('</body></html>');
      a.document.close();
      a.focus();
      setTimeout(() => {
        a.print();

      }, 1000);

      //BACKUP
      // frameDoc.document.write(script);
      // frameDoc.document.close();

      // const modalComponent = this;
      // window.frames["frame1"].onafterprint = function () {
      //   modalComponent.cerrarModal(false);
      // }

      // setTimeout(function () {
      //   window.frames["frame1"].focus();
      //   window.frames["frame1"].print();
      //   frame1.remove();
      // }, 500);
    });

  }

  private setStyles() {
    this.container = {
      'max-width': '1140px',
      'margin': '0.5rem'
    };
    this.row = {
      'display': 'flex',
      'flex-wrap': 'wrap',
      'margin-right': '-15px',
      'margin-left': '-15px'
    };

    this.col12 = {
      'flex': '0 0 100%',
      'max-width': '100%'
    }

    this.col6 = {
      'flex': '0 0 50%',
      'max-width': '50%'
    }

    this.mt2 = {
      'margin-top': '0.5rem'
    }

    this.mt5 = {
      'margin-top': '3rem'
    }

    this.title = {
      'border': '1px solid rgba(5, 5, 5, 0.2)',
      'text-align': 'center',
      'background-color': 'lightgrey',
      'margin-top': '10px'
    }

    this.pTitle = {
      'margin': '5px',
      'font-weight': 'bold'
    }

    this.infoTitle = {
      'position': 'relative',
      'margin-top': '20px',
      'font-size': '14px',
      'padding-left': '25px'
    }

    this.contentText = {
      'position': 'relative',
      'margin-top': '50px',
      'padding-left': '25px',
      'padding-right': '25px'
    }

    this.pContent = {
      'text-align': 'justify',
      'font-size': '14px'
    }

    this.firmasContainer = {
      'margin-top': '100px',
      'width': '80%',
      'text-align': 'center',
      'margin-left': 'auto',
      'margin-right': 'auto',
      'position': 'relative',
      'height': '150px'
    }

    this.textSniper = {
      'font-size': '14px'
    }

    this.firmaContainer = {
      'height': '60px'
    }

    this.footer = {
      'position': 'relative',
      'left': '0',
      'bottom': '0',
      'width': '100%',
      'text-align': 'left',
      'color': 'black'
    }

    this.imgfootercontainer = {

    }

    this.rowCentered = Object.assign(this.row, { 'justify-content': 'center' });
  }

  disableButton() {
    setTimeout(() => {
      this.printButtonDisabled = false;
    }, 1800);
  }

  generateImage() {
    const node: any = document.getElementById('qr');
    return new Promise<any>((resolve) => {
      htmlToImage.toPng(node)
        .then((dataUrl) => {
          const img = new Image();
          img.src = dataUrl;
          resolve(img);
        })
        .catch((error) => {
          console.error('oops, something went wrong!', error);
        });
    })
  }

}


export interface DialogData {
  idDocumento: number;
}
