<div class="mainheader" id="MenuHeader">
  <nav class="navbar navbar-expand-lg menustyle">
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav multi-level">
        <div>
          <li class="nav-item d-flex align-items-center">
            <a routerLink="/home" class="nav-link" matTooltip="Ir a inicio" matTooltipClass="tooltip-logout">
              <mat-icon class="d-flex" aria-hidden="false" aria-label="Go Home">home</mat-icon>
            </a>
          </li>
        </div>

        <div *ngFor="let element of navMenuList">
          <!-- Muestra todos los botones padres del menu -->
          <!-- Si tiene dropdown: true y disabled: false
              entonces es un boton que tiene submenu incluido
              Para los botones si el disabled: true
              entonce1s aplicamos una clase css para inhabilitar ese boton -->
          <li *ngIf="!element.hidden" class="nav-item d-flex align-items-center h-100"
            [ngClass]="(element.dropdown && !element.disabled) ? 'dropdown' : ''">
            <a mat-button class="nav-link" [ngClass]="{'disable-a' : element.disabled}" routerLink="{{ element.link }}"
              id="{{ (element.dropdown) ? 'dropdownMenuLink' : element.id }}"
              [attr.data-toggle]="(element.dropdown) ? 'dropdown' : ''"
              [attr.aria-haspopup]="(element.dropdown) ? true : false"
              [attr.aria-expanded]="(element.dropdown) ? false : true"
              [matTooltip]="element.tooltip" href="javascript:void(0)">
              <mat-icon *ngIf="element.icon" aria-hidden="false" aria-label="Go {{element.icon}}">{{ element.icon }}</mat-icon>
              <span>
                {{ element.header }}
              </span>
            </a>
            <!-- Iteración para mostrar todos los botones incluidos como submenu
                y aplicamos la misma logica que en los botones padres -->
            <ul *ngIf="element.submenu" class="dropdown-menu dropdown-submenu shadow-sm">
              <div *ngFor="let subElement of filterMenuItems(element.submenu)">
                <li *ngIf="!subElement.hidden" class="nav-item px-2 py-1"
                  [ngClass]="(subElement.dropdown && !subElement.disabled) ? 'dropdown' : ''">
                  <a class="nav-menu-link" [ngClass]="{'disable-a' : subElement.disabled}"
                    routerLink="{{ subElement.link }}" [matTooltip]="subElement.tooltip">
                    {{ subElement.header }}
                  </a>
                  <!-- Iteración para mostrar los los elementos como segundo nivel
                    del submenu y aplicamos la misma logica anterior -->
                  <ul class="dropdown-menu dropdown-submenu-level shadow-sm">
                    <li class="nav-item px-2 py-1" *ngFor="let subMenuElement of subElement.submenu">
                      <a class="nav-menu-link" [ngClass]="{'disable-a' : subMenuElement.disabled}"
                        routerLink="{{ subMenuElement.link }}" [matTooltip]="subMenuElement.tooltip">
                        {{ subMenuElement.header }}
                      </a>
                    </li>
                  </ul>
                </li>
              </div>

            </ul>
          </li>
        </div>
        <li class="nav-item d-flex align-items-center ml-auto">

          <mat-label class="text-light mr-1 userLabel">{{ userLabel }}</mat-label>

          <mat-icon matTooltip="Cerrar sesión" [matTooltipPosition]="'left'" matTooltipClass="tooltip-logout"
            class="ico-logout" (click)="cerrarSesion()">exit_to_app</mat-icon>
        </li>
      </ul>
    </div>
  </nav>
</div>
