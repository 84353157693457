import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, TimeoutError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionService {
  /* Variables para llamar a API */
  private readonly _url: string = '/api/configuracion';

  constructor(private http: HttpClient) { }

  protected getRequestHeaders() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': `application/json, text/plain, */*`,
      timeout: `${500000}`
    });

    return headers;
  }

  private handleError(error: HttpErrorResponse) {
    if (error instanceof TimeoutError) {
      return throwError('En este momento no podemos realizar la operación. Por favor intente nuevamente más tarde.');
    }
    return throwError(error);
  }

  /*--------------GET--------------*/

  getAbmGeneralService(filtro) {
    const endpointUrl = this._url + '/getAbmGeneral';
    const params = new HttpParams()
      .set('filtro', JSON.stringify(filtro));

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders(), params: params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getTableValuesService(filtro) {
    const endpointUrl = this._url + '/getTableValues';
    const params = new HttpParams()
      .set('filtro', JSON.stringify(filtro));

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders(), params: params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getHtmlElementsService(filtro) {
    const endpointUrl = this._url + '/getHtmlElements';
    const params = new HttpParams()
      .set('filtro', JSON.stringify(filtro));

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders(), params: params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getAbmJornadasGetService(filtro) {
    const endpointUrl = this._url + '/getAbmJornadasGet';
    const params = new HttpParams()
      .set('filtro', JSON.stringify(filtro));

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders(), params: params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getAbmJornadasUsuariosPlantillaGetService() {
    const endpointUrl = this._url + '/getAbmJornadasUsuariosPlantillaGet';

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders() }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }



  getSourceConfiguracionUsuarios(filtro) {
    const endpointUrl = this._url + '/getSourceConfiguracionUsuarios';
    const params = new HttpParams()
      .set('filtro', JSON.stringify(filtro));

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders(), params: params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getSourceConfPlantillasNomina(filtro) {
    const endpointUrl = this._url + '/getSourceConfPlantillasNomina';
    const params = new HttpParams()
      .set('filtro', JSON.stringify(filtro));

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders(), params: params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getSourceConfPlantillasJornadas(filtro) {
    const endpointUrl = this._url + '/getSourceConfPlantillasJornadas';
    const params = new HttpParams()
      .set('filtro', JSON.stringify(filtro));

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders(), params: params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getMonitorProcesos(filtro) {
    const endpointUrl = this._url + '/getMonitorProcesos';
    const params = new HttpParams()
      .set('filtro', JSON.stringify(filtro));

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders(), params: params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getSourceConfiguracionNomina(filtro) {
    const endpointUrl = this._url + '/getSourceConfiguracionNomina';
    const params = new HttpParams()
      .set('filtro', JSON.stringify(filtro));

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders(), params: params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }


  getABMGeneralControlesHTML(filtro) {
    const endpointUrl = this._url + '/getABMGeneralControlesHTML';
    const params = new HttpParams()
      .set('filtro', JSON.stringify(filtro));

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders(), params: params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }


   getValidarDatosABM(filtro) {
    const endpointUrl = this._url + '/getValidarDatosABM';
    const params = new HttpParams()
      .set('filtro', JSON.stringify(filtro));

    return this.http.get(endpointUrl, { headers: this.getRequestHeaders(), params: params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }



  /*----------POST-----------*/

  guardarNuevaConfiguracionService(paramentity) {
    const endPointUrl = this._url + '/postNuevaConfiguracion';

    return this.http.post(endPointUrl, JSON.stringify(paramentity), { headers: this.getRequestHeaders() }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  postAbmUsuarios(paramentity) {
    const endPointUrl = this._url + '/postAbmUsuarios';

    return this.http.post(endPointUrl, JSON.stringify(paramentity), { headers: this.getRequestHeaders() }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  postAbmPlantillasNomina(paramentity) {
    const endPointUrl = this._url + '/postAbmPlantillasNomina';

    return this.http.post(endPointUrl, JSON.stringify(paramentity), { headers: this.getRequestHeaders() }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  postAbmPlantillasJornadas(paramentity) {
    const endPointUrl = this._url + '/postAbmPlantillasJornadas';

    return this.http.post(endPointUrl, JSON.stringify(paramentity), { headers: this.getRequestHeaders() }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  guardarConfiguracionNomina(paramentity) {
    const endPointUrl = this._url + '/postConfiguracionNomina';

    return this.http.post(endPointUrl, JSON.stringify(paramentity), { headers: this.getRequestHeaders() }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }
}
