import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { TipoJornadaEnum, TipoTurnoEnum } from 'src/app/enums/configuracion.enum';
import { CmbEntity } from 'src/app/models/general/cmbEntity.model';
import { Usuario } from 'src/app/models/general/usuario.model';
import { ConfiguracionJornada, DatosCalculadosTurno } from 'src/app/models/rrhh/configuracion-jornada';
import { Configuracion } from 'src/app/models/rrhh/configuracion.model';
import { NovedadesEndPoint } from 'src/app/services/rrhh/novedades/novedades-endpoint';
import { NovedadesService } from 'src/app/services/rrhh/novedades/novedades.service';
import { UserValuesService } from 'src/app/services/utils/user-values.service';

@Component({
  selector: 'app-form-config-a',
  templateUrl: './form-config-a.component.html',
  styleUrls: ['./form-config-a.component.scss']
})
export class FormConfigAComponent implements OnInit, OnChanges {
  form: UntypedFormGroup;
  estadoOptions: CmbEntity[] = [];
  tipoOptions: CmbEntity[] = [];
  turnosOptions: CmbEntity[] = [];
  lstIncidencias: CmbEntity[] = [];
  usuario: Usuario;
  tipoJornadaSelect: number;
  tipoTurnoSelect: number;
  horasTeoricas: number;
  newJornada: boolean = false;
  gestionaIncidencia: boolean = false;
  esFranco: boolean = false;
  tipoJornadaEnum = TipoJornadaEnum;
  tipoJTurnoEnum = TipoTurnoEnum;
  @Input() configuracion: ConfiguracionJornada = {} as ConfiguracionJornada;
  @Input() datosCalculadosTurno1: DatosCalculadosTurno = {} as DatosCalculadosTurno;
  @Input() datosCalculadosTurno2: DatosCalculadosTurno = {} as DatosCalculadosTurno;

  @Output() tipoJornadaEmit = new EventEmitter();
  @Output() tipoTurnoEmit = new EventEmitter();

  @Input() set setClearForm(value) {
    if (value) {
      this.form.reset();
    }
  }
  @Output() formSubmitEmit = new EventEmitter();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userValuesService: UserValuesService,
    private novedadesService: NovedadesService
  ) {
    this.usuario = this.userValuesService.getUsuarioValues;
  }

  ngOnInit() {
    this.createForm();
    this.loadIncidenciasCmb();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.configuracion) {
      if (this.form) {
        this.horasTeoricas = 0;
        this.form.controls.IdIncidencia.disable();
        this.form.controls.IdIncidencia.setValue(0);
        this.form.reset();

        if (this.newJornada) {
          this.createForm();
          this.newJornada = false;
        }

      }
      this.configuracion = changes.configuracion.currentValue;
      if (this.configuracion.Njornada) {
        this.subscribeControls();
        this.setFormValues();
        return;
      }
    }
  }

  private createForm() {
    this.form = this.formBuilder.group({
      Njornada: [''],
      IdEstado: [],
      Hteoricas: [{ value: '', disabled: true }],
      EsFranco: [false],
      RefrirgerioMinutos: [],
      gestionaIncidencia: [false],
      IdIncidencia: [{ value: null, disabled: true }],
      TipoJornada: [2],
      TipoTurno: [1]
    });
    this.setOptions();
    this.subscribeControls();
  }

  private setFormValues() {
    this.form.controls.Njornada.setValue(this.configuracion.Njornada);
    this.form.controls.IdEstado.setValue(this.configuracion.IdEstado);

    this.form.controls.EsFranco.setValue(this.configuracion.EsFranco);
    this.esFranco=this.configuracion.EsFranco;

    this.form.controls.RefrirgerioMinutos.setValue(this.configuracion.RefrirgerioMinutos);

    this.form.controls.TipoJornada.setValue(this.configuracion.TipoJornada);
    this.form.controls.TipoTurno.setValue(this.configuracion.TipoTurno);
    this.tipoJornadaSelect = this.configuracion.TipoJornada;
    this.tipoTurnoSelect = this.configuracion.TipoTurno;
    if (this.tipoJornadaSelect === this.tipoJornadaEnum.FLEXIBLE) {
      this.form.controls.TipoTurno.disable();
      this.form.controls.Hteoricas.enable();
    } else {
      this.form.controls.TipoTurno.enable();
      this.form.controls.Hteoricas.disable();
    }

    this.form.controls.IdIncidencia.setValue(this.configuracion.IdIncidencia);
    if (this.configuracion.IdIncidencia!=0) {
      this.form.controls.IdIncidencia.enable();
      this.form.controls.gestionaIncidencia.setValue(true);
    } else {
      this.form.controls.IdIncidencia.disable();
    }

    this.actualizarHTeoricas();

  }

  private subscribeControls() {
    // CONTROL DE TIPO (CMB)
    this.form.controls.TipoJornada.valueChanges.subscribe((value) => {
      if (value) {
        this.tipoJornadaEmit.emit(value);

        if (value === TipoJornadaEnum.ESPECIFICA) {
          this.form.controls.TipoTurno.setValue(1);
        } else {
          this.form.controls.TipoTurno.reset();
        }
      }
    });

    // CONTROL DE TURNOS (CMB)
    this.form.controls.TipoTurno.valueChanges.subscribe((value) => {
      if (value) {
        this.tipoTurnoEmit.emit(value);
      }
    });

    if (this.form.controls.TipoJornada.value === TipoJornadaEnum.ESPECIFICA &&
      this.form.controls.TipoTurno.value === TipoTurnoEnum.SIMPLE) {
      this.form.controls.Hteoricas.disable();
    } else if (this.form.controls.TipoJornada.value === TipoJornadaEnum.ESPECIFICA &&
      this.form.controls.TipoTurno.value === TipoTurnoEnum.DOBLE) {
      this.form.controls.Hteoricas.enable();
    }

    this.form.controls.gestionaIncidencia.setValue(false);
    this.form.controls.IdIncidencia.disable();


    this.tipoJornadaEmit.emit(this.form.controls.TipoJornada.value);
    this.tipoTurnoEmit.emit(this.form.controls.TipoTurno.value);
  }

  private setOptions() {
    // SELECT OPTIONS
    const estados = [
      'Activo',
      'Anulado'
    ];

    estados.forEach((element, index) => {
      const estado = new CmbEntity();
      estado.Id = index + 1;
      estado.Detalle = element;
      this.estadoOptions.push(estado);
    });

    const tipos = [
      'Flexible',
      'Específica'
    ];

    tipos.forEach((element, index) => {
      const elemento = new CmbEntity();
      elemento.Id = index + 1;
      elemento.Detalle = element;
      this.tipoOptions.push(elemento);
    });

    const turnos = [
      'Simple',
      'Doble'
    ];

    turnos.forEach((element, index) => {
      const elemento = new CmbEntity();
      elemento.Id = index + 1;
      elemento.Detalle = element;
      this.turnosOptions.push(elemento);
    });
  }

  getSeleccionEstado(e) {}

  getGestionaIncidencia(e) {
    this.gestionaIncidencia = e.checked;

    if (e.checked) {
      this.form.controls.IdIncidencia.enable();
    } else {
      this.form.controls.IdIncidencia.disable();
      this.form.controls.IdIncidencia.setValue(0);
    }
  }

  displayFn(item: CmbEntity): string {
    return item && item.Detalle ? item.Detalle : '';
  }

  soloNumeros(e, soloNumero: boolean) {
    if (soloNumero) {
      if (!(e.keyCode === 8
        || e.keyCode === 46
        || (e.keyCode >= 35 && e.keyCode <= 40)
        || (e.keyCode >= 48 && e.keyCode <= 57)
        || (e.keyCode >= 96 && e.keyCode <= 105)
        || (e.keyCode === 86 && e.ctrlKey === true)
      )) {
        e.preventDefault();
        return false
      }
    }
  }

  loadIncidenciasCmb() {
    let params = [];
    params.push(null);
    params.push(this.usuario.IdRol);
    params.push(this.userValuesService.getUsuarioValues.IdEmpresa);

    this.novedadesService.getListIncidencias(params).subscribe((result: CmbEntity[]) => {
      this.lstIncidencias = result;
    });
  }

  getSeleccionIncidencia(e) {}

  getEsFranco(e) {
    this.esFranco = e.checked;
    this.actualizarHTeoricas();
  }

  getSeleccionTipoJornada(e) {
    this.tipoJornadaSelect = e.value;
    if (this.tipoJornadaSelect === this.tipoJornadaEnum.FLEXIBLE) {
      this.form.controls.TipoTurno.disable();
      this.form.controls.Hteoricas.enable();
    } else {
      this.form.controls.TipoTurno.enable();
      this.form.controls.Hteoricas.disable();
    }
    this.actualizarHTeoricas();
  }


  getSeleccionTipoTurno(e) {
    this.tipoTurnoSelect = e.value;
  }

  submitForm(e) {
    const configuracionObj: ConfiguracionJornada = this.form.getRawValue();
    this.formSubmitEmit.emit(configuracionObj);
  }

  actualizarHTeoricas() {

    if (this.tipoJornadaSelect === this.tipoJornadaEnum.FLEXIBLE) {
      this.horasTeoricas = this.configuracion.Hteoricas;
    } else {
      if (this.datosCalculadosTurno1.hTeoricas != undefined) {
        this.horasTeoricas = this.datosCalculadosTurno1.hTeoricas;
      }
      if (this.datosCalculadosTurno2.hTeoricas != undefined) {
        this.horasTeoricas += this.datosCalculadosTurno2.hTeoricas;
      }
    }

    if(this.esFranco){
      this.horasTeoricas = 0;
    }

    this.form.controls.Hteoricas.setValue(this.horasTeoricas);




  }


}
